import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'dojo-loader',
  imports: [],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() centered = false;
  @Input() size = 5;
  @Input() fullHeight = false;
}
