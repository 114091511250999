<!--
  The 'no-referrer' referrer policy fixes 403 errors when fetching avatars from Google.

  https://stackoverflow.com/questions/40570117/http403-forbidden-error-when-trying-to-load-img-src-with-google-profile-pic/61042200#61042200
-->

<ngx-avatars
  [src]="src"
  [name]="state.label"
  [borderColor]="borderColor"
  [size]="size"
  [cornerRadius]="cornerRadius"
  [round]="round"
  [value]="value"
  [bgColor]="bgColor"
  [referrerpolicy]="'no-referrer'"
/>
