import { Injectable } from '@angular/core';
import { LocalizedString } from '@examdojo/core/i18n';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { rethrowError } from '@examdojo/rxjs';
import { TableUpdateModel } from '@examdojo/supabase';
import { sanitizeObject } from '@examdojo/util/sanitize-object';
import kebabCase from 'lodash/kebabCase';
import { tap } from 'rxjs';
import { TopicLevel1HttpService } from './topic-level-1-http.service';
import {
  TOPIC_LEVEL_1_UPDATE_ALLOWED_KEYS,
  TopicLevel1HttpModel,
  TopicLevel1StoreModel,
  TopicLevel1UpdateModel,
} from './topic-level-1.model';
import { TopicLevel1Query } from './topic-level-1.query';
import { TopicLevel1Store } from './topic-level-1.store';

@Injectable({
  providedIn: 'root',
})
export class TopicLevel1Service {
  constructor(
    private readonly store: TopicLevel1Store,
    private readonly query: TopicLevel1Query,
    private readonly httpService: TopicLevel1HttpService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  fetchByIds(ids: Array<TopicLevel1StoreModel['id']>) {
    return this.httpService.fetchByIds(ids).pipe(
      tap((data) => {
        this.store.set(data.map((item) => this.mapHttpModelToStoreModel(item)));
      }),
      this.errorHandlerService.catchError('[TopicLevel1Service]: Fetching the topics failed', rethrowError(), {
        toast: 'An error occurred while fetching the topics',
      }),
    );
  }

  update(id: TopicLevel1StoreModel['id'], model: TopicLevel1UpdateModel) {
    const httpModel = this.mapUpdateModelToHttpUpdateModel(model);

    return this.httpService.update(id, httpModel).pipe(
      tap((data) => {
        this.store.upsert(id, this.mapHttpModelToStoreModel(data));
      }),
      this.errorHandlerService.catchError(
        '[ReferenceQuestionHttpService]: Updating the syllabus topic failed',
        rethrowError(),
        {
          toast: (err) => ({
            title: 'An error occurred while updating the syllabus topic',
            description: (err as Error)?.message,
          }),
        },
      ),
    );
  }

  setActiveBySlug(slug: TopicLevel1StoreModel['slug']) {
    const topic = this.query.getAllUIEntities().find((t) => t.slug === slug);
    if (!topic) {
      this.errorHandlerService.error(`Topic level 1 with slug ${slug} not found`);
      return;
    }
    return this.store.setActive(topic.id);
  }

  reset() {
    this.store.reset();
  }

  private mapUpdateModelToHttpUpdateModel(
    updateModel: TopicLevel1UpdateModel,
  ): TableUpdateModel<'topics_level_01', 'categories'> {
    return sanitizeObject(updateModel, TOPIC_LEVEL_1_UPDATE_ALLOWED_KEYS);
  }

  private mapHttpModelToStoreModel(httpModel: TopicLevel1HttpModel): TopicLevel1StoreModel {
    const name = httpModel.name as LocalizedString;
    const description = httpModel.description as LocalizedString;
    return {
      ...httpModel,
      name,
      description,
      slug: kebabCase(name.en),
    };
  }
}
