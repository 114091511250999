import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, signal } from '@angular/core';
import { IonImg, IonSpinner } from '@ionic/angular/standalone';

@Component({
  selector: 'dojo-image',
  imports: [IonSpinner, IonImg, NgClass],
  templateUrl: './image.component.html',
  host: { class: 'block' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  readonly src = input.required<string>();
  readonly alt = input<string>();
  readonly classes = input<string>();

  readonly isImageLoaded = signal(false);

  onImageLoad() {
    this.isImageLoaded.set(true);
  }
}
