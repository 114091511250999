import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { connectState } from '@examdojo/angular/util';
import { AvatarModule } from '@examdojo/core/avatar';
import { IconComponent, IconSizeType } from '@examdojo/core/icon';
import { UserUIModel } from '@examdojo/models/user';
import { combineLatest, map, Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { UserQuery } from '../user.query';

@Component({
  selector: 'dojo-profile-avatar',
  templateUrl: './profile-avatar.component.html',
  styleUrls: ['./profile-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'flex' },
  standalone: true,
  imports: [IconComponent, AvatarModule],
})
export class ProfileAvatarComponent {
  constructor(
    private readonly userQuery: UserQuery,
    private readonly authService: AuthService,
  ) {}

  readonly user = input<UserUIModel>();
  readonly size = input<IconSizeType>(16);

  readonly clicked = output();

  private readonly user$: Observable<UserUIModel | null> = combineLatest([
    toObservable(this.user),
    this.userQuery.active$,
  ]).pipe(map(([inputUser, queryUser]) => inputUser ?? queryUser));

  readonly state = connectState({
    avatarUrl: combineLatest([
      this.user$.pipe(
        map((user) => {
          if (user?.profile_pic) {
            return this.userQuery.getProfilePicturePublicUrl(user.profile_pic);
          }
          return null;
        }),
      ),
      this.authService.oAuthProfilePic$,
    ]).pipe(
      map(([profilePic, oAuthProfilePic]) => (profilePic ?? oAuthProfilePic) as `${string}.${'png' | 'jpg'}` | null),
    ),
    user: this.user$,
  });
}
