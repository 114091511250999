import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { distinctUntilDeepChanged } from '@examdojo/rxjs';
import { QueryEntity } from '@examdojo/state';
import { map, Observable, of, switchMap } from 'rxjs';
import { TopicLevel1Query, TopicLevel1StoreModel } from '../topic-level-1';
import { TopicLevel2StoreModel, TopicLevel2UIModel } from './topic-level-2.model';
import { TopicLevel2State, TopicLevel2Store } from './topic-level-2.store';

@Injectable({ providedIn: 'root' })
export class TopicLevel2Query extends QueryEntity<
  TopicLevel2State,
  TopicLevel2StoreModel,
  TopicLevel2UIModel,
  'id',
  number
> {
  constructor(
    protected override store: TopicLevel2Store,
    private readonly topicLevel1Query: TopicLevel1Query,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {
    super(
      store,
      () => [topicLevel1Query.entities$, this.weights$, this.proficiencies$],
      (id) => [this.selectTopicLevel1(id), this.selectWeightsById(id), this.proficiencies$],
    );
  }

  readonly loaded$: Observable<boolean> = this.entities$.pipe(map((entities) => entities.length > 0));

  readonly weights$ = this.selectProp('weights');
  readonly areWeightsLoaded$ = this.selectProp('weights').pipe(map((weights) => weights !== null));

  readonly proficiencies$ = this.selectProp('proficiencies');
  readonly predictedScore$ = this.selectProp('predictedScore');

  override readonly toUIModelFn = (storeModel: TopicLevel2StoreModel): TopicLevel2UIModel => {
    const codeNumber = storeModel.code.split(' ').at(-1);

    if (!codeNumber) {
      this.errorHandlerService.error(`[TopicLevel2Query.toUIModelFn]: Cannot extract code number from code`, {
        context: { codeNumber, storeModel },
      });
    }

    const topicLevel1 = this.topicLevel1Query.getUIEntity(storeModel.topic_level_01_id);

    const weight = this.getProp('weights')?.find(({ topic_id }) => topic_id === storeModel.id);

    const proficiency = (this.getProp('proficiencies') ?? {})[storeModel.id];

    return {
      ...storeModel,
      codeNumber: codeNumber ?? '',
      topicLevel1: topicLevel1 ?? undefined,
      weight,
      proficiency,
    };
  };

  private selectTopicLevel1(id: TopicLevel2StoreModel['id']): Observable<TopicLevel1StoreModel | null> {
    return this.selectEntity(id).pipe(
      map((entity) => entity?.topic_level_01_id),
      switchMap((topicLevel1Id) => {
        if (!topicLevel1Id) {
          return of(null);
        }
        return this.topicLevel1Query.entities$.pipe(
          map((entities) => entities.find((entity) => entity.id === topicLevel1Id) ?? null),
        );
      }),
      distinctUntilDeepChanged(),
    );
  }

  private selectWeightsById(id: TopicLevel2StoreModel['id']) {
    return this.weights$.pipe(map((weights) => weights?.filter((weight) => weight.topic_id === id)));
  }
}
