import { Injectable } from '@angular/core';
import { QueryEntity } from '@examdojo/state';
import { isNotNullish } from '@examdojo/util/nullish';
import { combineLatest, debounceTime, distinctUntilChanged, filter, map } from 'rxjs';
import { CourseLevel } from '../model';
import { SyllabusStoreModel, SyllabusUIModel } from './syllabus.model';
import { SyllabusState, SyllabusStore } from './syllabus.store';

@Injectable({ providedIn: 'root' })
export class SyllabusQuery extends QueryEntity<SyllabusState, SyllabusStoreModel, SyllabusUIModel, 'id', number> {
  constructor(protected override store: SyllabusStore) {
    super(store);
  }

  readonly courseLevel$ = this.selectProp('course_level');

  readonly activeIdAndCourseLevel$ = combineLatest([
    this.selectActiveId().pipe(distinctUntilChanged()),
    this.courseLevel$.pipe(distinctUntilChanged()),
  ]).pipe(
    filter((arr): arr is [SyllabusStoreModel['id'], CourseLevel] => arr.every(isNotNullish)),
    debounceTime(0), // Both value are set at the same time in the store, so we take them in one shot
    map(([syllabusId, courseLevel]) => ({ syllabusId, courseLevel })),
  );
}
