import { Injectable } from '@angular/core';
import { UserRole, UserStoreModel } from '@examdojo/models/user';
import { EntityState, EntityStore } from '@examdojo/state';

export interface UserState extends EntityState<UserStoreModel> {
  props: {
    currentUserRole: UserRole | null;
  };
}

@Injectable({ providedIn: 'root' })
export class UserStore extends EntityStore<UserState, UserStoreModel, 'id'> {
  constructor() {
    super({
      name: 'users',
      idKey: 'id',
    });
  }
}
