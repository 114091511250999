import escapeRegExp from 'lodash/escapeRegExp';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  startWith,
  tap,
} from 'rxjs';

export interface FilteredArrayOptions<T> {
  filter$: Observable<string>;
  items$: Observable<T[]>;
  regExpFactory: (searchFilter: string) => RegExp;
  filterItemPropertyValueFn?: (item: T) => string;
  debounceTimeMs?: number;
}

export interface Filter {
  value: string;
  regExp: RegExp | null;
}

export function filteredArray<T>({
  filter$,
  items$,
  regExpFactory,
  filterItemPropertyValueFn,
  debounceTimeMs = 100,
}: FilteredArrayOptions<T>) {
  const filter$$ = new BehaviorSubject<Filter>({
    value: '',
    regExp: null,
  });

  const getItemFilterPropertyValue = (item: T): string =>
    filterItemPropertyValueFn ? filterItemPropertyValueFn(item) : (item as unknown as string);

  const regExpFilter$ = filter$.pipe(
    startWith(''),
    debounceTime(debounceTimeMs),
    map((filterValue) => filterValue.trim()),
    distinctUntilChanged(),
    map((filterValue) => ({
      filterValue,
      regExp: filterValue ? regExpFactory(filterValue) : null,
    })),
    tap(({ filterValue, regExp }) => {
      filter$$.next({
        value: filterValue,
        regExp,
      });
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  return {
    filter$: filter$$.asObservable(),
    items$: combineLatest([items$.pipe(startWith([])), regExpFilter$]).pipe(
      map(([items, { regExp }]) =>
        regExp
          ? items.filter((item) => {
              const propertyValue = getItemFilterPropertyValue(item);
              if (typeof propertyValue !== 'string') {
                console.warn(`[${filteredArray.name}]: filter property is not a string`);
                return false;
              }
              return !!`${propertyValue}`.match(regExp);
            })
          : items,
      ),
    ),
  };
}

/**
 * RegExp factory that filters an array based on partial matchs with gaps like in vscode, eg: if we search by "asd" the
 * regexp will be ".*a.*s.*d.*"
 */
export const gapsRegExpFactory = (filterValue: string) => {
  const chars = filterValue.split('');
  const escapedChars = chars.map((char) => escapeRegExp(char));
  const regExp = [null, ...escapedChars, null].join('.*');
  return new RegExp(regExp, 'i');
};
