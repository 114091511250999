import { Injectable } from '@angular/core';
import { LocalizedString } from '@examdojo/core/i18n';
import { FaIcon } from '@examdojo/core/icon';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { mapToVoid, rethrowError } from '@examdojo/rxjs';
import { TableUpdateModel } from '@examdojo/supabase';
import { sanitizeObject } from '@examdojo/util/sanitize-object';
import kebabCase from 'lodash/kebabCase';
import { merge, of, tap } from 'rxjs';
import { CourseLevel } from '../model';
import { SyllabusStoreModel } from '../syllabus';
import { ProficiencyHttpService } from './proficiency';
import { TopicLevel2HttpService } from './topic-level-2-http.service';
import { TopicLevel2WeightsHttpService } from './topic-level-2-weights-http.service';
import {
  TOPIC_LEVEL_2_UPDATE_ALLOWED_KEYS,
  TopicLevel2HttpModel,
  TopicLevel2StoreModel,
  TopicLevel2UpdateModel,
  TopicLevel2Weight,
} from './topic-level-2.model';
import { TopicLevel2Query } from './topic-level-2.query';
import { TopicLevel2Store } from './topic-level-2.store';

@Injectable({
  providedIn: 'root',
})
export class TopicLevel2Service {
  constructor(
    private readonly store: TopicLevel2Store,
    private readonly query: TopicLevel2Query,
    private readonly httpService: TopicLevel2HttpService,
    private readonly weightsHttpService: TopicLevel2WeightsHttpService,
    private readonly proficiencyHttpService: ProficiencyHttpService,
    private readonly errorHandler: ErrorHandlerService,
  ) {}

  fetchByIds(
    ids: Array<TopicLevel2StoreModel['id']>,
    context?: {
      syllabusId?: SyllabusStoreModel['id'];
      courseLevel?: CourseLevel;
    },
  ) {
    return merge(
      this.httpService.fetchByIds(ids, context).pipe(
        tap((data) => {
          this.store.set(data.map((item) => this.mapHttpModelToStoreModel(item)));
        }),
      ),
      this.fetchWeightsByIds(ids, context),
    ).pipe(
      this.errorHandler.catchError('[TopicLevel2Service]: Fetching the topics failed', rethrowError(), {
        toast: 'An error occurred while fetching the topics',
      }),
    );
  }

  fetchWeightsByIds(
    tl2Ids: Array<TopicLevel2StoreModel['id']>,
    filters?: {
      syllabusId?: SyllabusStoreModel['id'];
      courseLevel?: CourseLevel;
    },
  ) {
    return this.weightsHttpService.fetchByIds(tl2Ids, filters).pipe(
      tap((data) => {
        this.store.updateProps(() => ({
          weights: data.map(
            (item): TopicLevel2Weight => ({
              topic_id: item.topic_id!,
              syllabus_id: item.syllabus_id!,
              course_level: item.course_level! as CourseLevel,
              weight: item.weight!,
            }),
          ),
        }));
      }),
      this.errorHandler.setHttpErrorMetadata({
        entity: 'examdojo.entity.topic_level_02',
      }),
      mapToVoid(),
    );
  }

  update(id: TopicLevel2StoreModel['id'], model: TopicLevel2UpdateModel) {
    const httpModel = this.mapUpdateModelToHttpUpdateModel(model);

    return this.httpService.update(id, httpModel).pipe(
      tap((data) => {
        this.store.upsert(id, this.mapHttpModelToStoreModel(data));
      }),
      this.errorHandler.catchError(
        '[ReferenceQuestionHttpService]: Updating the syllabus topic failed',
        rethrowError(),
        {
          toast: (err) => ({
            title: 'An error occurred while updating the syllabus topic',
            description: (err as Error)?.message,
          }),
        },
      ),
    );
  }

  setActiveBySlug(slug: TopicLevel2StoreModel['slug']) {
    const topic = this.query.getAllUIEntities().find((t) => t.slug === slug);
    if (!topic) {
      this.errorHandler.error(`Topic level 2 with slug ${slug} not found`);
      return;
    }
    return this.store.setActive(topic.id);
  }

  reset() {
    this.store.reset();
  }

  fetchProficiencies() {
    return this.proficiencyHttpService.fetch().pipe(
      tap((proficiencies) => {
        this.store.updateProps(() => ({ proficiencies }));
      }),
      this.errorHandler.setHttpErrorMetadata({ entity: 'proficiencies' }),
    );
  }

  fetchPredictedScore(ids: Array<TopicLevel2StoreModel['id']>) {
    if (!ids.length) {
      this.store.updateProps(() => ({ predictedScore: null }));
      return of(undefined);
    }

    return this.httpService.fetchPredictedScore(ids).pipe(
      tap((predictedScore) => {
        this.store.updateProps(() => ({ predictedScore }));
      }),
      this.errorHandler.setHttpErrorMetadata({
        entity: 'examdojo.entity.topic_level_02',
      }),
      mapToVoid(),
    );
  }

  private mapUpdateModelToHttpUpdateModel(
    updateModel: TopicLevel2UpdateModel,
  ): TableUpdateModel<'topics_level_02', 'categories'> {
    return sanitizeObject(updateModel, TOPIC_LEVEL_2_UPDATE_ALLOWED_KEYS);
  }

  private mapHttpModelToStoreModel(httpModel: TopicLevel2HttpModel): TopicLevel2StoreModel {
    const name = httpModel.name as LocalizedString;
    return {
      ...httpModel,
      name,
      description: httpModel.description as LocalizedString,
      example: httpModel.example as LocalizedString,
      short_name: httpModel.short_name as LocalizedString,
      slug: kebabCase(`${httpModel.code} ${name.en}`),
      icon: httpModel.icon as FaIcon | null,
    };
  }
}
