import { Injectable } from '@angular/core';
import { distinctUntilDeepChanged } from '@examdojo/rxjs';
import { QueryEntity } from '@examdojo/state';
import { map, Observable, of, switchMap } from 'rxjs';
import { SyllabusQuery, SyllabusStoreModel } from '../syllabus';
import { TopicLevel1StoreModel, TopicLevel1UIModel } from './topic-level-1.model';
import { TopicLevel1State, TopicLevel1Store } from './topic-level-1.store';

@Injectable({ providedIn: 'root' })
export class TopicLevel1Query extends QueryEntity<
  TopicLevel1State,
  TopicLevel1StoreModel,
  TopicLevel1UIModel,
  'id',
  number
> {
  constructor(
    protected override store: TopicLevel1Store,
    private readonly syllabusQuery: SyllabusQuery,
  ) {
    super(
      store,
      () => [syllabusQuery.entities$],
      (id) => [this.selectSyllabus(id)],
    );
  }

  override readonly toUIModelFn = (entity: TopicLevel1StoreModel): TopicLevel1UIModel => {
    const syllabus = this.syllabusQuery.getUIEntity(entity.syllabus_id)!;

    return { ...entity, syllabus };
  };

  private selectSyllabus(id: TopicLevel1StoreModel['id']): Observable<SyllabusStoreModel | null> {
    return this.selectEntity(id).pipe(
      map((entity) => entity?.syllabus_id),
      switchMap((syllabusId) => {
        if (!syllabusId) {
          return of(null);
        }
        return this.syllabusQuery.entities$.pipe(
          map((entities) => entities.find((entity) => entity.id === syllabusId) ?? null),
        );
      }),
      distinctUntilDeepChanged(),
    );
  }
}
