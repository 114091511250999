import { Injectable } from '@angular/core';
import { EntityState, EntityStore } from '@examdojo/state';
import { CourseLevel } from '../model';
import { SyllabusStoreModel } from './syllabus.model';

export type SyllabusState = EntityState<SyllabusStoreModel, { course_level?: CourseLevel }, number>;

@Injectable({ providedIn: 'root' })
export class SyllabusStore extends EntityStore<SyllabusState, SyllabusStoreModel, 'id', number> {
  constructor() {
    super({
      name: 'syllabuses',
      idKey: 'id',
    });
  }
}
