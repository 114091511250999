import { ChangeDetectorRef, DestroyRef, Directive, HostListener, input, Optional, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControlStatus, FormGroup } from '@angular/forms';
import { BaseButton } from '@examdojo/core/button';
import { shareOneReplay } from '@examdojo/rxjs';
import { isNullish } from '@examdojo/util/nullish';
import {
  combineLatest,
  filter,
  finalize,
  first,
  map,
  merge,
  Observable,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { SwiperStepperComponent } from './swiper-stepper.component';

@Directive({
  selector: '[swiperStepperNext]',
  standalone: true,
})
export class SwiperStepperNextDirective {
  constructor(
    private readonly button: BaseButton,
    private readonly destroyRef: DestroyRef,
    private readonly cdRef: ChangeDetectorRef,
    @Optional() private readonly parentSwiperStepperComponent?: SwiperStepperComponent<FormGroup>,
  ) {
    merge(
      this.changeButtonTypeFromSlide(),
      this.disableOnActiveControlStatusChange(),
      this.swiperStepperComponent$.pipe(
        switchMap((swiperStepperComponent) => swiperStepperComponent.goNextTrigger$),
        tap(({ force }) => this.goNextOrSubmit({ force })),
      ),
      this.swiperStepperComponent$.pipe(
        switchMap((swiperStepperComponent) => swiperStepperComponent.pending$),
        tap((pending) => {
          this.button.pending = pending;
          this.cdRef.markForCheck();
        }),
      ),
    )
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  readonly swiperStepperComponent = input<SwiperStepperComponent<FormGroup> | undefined>(
    this.parentSwiperStepperComponent,
    { alias: 'swiperStepper' },
  );

  readonly swiperStepperComponent$ = toObservable(this.swiperStepperComponent).pipe(first(Boolean));

  readonly activeControlStatus$: Observable<FormControlStatus | null | undefined> = this.swiperStepperComponent$.pipe(
    switchMap((swiperStepperComponent) => swiperStepperComponent.activeControlStatus$),
    startWith<FormControlStatus | null | undefined>(undefined),
    shareOneReplay(),
  );

  readonly isTransitioning = signal(false);

  readonly enabled = input(true, {
    alias: 'swiperStepperNext',
    transform: (value?: boolean | ''): boolean => {
      if (isNullish(value) || value === '') {
        return true;
      }
      return value;
    },
  });

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.enabled()) {
      return;
    }

    this.goNextOrSubmit();
  }

  private goNextOrSubmit({ force = false }: { force?: boolean } = {}) {
    return this.swiperStepperComponent$
      .pipe(
        withLatestFrom(this.activeControlStatus$),
        filter(([_, activeControlStatus]) => {
          if (activeControlStatus === undefined) {
            // the control is not ready
            return false;
          }
          if (activeControlStatus === null) {
            // there is no control
            return true;
          }
          return activeControlStatus === 'VALID';
        }),
        tap(() => this.isTransitioning.set(true)),
        switchMap(([swiperStepperComponent]) =>
          swiperStepperComponent.activeSlideIndex$.pipe(
            first(),
            switchMap((previousSlideIndex) =>
              swiperStepperComponent
                .goNextOrSubmit({ force })
                .pipe(
                  switchMap(() =>
                    swiperStepperComponent.activeSlideIndex$.pipe(first((index) => index !== previousSlideIndex)),
                  ),
                ),
            ),
            finalize(() => this.isTransitioning.set(false)),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private changeButtonTypeFromSlide() {
    return this.swiperStepperComponent$.pipe(
      switchMap((swiperStepperComponent) => swiperStepperComponent.isLastStep$),
      tap((isLastStep) => {
        this.button.type.set(isLastStep ? 'submit' : 'button');
      }),
    );
  }

  private disableOnActiveControlStatusChange() {
    return combineLatest([
      this.activeControlStatus$.pipe(
        filter(() => this.enabled()),
        filter((status) => status !== undefined),
      ),
      toObservable(this.isTransitioning),
    ]).pipe(
      map(([status, isTransitioning]): boolean => isTransitioning || (!!status && status !== 'VALID')),
      tap((disable) => {
        this.button.disabled.set(disable);
      }),
    );
  }
}
