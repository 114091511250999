import { Injectable } from '@angular/core';
import { Proficiency } from '@examdojo/models/proficiency/proficiency.model';
import { Query } from '@examdojo/state';
import { isNotNullish } from '@examdojo/util/nullish';
import { combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { CategoryState, CategoryStore } from './category.store';
import { HydratedSyllabusTopicsTree } from './model';
import { SyllabusQuery } from './syllabus';
import { TopicLevel1Query, TopicLevel1StoreModel } from './topic-level-1';
import { TopicLevel2Query } from './topic-level-2';

@Injectable({ providedIn: 'root' })
export class CategoryQuery extends Query<CategoryState> {
  constructor(
    protected override readonly store: CategoryStore,
    private readonly syllabusQuery: SyllabusQuery,
    private readonly topicLevel1Query: TopicLevel1Query,
    private readonly topicLevel2Query: TopicLevel2Query,
  ) {
    super(store);
  }

  readonly topicsTree$ = this.select('topicsTree');
  readonly gradeBoundaries$ = this.select('gradeBoundaries');

  readonly activeSyllabusGradeBoundaries$ = this.syllabusQuery.activeIdAndCourseLevel$.pipe(
    switchMap(({ syllabusId, courseLevel }) => {
      return this.gradeBoundaries$.pipe(
        map(
          (gradeBoundaries) =>
            gradeBoundaries?.filter(
              (gradeBoundary) => gradeBoundary.syllabus_id === syllabusId && gradeBoundary.course_level === courseLevel,
            ),
        ),
      );
    }),
  );

  readonly activeSyllabusTopics$: Observable<HydratedSyllabusTopicsTree> = combineLatest([
    this.syllabusQuery.active$,
    this.topicsTree$,
  ]).pipe(
    switchMap(([activeSyllabus, allTopics]) => {
      if (!activeSyllabus || !allTopics) {
        return of([]);
      }

      const syllabusTree = allTopics.find(({ id }) => id === activeSyllabus.id);
      if (!syllabusTree) {
        return of([]);
      }

      return combineLatest([this.topicLevel1Query.entities$, this.topicLevel2Query.entities$]).pipe(
        map(([topicsLevel1, topicsLevel2]): HydratedSyllabusTopicsTree => {
          return syllabusTree.topics_level_01
            .map((topicLevel1) => {
              const topicLevel1UIModel = topicsLevel1.find(({ id }) => id === topicLevel1.id);

              if (!topicLevel1UIModel) {
                return null;
              }

              return {
                ...topicLevel1UIModel,
                level: 1,
                code: topicLevel1.code,
                children: topicLevel1.topics_level_02
                  .map((topicLevel2) => {
                    const topicLevel2UIModel = topicsLevel2.find(({ id }) => id === topicLevel2.id);

                    if (!topicLevel2UIModel) {
                      return null;
                    }

                    return {
                      ...topicLevel2UIModel,
                      level: 2,
                      code: topicLevel2.code,
                      latest_pages: topicLevel2.latest_pages ?? [],
                    } as const;
                  })
                  .filter(isNotNullish),
              } as const;
            })
            .filter(isNotNullish);
        }),
      );
    }),
  );

  selectTopicLevel1Proficiency(
    topicId: TopicLevel1StoreModel['id'],
  ): Observable<(Proficiency & { hasMissingTl2Proficiencies: boolean }) | undefined> {
    return this.topicLevel2Query.proficiencies$.pipe(
      map((proficiencies) => proficiencies ?? {}),
      map((proficiencies) => {
        const tl2s = this.topicLevel2Query.getAllUIEntities().filter((tl2) => tl2.topic_level_01_id === topicId);

        // Check if all proficiencies are undefined
        const hasAnyProficiency = tl2s.some((tl2) => proficiencies[tl2.id] !== undefined);
        if (!hasAnyProficiency) {
          return undefined;
        }

        // Calculate weighted proficiencies in a single reduce operation
        const { totalWeight, weightedSums, hasMissingProficiencies } = tl2s.reduce(
          (acc, tl2) => {
            const weight = tl2.weight?.weight ?? 0;
            const prof = proficiencies[tl2.id] ?? { mean: 0.5, lower: 0.16, upper: 0.84 };

            return {
              totalWeight: acc.totalWeight + weight,
              weightedSums: {
                mean: acc.weightedSums.mean + prof.mean * weight,
                lower: acc.weightedSums.lower + prof.lower * weight,
                upper: acc.weightedSums.upper + prof.upper * weight,
              },
              hasMissingProficiencies: acc.hasMissingProficiencies || !proficiencies[tl2.id],
            };
          },
          {
            totalWeight: 0,
            weightedSums: { mean: 0, lower: 0, upper: 0 },
            hasMissingProficiencies: false,
          },
        );

        // Calculate final weighted averages
        return {
          mean: weightedSums.mean / totalWeight,
          lower: weightedSums.lower / totalWeight,
          upper: weightedSums.upper / totalWeight,
          hasMissingTl2Proficiencies: hasMissingProficiencies,
        };
      }),
    );
  }
}
