<div class="flex flex-col gap-4 p-4">
  <div class="flex justify-end bg-white">
    <dojo-classic-button (click)="close()">Close</dojo-classic-button>
  </div>
  <div class="flex gap-4">
    <div class="flex-1">
      <div class="question-validation-header" style="border-top: 1px solid #d2d2d2">
        Question (id: {{ question.id }})
      </div>
      <dojo-master-question-content
        class="p-6"
        [question]="question"
        [questionItems]="questionItems"
        [totalMarks]="totalMarks"
      />
    </div>
    <div class="flex-1">
      <div class="question-validation-header" style="border-top: 1px solid #d2d2d2">Markscheme</div>
      <div class="p-8">
        <dojo-markscheme-viewer [markScheme]="markscheme" [allowImageDelete]="false" />
      </div>
    </div>
  </div>
</div>
