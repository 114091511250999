import { Component, EventEmitter, Input, Output, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatListModule, MatListOption } from '@angular/material/list';
import { RxAfterViewInit } from '@examdojo/angular/util';
import { Icon, IconComponent } from '@examdojo/core/icon';
import { SelectOption } from '@examdojo/util/select-option';
import { TranslocoPipe } from '@jsverse/transloco';
import { delay, filter, Observable, tap } from 'rxjs';
import { TopicCodeNumberComponent } from '../topic-code-number/topic-code-number.component';

export interface SelectionListOption<T> extends SelectOption<T> {
  figure?: { type: 'icon'; icon: Icon } | { type: 'label'; label: string };
  labelAdditional?: string;
}

export type CompareWithFn<T> = (o1: T, o2: T) => boolean;

@RxAfterViewInit()
@Component({
  selector: 'dojo-selection-list',
  imports: [MatListModule, ReactiveFormsModule, TranslocoPipe, IconComponent, TopicCodeNumberComponent],
  template: `
    <mat-selection-list
      class="custom-selection-list-checkbox"
      [formControl]="formCtrl"
      (selectionChange)="optionSelected.emit($event.options)"
      [multiple]="multiple"
      [compareWith]="compareWith ?? defaultCompareWith"
    >
      @for (option of options; track option.value; let idx = $index) {
        <mat-list-option
          [value]="option.value"
          [class.hide-indicator]="hideIndicator"
          [disabled]="option.disabled === true"
        >
          <div class="flex items-center gap-2">
            @if (option.figure) {
              @switch (option.figure.type) {
                @case ('icon') {
                  <dojo-icon [icon]="option.figure.icon" [alt]="option.label" [size]="figureIconSize" />
                }
                @case ('label') {
                  <dojo-topic-code-number [codeNumber]="option.figure.label" />
                }
              }
            }

            @if (option?.labelDescription) {
              <div class="inner-component ml-1 flex flex-col gap-1 truncate">
                <span class="text-primary-exam truncate">
                  @if (labelsAreTranslationKeys) {
                    {{ option.label | transloco: option.translationParams || {} }}
                  } @else {
                    {{ option.label }}
                  }
                </span>
                <span class="text-secondary font-normal-exam truncate">
                  @if (labelsAreTranslationKeys) {
                    {{ option.labelDescription | transloco }}
                  } @else {
                    {{ option.labelDescription }}
                  }
                </span>
              </div>
            } @else {
              <span class="w-full truncate">
                @if (labelsAreTranslationKeys) {
                  @if (isDividedLabel) {
                    <span class="flex w-full items-center justify-between gap-2">
                      <span>{{ option.label | transloco: option.translationParams || {} }}</span>
                      <span class="text-xs font-semibold">{{
                        option.labelAdditional | transloco: option.translationParams || {}
                      }}</span>
                    </span>
                  } @else {
                    {{ option.label | transloco: option.translationParams || {} }}
                  }
                } @else {
                  @if (isDividedLabel) {
                    <span class="flex w-full items-center justify-between gap-2">
                      <span>{{ option.label }}</span>
                      <span class="text-xs font-semibold">{{ option.labelAdditional }}</span>
                    </span>
                  } @else {
                    {{ option.label }}
                  }
                }
              </span>
            }
          </div>
        </mat-list-option>
      }
    </mat-selection-list>
  `,
  styleUrl: './selection-list.component.scss',
})
export class SelectionListComponent<T = unknown> {
  constructor() {
    this.setInitialFocus().pipe(takeUntilDestroyed()).subscribe();
  }

  @Input({ required: true }) formCtrl!: FormControl;
  @Input({ required: true }) options: Array<SelectionListOption<T>> = [];
  @Input() multiple = false;
  @Input() labelsAreTranslationKeys = false;
  @Input() hasInitialFocus = false;
  @Input() isDividedLabel = false;
  @Input() hideIndicator = false;
  @Input() figureIconSize = 30;

  @Output() optionSelected = new EventEmitter<MatListOption[]>();

  private readonly matListOption = viewChild<MatListOption>(MatListOption);

  readonly ngAfterViewInit$!: Observable<void>;

  @Input() compareWith?: CompareWithFn<T>;

  readonly defaultCompareWith: CompareWithFn<T> = (o1, o2) => o1 === o2;

  setFocus() {
    this.matListOption()?.focus();
  }

  private setInitialFocus() {
    return this.ngAfterViewInit$.pipe(
      filter(() => this.hasInitialFocus),
      delay(0),
      tap(() => this.setFocus()),
    );
  }
}
