import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@examdojo/core/environment';
import { StatisticalRange } from '@examdojo/core/model';
import { SupabaseBaseHttpService } from '@examdojo/supabase';
import { from, map } from 'rxjs';
import { CourseLevel } from '../model';
import { TopicLevel2StoreModel } from './topic-level-2.model';

@Injectable({
  providedIn: 'root',
})
export class TopicLevel2HttpService extends SupabaseBaseHttpService<'topics_level_02', 'categories'> {
  readonly http = inject(HttpClient);

  readonly schema = 'categories';
  readonly tableName = 'topics_level_02';

  fetchByIds(
    ids: Array<TopicLevel2StoreModel['id']>,
    filters?: {
      courseLevel?: CourseLevel;
    },
  ) {
    let query = this.supabase.client.schema(this.schema).from(this.tableName).select().in('id', ids);

    if (filters?.courseLevel) {
      switch (filters.courseLevel) {
        case 'HL':
          query = query.in('course_level', ['HL', 'SL']);
          break;
        case 'SL':
          query = query.eq('course_level', 'SL');
          break;
      }
    }

    return from(query.throwOnError().then((response) => response.data!));
  }

  fetchPredictedScore(ids: Array<TopicLevel2StoreModel['id']>) {
    return this.http
      .get<{
        status: string;
        score: StatisticalRange;
      }>(`${environment.examdojo.examdojoApiUrl}/predicted-score`, { params: { topics_level_02: ids } })
      .pipe(map((response) => response.score));
  }
}
