import { Injectable } from '@angular/core';
import { distinctUntilDeepChanged } from '@examdojo/rxjs';
import { QueryEntity } from '@examdojo/state';
import { map, Observable, of, switchMap } from 'rxjs';
import { TopicLevel2Query, TopicLevel2StoreModel } from '../topic-level-2';
import { TopicLevel3StoreModel, TopicLevel3UIModel } from './topic-level-3.model';
import { TopicLevel3State, TopicLevel3Store } from './topic-level-3.store';

@Injectable({ providedIn: 'root' })
export class TopicLevel3Query extends QueryEntity<
  TopicLevel3State,
  TopicLevel3StoreModel,
  TopicLevel3UIModel,
  'id',
  number
> {
  constructor(
    protected override store: TopicLevel3Store,
    private readonly topicLevel2Query: TopicLevel2Query,
  ) {
    super(
      store,
      () => [topicLevel2Query.entities$],
      (id) => [this.selectTopicLevel2(id)],
    );
  }

  override readonly toUIModelFn = (entity: TopicLevel3StoreModel): TopicLevel3UIModel => {
    const topicLevel2 = entity.topic_level_02_id
      ? this.topicLevel2Query.getUIEntity(entity.topic_level_02_id)!
      : undefined;

    return { ...entity, topicLevel2 };
  };

  private selectTopicLevel2(id: TopicLevel3StoreModel['id']): Observable<TopicLevel2StoreModel | null> {
    return this.selectEntity(id).pipe(
      map((entity) => entity?.topic_level_02_id),
      switchMap((topicLevel2Id) => {
        if (!topicLevel2Id) {
          return of(null);
        }
        return this.topicLevel2Query.entities$.pipe(
          map((entities) => entities.find((entity) => entity.id === topicLevel2Id) ?? null),
        );
      }),
      distinctUntilDeepChanged(),
    );
  }
}
