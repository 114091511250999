import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@examdojo/core/environment';
import { Proficiency } from '@examdojo/models/proficiency/proficiency.model';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProficiencyHttpService {
  constructor(private readonly http: HttpClient) {}

  fetch() {
    return this.http
      .get<{
        result: {
          data: Record<string, Proficiency>;
        };
      }>(`${environment.examdojo.examdojoApiUrl}/topic-level-02-proficiencies`)
      .pipe(map((response) => response.result.data));
  }
}
