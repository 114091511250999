import { CdkTrapFocus } from '@angular/cdk/a11y';
import { Directive, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

@Directive({
  selector: 'swiper-slide',
  standalone: true,
  hostDirectives: [CdkTrapFocus],
})
export class SwiperSlideDirective {
  readonly controlName = input<string | undefined>();
  readonly skip = input<boolean>(false);

  readonly controlName$ = toObservable(this.controlName);
}
