import { LocalizedString } from '@examdojo/core/i18n';
import { TableInsertModel, TableRowModel, TableUpdateModel } from '@examdojo/supabase';

export type MarkschemeImageHttpModel = Pick<
  TableRowModel<'markscheme_images'>,
  'author' | 'created_at' | 'description' | 'id' | 'image_bucket' | 'image_name' | 'question_id' | 'updated_at'
>;

export type MarkschemeImageStoreModel = Omit<MarkschemeImageHttpModel, 'author' | 'description'> & {
  authorId: MarkschemeImageHttpModel['author'];
  description: LocalizedString;
  type: 'markscheme_image';
};

export type MarkschemeTextHttpModel = Pick<
  TableRowModel<'markscheme_texts'>,
  'author' | 'created_at' | 'id' | 'question_id' | 'text' | 'updated_at'
>;

export type MarkschemeTextStoreModel = Omit<MarkschemeTextHttpModel, 'author' | 'text'> & {
  authorId: MarkschemeTextHttpModel['author'];
  text: LocalizedString;
  type: 'markscheme_text';
};

export const MARKSCHEME_IMAGE_CREATE_ALLOWED_KEYS = ['description', 'image_name', 'question_id'] satisfies Array<
  keyof TableInsertModel<'markscheme_images'>
>;
export const MARKSCHEME_TEXT_CREATE_ALLOWED_KEYS = ['text', 'question_id'] satisfies Array<
  keyof TableInsertModel<'markscheme_texts'>
>;

export type MarkschemeImageCreateModel = Pick<
  TableInsertModel<'markscheme_images'>,
  (typeof MARKSCHEME_IMAGE_CREATE_ALLOWED_KEYS)[number]
>;
export type MarkschemeTextCreateModel = Pick<
  TableInsertModel<'markscheme_texts'>,
  (typeof MARKSCHEME_TEXT_CREATE_ALLOWED_KEYS)[number]
>;

export const MARKSCHEME_IMAGE_UPDATE_ALLOWED_KEYS = MARKSCHEME_IMAGE_CREATE_ALLOWED_KEYS satisfies Array<
  keyof TableUpdateModel<'markscheme_images'>
>;
export const MARKSCHEME_TEXT_UPDATE_ALLOWED_KEYS = MARKSCHEME_TEXT_CREATE_ALLOWED_KEYS satisfies Array<
  keyof TableUpdateModel<'markscheme_texts'>
>;

export type MarkschemeImageUpdateModel = Pick<
  TableUpdateModel<'markscheme_images'>,
  (typeof MARKSCHEME_IMAGE_UPDATE_ALLOWED_KEYS)[number]
>;
export type MarkschemeTextUpdateModel = Pick<
  TableUpdateModel<'markscheme_texts'>,
  (typeof MARKSCHEME_TEXT_UPDATE_ALLOWED_KEYS)[number]
>;
