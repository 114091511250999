import { LocalizedString } from '@examdojo/core/i18n';
import { TableInsertModel, TableRowModel, TableUpdateModel } from '@examdojo/supabase';
import { SyllabusUIModel } from '../syllabus';

export type TopicLevel1HttpModel = Pick<
  TableRowModel<'topics_level_01', 'categories'>,
  'active' | 'created_at' | 'id' | 'name' | 'sort_order' | 'syllabus_id' | 'updated_at' | 'color_name' | 'description'
>;

export type TopicLevel1StoreModel = Omit<TopicLevel1HttpModel, 'name' | 'description'> & {
  slug: string;
  name: LocalizedString;
  description: LocalizedString;
};

export type TopicLevel1UIModel = TopicLevel1StoreModel & {
  syllabus: SyllabusUIModel;
};

export const TOPIC_LEVEL_1_CREATE_ALLOWED_KEYS = [
  'name',
  'active',
  'created_at',
  'sort_order',
  'syllabus_id',
] satisfies Array<keyof TableInsertModel<'topics_level_01', 'categories'>>;

export type TopicLevel1CreateModel = Pick<
  TableInsertModel<'topics_level_01', 'categories'>,
  (typeof TOPIC_LEVEL_1_CREATE_ALLOWED_KEYS)[number]
>;

export const TOPIC_LEVEL_1_UPDATE_ALLOWED_KEYS = [...TOPIC_LEVEL_1_CREATE_ALLOWED_KEYS] satisfies Array<
  keyof TableUpdateModel<'topics_level_01', 'categories'>
>;

export type TopicLevel1UpdateModel = Pick<
  TableUpdateModel<'topics_level_01', 'categories'>,
  (typeof TOPIC_LEVEL_1_UPDATE_ALLOWED_KEYS)[number]
>;
