<div dojoArrowNavigationContainer class="flex flex-col">
  @if (header) {
    <span class="text-title-xs dark:font-medium-dark text-typography-secondary-add bg-gray-300 px-4 py-1 font-medium">{{
      header
    }}</span>
  }

  <div class="menu-header menu-header-search">
    <div class="filterable-list custom-mat-form-wrapper">
      <mat-form-field appearance="fill">
        <div matPrefix *ngIf="hasSearchIcon">
          <dojo-icon [icon]="'search'"></dojo-icon>
        </div>
        <input
          dojoArrowNavigable
          matInput
          [cdkTrapFocus]
          [cdkTrapFocusAutoCapture]="true"
          [formControl]="searchControl"
          [placeholder]="state.searchPlaceholder"
        />
      </mat-form-field>
    </div>
  </div>

  <div
    *ngIf="enableSelectedItemsListSummary && state.selectedItems.length > 0"
    class="mb-[8px] w-full overflow-x-auto rounded bg-gray-300 p-[8px]"
  >
    <mat-chip-grid #chipGrid>
      <mat-chip-row
        *ngFor="let item of state.selectedItems; trackBy: trackByIdentity"
        (removed)="setSelection(item, false)"
      >
        <ng-container
          *ngTemplateOutlet="
            summaryItemTemplate;
            context: {
              $implicit: item,
              itemsFilter: state.itemsFilter.value
            }
          "
        ></ng-container>
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
  </div>

  <ng-container *ngIf="state.isRegularList">
    <cdk-virtual-scroll-viewport [itemSize]="itemSize" class="items-wrapper">
      <button
        dojoArrowNavigable
        mat-menu-item
        *cdkVirtualFor="let item of state.items; trackBy: trackByIdentity"
        (click)="setSelection(item, true)"
        class="w-full px-2"
        [class.pointer-events-none]="disableItems"
        [ngClass]="{ active: state.selection[item | identify: identityFn] }"
      >
        <ng-container
          *ngTemplateOutlet="
            itemTemplate ?? defaultTemplate;
            context: {
              $implicit: item,
              itemsFilter: state.itemsFilter.value
            }
          "
        ></ng-container>
      </button>
      <div *ngIf="state.isEmptyListLabelEnabled" class="empty-text">
        {{ emptyListLabel }}
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-container>

  <ng-container *ngIf="state.isToggledList">
    <cdk-virtual-scroll-viewport [itemSize]="itemSize" class="items-wrapper">
      <div
        mat-menu-item
        *cdkVirtualFor="let item of state.items; trackBy: trackByIdentity"
        class="item w-full"
        [class.pointer-events-none]="disableItems"
      >
        <mat-checkbox
          color="primary"
          [ngModel]="state.selection[item | identify: identityFn]"
          [disableRipple]="true"
          (ngModelChange)="setSelection(item, $event)"
        >
          <ng-container
            *ngTemplateOutlet="
              itemTemplate ?? defaultTemplate;
              context: { $implicit: item, itemsFilter: state.itemsFilter.value }
            "
          ></ng-container>
        </mat-checkbox>
      </div>
      <div *ngIf="state.isEmptyListLabelEnabled" class="empty-text">
        {{ emptyListLabel }}
      </div>
    </cdk-virtual-scroll-viewport>
  </ng-container>

  <button
    dojoArrowNavigable
    mat-flat-button
    *ngIf="state.isCreateButtonEnabled || alwaysShowCreateItemButton"
    class="create-btn-bottom"
    (click)="create(searchControl.value)"
  >
    <dojo-icon [icon]="'plus'" class="mr-1"></dojo-icon>

    <span>{{ createItemButtonLabel }}{{ state.createValueFormatted ? ':' : '' }}</span>

    @if (state.createValueFormatted) {
      <span class="text-typography-secondary ml-1 truncate text-left">"{{ state.createValueFormatted }}"</span>
    }
  </button>
</div>

<ng-template #defaultTemplate let-item>
  {{ item }}
</ng-template>
