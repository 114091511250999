import { Injectable } from '@angular/core';
import { ExamdojoSupabaseService } from '@examdojo/supabase';
import { from } from 'rxjs';
import { CourseLevel } from '../model';
import { SyllabusHttpModel } from '../syllabus';
import { TopicLevel2StoreModel } from './topic-level-2.model';

@Injectable({
  providedIn: 'root',
})
export class TopicLevel2WeightsHttpService {
  constructor(protected readonly supabase: ExamdojoSupabaseService) {}

  fetchByIds(
    tl2Ids: Array<TopicLevel2StoreModel['id']>,
    filters?: {
      syllabusId?: SyllabusHttpModel['id'];
      courseLevel?: CourseLevel;
    },
  ) {
    let query = this.supabase.client.schema('learning').from('topic_level_02_weights').select().in('topic_id', tl2Ids);

    if (filters?.syllabusId) {
      query = query.eq('syllabus_id', filters.syllabusId);
    }
    if (filters?.courseLevel) {
      switch (filters.courseLevel) {
        case 'HL':
          query = query.in('course_level', ['HL', 'SL']);
          break;
        case 'SL':
          query = query.eq('course_level', 'SL');
          break;
      }
    }

    return from(query.throwOnError().then((response) => response.data!));
  }
}
