import { Injectable } from '@angular/core';
import { GradeBoundary } from '@examdojo/category';
import { Store } from '@examdojo/state';
import { createStore, withProps } from '@ngneat/elf';
import { TopicsTree } from './model';

export interface CategoryState {
  topicsTree?: TopicsTree;
  gradeBoundaries?: GradeBoundary[];
}

@Injectable({ providedIn: 'root' })
export class CategoryStore extends Store<CategoryState> {
  constructor() {
    super(createStore({ name: 'category' }, withProps<CategoryState>({})));
  }
}
