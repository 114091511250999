import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  signal,
  viewChild,
} from '@angular/core';
import { assertNonNullable } from '@examdojo/util/assert';
import { BehaviorSubject, delay, distinctUntilChanged } from 'rxjs';
import { SwiperContainer } from 'swiper/swiper-element';
import { Swiper } from 'swiper/types';
import { SwiperOptions } from 'swiper/types/swiper-options';

@Component({
  selector: 'dojo-swiper',
  imports: [],
  templateUrl: './swiper.component.html',
  styleUrl: './swiper.component.scss',
  host: { class: 'block' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SwiperComponent implements AfterViewInit {
  readonly onSlideChange = new EventEmitter<number>();

  private readonly defaultEvents: SwiperOptions['on'] = {
    init: (instance) => {
      this.instance.set(instance);
    },
    transitionEnd: (instance) => {
      const activeIndex = instance.activeIndex;
      this.activeSlideIndex$$.next(activeIndex);
      this.onSlideChange.emit(activeIndex);
    },
  };

  @Input() options: SwiperOptions | undefined = {
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
      type: 'bullets',
      clickable: true,
    },
    on: this.defaultEvents,
  };

  @Input() paginationTop?: boolean;

  private readonly containerRef = viewChild<ElementRef<SwiperContainer>>('container');
  private readonly container = computed(() => this.containerRef()?.nativeElement);

  readonly instance = signal<Swiper | undefined>(undefined);

  private readonly activeSlideIndex$$ = new BehaviorSubject(0);
  readonly activeSlideIndex$ = this.activeSlideIndex$$.asObservable().pipe(delay(0), distinctUntilChanged());

  isFirstSlide() {
    return this.instance()?.isBeginning || this.instance()?.activeIndex === 0;
  }

  isLastSlide() {
    return !!this.instance()?.isEnd;
  }

  ngAfterViewInit() {
    // For some reason the swiper instance gets destroyed if we initialize it immediately
    // The absence of error makes explaining this behaviour difficult
    setTimeout(() => {
      const container = this.container();
      assertNonNullable(container, 'container');
      const options: SwiperOptions = {
        ...(this.options ?? {}),
        on: { ...this.defaultEvents, ...(this.options?.on ?? {}) },
      };
      Object.assign(container, options);
      container.initialize();
    }, 0);
  }

  updateOption(key: keyof SwiperOptions, value: SwiperOptions[keyof SwiperOptions]) {
    const container = this.container();
    assertNonNullable(container, 'container');
    // @ts-ignore https://swiperjs.com/element#updating-parameters
    container[key] = value;
  }
}
