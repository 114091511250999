import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { provideFaIcons } from '@examdojo/icons';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { ConfirmDialogData } from './confirm-dialog.model';

@Injectable({ providedIn: 'root' })
export class ConfirmDialogService {
  constructor(private readonly dialog: MatDialog) {
    provideFaIcons([faTriangleExclamation]);
  }

  /**
   * Opens a confirmation dialog with 3 possible return values:
   * - true: clicked on the "confirm" button and the (optional) confirmFn function returned true.
   * - false: clicked on the "cancel" button or the (optional) confirmFn function returned false (error handling return value).
   * - undefined: dismissed the dialog by eg. clicking in the background (when disableClose is not enabled) without taking any action.
   */
  confirm(data: ConfirmDialogData): Observable<boolean | undefined> {
    return this.dialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, { data })
      .afterClosed();
  }

  open(data: ConfirmDialogData): MatDialogRef<ConfirmDialogComponent, boolean> {
    return this.dialog.open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, { data });
  }
}
