import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { connectState, RxInput, RxOnInit } from '@examdojo/angular/util';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map, Observable } from 'rxjs';
import { getLabelInitials } from './label.util';

@RxOnInit()
@UntilDestroy()
@Component({
  selector: 'dojo-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AvatarComponent {
  @Input() src: string | undefined = '';

  @Input()
  @RxInput()
  name = '';
  private readonly name$!: Observable<string>;

  @Input() borderColor?: string;
  @Input() size: string | number = '';
  @Input() cornerRadius: string | number = '';
  @Input() round = true;
  @Input() value?: string | null;
  @Input() bgColor: string | undefined;

  readonly state = connectState({
    label: this.name$.pipe(map(getLabelInitials)),
  });
}
