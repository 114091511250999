import { Injectable } from '@angular/core';
import { EntityStore } from '@examdojo/state';
import { CategoryState, CategoryStoreModel } from './category.model';

@Injectable({ providedIn: 'root' })
export class CategoryStore extends EntityStore<CategoryState, CategoryStoreModel, 'id'> {
  constructor() {
    super({
      name: 'categories',
      idKey: 'id',
      initialPropsState: {},
    });
  }
}
