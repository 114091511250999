import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ClassicButtonComponent } from '@examdojo/core/button';
import { MarkschemeViewerComponent } from '@examdojo/markscheme';
import { Markscheme } from '@examdojo/models/markscheme';
import { QuestionItemsUIModel, QuestionStoreModel } from '@examdojo/models/question';
import { ModalController } from '@ionic/angular/standalone';
import { MasterQuestionContentComponent } from '../../../master-question-content/master-question-content.component';
import { provideStemMarkschemeImage } from '../stem-editor/stem-markscheme-image';
import { QUESTION_PREVIEW_DIALOG_ID } from './question-preview-dialog.model';

@Component({
  selector: 'exam-question-preview-dialog',
  templateUrl: './question-preview-dialog.component.html',
  styleUrl: './question-preview-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MasterQuestionContentComponent, MarkschemeViewerComponent, ClassicButtonComponent],
  host: {
    class: 'overflow-y-auto',
  },
  providers: [provideStemMarkschemeImage()],
})
export class QuestionPreviewDialogComponent {
  constructor(private readonly modalController: ModalController) {}

  readonly question!: QuestionStoreModel;
  readonly totalMarks!: number;
  readonly questionItems!: QuestionItemsUIModel;
  readonly markscheme!: Markscheme;

  close() {
    this.modalController.dismiss(undefined, undefined, QUESTION_PREVIEW_DIALOG_ID);
  }
}
