import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { mapToVoid } from '@examdojo/rxjs';
import { EMPTY, first } from 'rxjs';
import { CategoryService } from './category.service';

export const topicsTreeResolver: ResolveFn<void> = () => {
  const categoryService = inject(CategoryService);
  const errorHandlerService = inject(ErrorHandlerService);

  return categoryService.fetchTopicsTree().pipe(
    errorHandlerService.catchHttpErrors(() => EMPTY),
    first(),
    mapToVoid(),
  );
};
