import { A11yModule, CdkTrapFocus } from '@angular/cdk/a11y';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Pipe,
  PipeTransform,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { connectState, getTrackByIdentityFn, InputObservable, trackByValue } from '@examdojo/angular/util';
import { ArrowNavigableDirective, ArrowNavigationContainerDirective } from '@examdojo/core/arrow-navigation';
import { IconComponent } from '@examdojo/core/icon';
import { assertNonNullable } from '@examdojo/util/assert';
import { filteredArray, gapsRegExpFactory } from '@examdojo/util/filtered-array';
import { keys } from '@examdojo/util/object-utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, debounceTime, map, Observable, shareReplay, startWith } from 'rxjs';
import { FilterableListItemContext, FilterableListItemDirective } from './filterable-list-item.directive';
import { FilterableListSummaryItemDirective } from './filterable-list-summary-item.directive';

@Pipe({
  name: 'identify',
  standalone: true,
})
export class IdentifyPipe<T> implements PipeTransform {
  transform(value: T, identityFn?: (value: T) => string): string {
    if (typeof value === 'string') {
      return value;
    }

    assertNonNullable(identityFn, 'identityFn');
    return identityFn(value);
  }
}

export type FilterableItemsListMode = 'toggled-list' | 'list';

@UntilDestroy()
@Component({
  selector: 'dojo-filterable-items-list',
  templateUrl: './filterable-items-list.component.html',
  styleUrls: ['./filterable-items-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule,
    ScrollingModule,
    MatMenuModule,
    A11yModule,
    ArrowNavigationContainerDirective,
    ArrowNavigableDirective,
    IconComponent,
    NgIf,
    NgForOf,
    NgClass,
    NgTemplateOutlet,
    IdentifyPipe,
  ],
})
export class FilterableItemsListComponent<T extends string | object, IdType extends string = string> {
  @ViewChild(CdkTrapFocus) trapFocus?: CdkTrapFocus;

  @Input()
  @InputObservable()
  listMode: FilterableItemsListMode = 'toggled-list';
  private readonly listMode$!: Observable<FilterableItemsListMode>;

  @Input() header?: string;

  @Input()
  enableSelectedItemsListSummary = false;

  @Input()
  @InputObservable()
  items: T[] = [];
  private readonly items$!: Observable<T[]>;

  @Input()
  @InputObservable()
  selected: IdType[] = [];
  private readonly selected$!: Observable<IdType[]>;

  @Input()
  identityFn?: (item: T) => IdType;

  @Input()
  filterPropertyValueFn?: (item: T) => string;

  @Input()
  @InputObservable()
  searchPlaceholder = '';
  private readonly searchPlaceholder$!: Observable<string>;

  @Input()
  emptyListLabel = '';

  @Input()
  @InputObservable()
  enableCreateItemButton = false;
  private readonly enableCreateItemButton$!: Observable<boolean>;

  @Input() alwaysShowCreateItemButton = false;

  @Input()
  createItemButtonLabel = '';

  @Input()
  @InputObservable()
  createValueFormatterFn?: (value: string) => string;
  private readonly createValueFormatterFn$!: Observable<((value: string) => string) | undefined>;

  @Input()
  itemSize = 30;

  @Input()
  hasSearchIcon = true;

  @Input()
  disableItems = false;

  /**
   * Emits all selected values
   */
  @Output()
  selectionChange = new EventEmitter<IdType[]>();

  /**
   * Emits the most recently selected value
   */
  @Output()
  selectItem = new EventEmitter<string>();

  @Output()
  createItem = new EventEmitter<string>();

  readonly trackByValue: TrackByFunction<T> = trackByValue<T>;
  readonly searchControl = new FormControl('', { nonNullable: true });

  private readonly selectionMap$ = this.selected$.pipe(
    map((selected) => {
      return selected.reduce(
        (selection, current) => {
          selection[this.getIdentity(current)] = true;
          return selection;
        },
        {} as Record<string, true>,
      );
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly filteredArray = filteredArray({
    filter$: this.searchControl.valueChanges.pipe(debounceTime(100)),
    items$: this.items$,
    regExpFactory: gapsRegExpFactory,
    filterItemPropertyValueFn: (item) => this.getFilterItemPropertyValue(item),
  });

  readonly trackByIdentity = getTrackByIdentityFn<T>((item) => this.getIdentity(item));

  readonly state = connectState({
    selection: this.selectionMap$,
    selectedItems: combineLatest([this.items$, this.selectionMap$]).pipe(
      map(([items, selection]) => {
        const identityFn = this.identityFn;
        if (identityFn) {
          return items.filter((item) => selection[identityFn(item)]);
        }

        return Object.keys(selection) as T[];
      }),
    ),
    items: combineLatest([this.filteredArray.items$, this.selectionMap$]).pipe(map(([items]) => items)),
    itemsFilter: this.filteredArray.filter$,
    searchPlaceholder: this.searchPlaceholder$,
    isEmptyListLabelEnabled: this.filteredArray.items$.pipe(map((items) => items?.length === 0 && this.emptyListLabel)),
    isCreateButtonEnabled: combineLatest([
      this.enableCreateItemButton$,
      this.filteredArray.items$,
      this.searchControl.valueChanges,
    ]).pipe(
      debounceTime(100),
      map(
        ([enableCreateItemButton, items, value]) =>
          enableCreateItemButton && value && !items.find((item) => this.getFilterItemPropertyValue(item) === value),
      ),
    ),
    isToggledList: this.listMode$.pipe(map((listMode) => listMode === 'toggled-list')),
    isRegularList: this.listMode$.pipe(map((listMode) => listMode === 'list')),
    createValueFormatted: combineLatest([
      this.searchControl.valueChanges.pipe(startWith('')),
      this.createValueFormatterFn$.pipe(startWith(undefined)),
    ]).pipe(
      map(([val, createValueFormatterFn]) => {
        if (!createValueFormatterFn) {
          return val;
        }

        return createValueFormatterFn(val);
      }),
    ),
  });

  @ContentChild(FilterableListItemDirective, { read: TemplateRef })
  readonly itemTemplate!: TemplateRef<FilterableListItemContext<T>>;
  @ContentChild(FilterableListSummaryItemDirective, { read: TemplateRef })
  readonly summaryItemTemplate!: TemplateRef<FilterableListSummaryItemDirective>;

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
  }

  create(name: string) {
    this.createItem.emit(name);
    this.searchControl.setValue('');
    this.trapFocus?.focusTrap.focusInitialElement();
  }

  setSelection(item: T, checked: boolean) {
    // This deliberately modifies the local state
    // for virtual-scroll to work as expected -
    // meaning that scrolling down and back up again keeps the checked state of a checkbox
    // in case the consuming component does not handle selection (like e.g. in Storybook).
    const selection = this.state.selection;
    const id = this.getIdentity(item);

    if (checked) {
      selection[id] = true;
      this.selectItem.emit(id);
    } else {
      delete selection[id];
    }

    this.selectionChange.emit(keys(selection));
  }

  getActiveClass(item: T) {
    const id = this.getIdentity(item);
    return this.state.selection[id] ? 'active' : null;
  }

  private getFilterItemPropertyValue(item: T): string {
    const value = this.filterPropertyValueFn ? this.filterPropertyValueFn(item) : (item as string);
    return value;
  }

  private getIdentity(item: T | string): IdType {
    if (typeof item === 'string') {
      return item as IdType;
    }

    const identityFn = this.identityFn;
    assertNonNullable(identityFn, 'identityFn');
    return identityFn(item);
  }
}
