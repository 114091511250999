<div class="custom-dialog relative">
  <!-- Title -->
  <div class="custom-dialog-header">
    <h3 [innerHtml]="state.dialogTitleSanitized"></h3>
    <div class="custom-dialog-header-right-side">
      <ng-content select="[y42DialogHeader]"></ng-content>
      <div class="header-actions">
        <y42-keyboard-shortcut-description-tooltip
          *ngIf="allowFullscreen"
          [keyboardShortcut]="{ sequence: state.toggleFullscreenKeys }"
          [position]="['below', 'center']"
        >
          <y42-icon-button
            [classes]="'icon-button-fullscreen'"
            [size]="'compact'"
            [icon]="
              state.fullscreen
                ? 'arrow-down-left-and-arrow-up-right-to-center'
                : 'arrow-up-right-and-arrow-down-left-from-center'
            "
            (click)="toggleFullscreen()"
          />
        </y42-keyboard-shortcut-description-tooltip>
        <y42-icon-button
          *ngIf="closeable"
          [icon]="'times'"
          [classes]="'icon-button-close'"
          data-test="dialog-close-icon-button"
          (click)="cancelClicked()"
        />
      </div>
    </div>
  </div>

  <!-- Body -->
  <div class="custom-dialog-body" [ngClass]="noBodyPadding ? 'custom-dialog-body-no-padding' : ''" cdkScrollable>
    <ng-content select="[y42DialogBody]"></ng-content>
  </div>

  <!-- Actions -->
  <div *ngIf="!hideFooter" class="custom-dialog-footer">
    <div class="dialog-footer">
      <ng-content select="[y42DialogFooter]"></ng-content>
    </div>

    <div class="dialog-actions">
      <dojo-classic-button
        *ngIf="closeable && cancelLabel !== null"
        data-test="dialog-cancel-button"
        [color]="cancelColor"
        (click)="cancelClicked()"
      >
        {{ cancelLabel || (confirmLabel ? 'Cancel' : 'Close') }}
      </dojo-classic-button>

      <ng-content select="[dialogAdditionalActions]"></ng-content>

      <div *ngIf="confirmLabel" [matTooltip]="confirmDisabled ? confirmDisabledTooltip : ''">
        <dojo-classic-button
          type="submit"
          [color]="confirmColor ?? 'primary'"
          [disabled]="confirmDisabled"
          [pending]="confirmPending"
          id="dialog-confirm-button"
          data-test="dialog-confirm-button"
          class="confirm-button"
          (click)="confirmed()"
        >
          <ng-container *ngIf="state.confirmLabelTemplate; else defaultConfirmLabel">
            <ng-container *ngTemplateOutlet="state.confirmLabelTemplate"></ng-container>
          </ng-container>

          <ng-template #defaultConfirmLabel>
            {{ confirmLabel }}
          </ng-template>

          <y42-keyboard-shortcut *ngIf="!disableCmdEnter" [shortcut]="'CmdEnter'"></y42-keyboard-shortcut>
        </dojo-classic-button>
      </div>
    </div>
  </div>
</div>
