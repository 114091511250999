import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'dojo-topic-code-number',
  imports: [],
  template: `
    <div class="number-wrapper w-10 shrink-0">
      <span
        class="number font-medium-exam inline-flex h-[30px] min-w-[30px] items-center justify-center rounded-[0.5rem] border p-1 text-sm"
      >
        {{ codeNumber() }}
      </span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopicCodeNumberComponent {
  readonly codeNumber = input.required<string>();
  readonly color = input<string>();
}
