import { Injectable } from '@angular/core';
import { AbstractDialogService } from '@examdojo/core/dialog';
import { QuestionPreviewDialogComponent } from './question-preview-dialog.component';
import { QUESTION_PREVIEW_DIALOG_ID } from './question-preview-dialog.model';

@Injectable()
export class QuestionPreviewDialogService extends AbstractDialogService {
  constructor() {
    super();
  }

  readonly component = QuestionPreviewDialogComponent;
  readonly id = QUESTION_PREVIEW_DIALOG_ID;

  protected override readonly options = {
    cssClass: 'modified-modal font-primary-exam',
  };
}
