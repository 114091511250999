<y42-mat-dialog
  [dialogTitle]="data.title"
  [confirmLabel]="data.confirmLabel || 'Confirm'"
  [confirmColor]="data.confirmColor"
  [confirmPending]="state.confirmPending"
  [cancelLabel]="data.cancelLabel"
  [cancelFn]="data.cancelFn || cancel"
  [width]="data.width || 'medium'"
  (confirm)="confirm()"
>
  <ng-container y42DialogBody>
    <div class="flex gap-2 py-2">
      @if (data.severity) {
        <y42-info-box-severity-icon [severity]="data.severity" [size]="16" />
      }

      <div class="flex flex-col gap-2">
        <span
          class="confirm-dialog-message text-title-s dark:font-medium-dark font-medium"
          [innerHTML]="messageSanitized"
        ></span>

        @if (bodySanitized) {
          <span class="text-typography-secondary" [innerHTML]="bodySanitized"></span>
        }
      </div>
    </div>
  </ng-container>

  <ng-container y42DialogFooter>
    @if (data.tertiary) {
      <div [matTooltip]="data.tertiary.disabled ? data.tertiary.disabledTooltip || '' : ''">
        <dojo-classic-button
          [color]="data.tertiary.color"
          [disabled]="data.tertiary.disabled"
          [pending]="state.tertiaryPending"
          id="dialog-tertiary-button"
          (click)="tertiaryClicked()"
        >
          {{ data.tertiary.label }}
        </dojo-classic-button>
      </div>
    }
  </ng-container>
</y42-mat-dialog>
