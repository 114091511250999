import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { mapToVoid } from '@examdojo/rxjs';
import { combineLatest, EMPTY, first, merge, Observable, of, switchMap, tap } from 'rxjs';
import { CategoryHttpService } from './category-http.service';
import { CategoryQuery } from './category.query';
import { CategoryStore } from './category.store';
import { TopicsTree } from './model';
import { SyllabusQuery } from './syllabus';
import { TopicLevel1Service } from './topic-level-1';
import { TopicLevel2Service } from './topic-level-2';
import { TopicLevel3Service } from './topic-level-3';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(
    private readonly store: CategoryStore,
    private readonly query: CategoryQuery,
    private readonly categoryHttpService: CategoryHttpService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly syllabusQuery: SyllabusQuery,
    private readonly topicLevel1Service: TopicLevel1Service,
    private readonly topicLevel2Service: TopicLevel2Service,
    private readonly topicLevel3Service: TopicLevel3Service,
  ) {
    merge(this.fetchTopicsWhenActiveSyllabusChanges()).pipe(takeUntilDestroyed()).subscribe();
  }

  fetchTopicsTree(): Observable<TopicsTree> {
    return this.categoryHttpService.fetchTopicsTree().pipe(
      tap((data) => this.store.updateStore((state) => ({ ...state, topicsTree: data }))),
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.categories' }),
    );
  }

  fetchGradeBoundaries() {
    return this.categoryHttpService.fetchGradeBoundaries().pipe(
      tap((data) => this.store.updateStore((state) => ({ ...state, gradeBoundaries: data ?? undefined }))),
      this.errorHandlerService.setHttpErrorMetadata({ entity: 'examdojo.entity.grade_boundaries' }),
      mapToVoid(),
    );
  }

  private fetchTopicsWhenActiveSyllabusChanges() {
    return this.syllabusQuery.activeIdAndCourseLevel$.pipe(
      switchMap(({ syllabusId, courseLevel }) => {
        if (!syllabusId) {
          this.topicLevel1Service.reset();
          this.topicLevel2Service.reset();
          this.topicLevel3Service.reset();
          return of([]);
        }

        return this.query.select('topicsTree').pipe(
          first(Boolean),
          switchMap((topicsTree) => {
            const syllabusTree = topicsTree.find(({ id }) => id === syllabusId);
            if (!syllabusTree) {
              return of([]);
            }

            const topicsLevel1 = syllabusTree.topics_level_01;
            const topicsLevel1Ids = topicsLevel1.map((topic) => topic.id);

            const topicsLevel2 = topicsLevel1.flatMap((topic) => topic.topics_level_02);
            const topicsLevel2Ids = topicsLevel2.map((topic) => topic.id);

            return combineLatest([
              this.topicLevel1Service.fetchByIds(topicsLevel1Ids),
              this.topicLevel2Service.fetchByIds(topicsLevel2Ids, { syllabusId, courseLevel }),
              this.topicLevel3Service.fetchByTopicLevel2Ids(topicsLevel2Ids),
            ]).pipe(this.errorHandlerService.catchHttpErrors(() => EMPTY));
          }),
        );
      }),
    );
  }
}
