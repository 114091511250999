import { Injectable } from '@angular/core';
import { QueryEntity } from '@examdojo/state';
import { map } from 'rxjs';
import { CategoryStore } from './category-store.service';
import { CategoryState, CategoryStoreModel, CategoryUIModel, CourseCategory, TopicCategory } from './category.model';

/**
 * @deprecated
 */
@Injectable({ providedIn: 'root' })
export class CategoryQueryV1 extends QueryEntity<CategoryState, CategoryStoreModel, CategoryUIModel, 'id'> {
  constructor(protected override store: CategoryStore) {
    super(store);
  }

  readonly subjects$ = this.entities$.pipe(
    map((categories) =>
      categories.filter((category): category is CourseCategory => category.category_level === 'subject'),
    ),
  );

  readonly courses$ = this.entities$.pipe(
    map((categories) =>
      categories.filter((category): category is CourseCategory => category.category_level === 'course'),
    ),
  );

  readonly topics$ = this.entities$.pipe(
    map((categories) =>
      categories.filter((category): category is TopicCategory => category.category_level === 'topic'),
    ),
  );
}
