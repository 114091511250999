import { LocalizedString } from '@examdojo/core/i18n';
import { TableInsertModel, TableRowModel, TableUpdateModel } from '@examdojo/supabase';
import { TopicLevel2UIModel } from '../topic-level-2';

export type TopicLevel3HttpModel = Pick<
  TableRowModel<'topics_level_03', 'categories'>,
  'code' | 'created_at' | 'id' | 'name' | 'sort_order' | 'testable_concepts' | 'topic_level_02_id' | 'updated_at'
>;

export type TopicLevel3StoreModel = Omit<TopicLevel3HttpModel, 'name' | 'testable_concepts'> & {
  name: LocalizedString;
  testable_concepts: LocalizedString;
};

export type TopicLevel3UIModel = TopicLevel3StoreModel & {
  topicLevel2?: TopicLevel2UIModel;
};

export const TOPIC_LEVEL_3_CREATE_ALLOWED_KEYS = [
  'code',
  'name',
  'sort_order',
  'testable_concepts',
  'topic_level_02_id',
] satisfies Array<keyof TableInsertModel<'topics_level_03', 'categories'>>;

export type TopicLevel3CreateModel = Pick<
  TableInsertModel<'topics_level_03', 'categories'>,
  (typeof TOPIC_LEVEL_3_CREATE_ALLOWED_KEYS)[number]
>;

export const TOPIC_LEVEL_3_UPDATE_ALLOWED_KEYS = [...TOPIC_LEVEL_3_CREATE_ALLOWED_KEYS] satisfies Array<
  keyof TableUpdateModel<'topics_level_03', 'categories'>
>;

export type TopicLevel3UpdateModel = Pick<
  TableUpdateModel<'topics_level_03', 'categories'>,
  (typeof TOPIC_LEVEL_3_UPDATE_ALLOWED_KEYS)[number]
>;
