import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChildren,
  DestroyRef,
  input,
  Input,
  signal,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControlStatus, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { markFormAsSubmitted } from '@examdojo/core/form';
import { FormConfirmFn, FormConfirmFnReturnType } from '@examdojo/core/form-submit-button';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { mapToVoid } from '@examdojo/rxjs';
import { assertNonNullable } from '@examdojo/util/assert';
import {
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  first,
  firstValueFrom,
  from,
  isObservable,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';
import { SwiperOptions } from 'swiper/types';
import { SwiperComponent, SwiperSlideDirective } from '../swiper';

export { FormConfirmFn as StepperSubmitFn } from '@examdojo/core/form-submit-button';

@Component({
  selector: 'dojo-swiper-stepper',
  imports: [SwiperComponent, ReactiveFormsModule],
  templateUrl: './swiper-stepper.component.html',
  styleUrl: './swiper-stepper.component.scss',
  host: { class: 'block' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwiperStepperComponent<TFormGroup extends FormGroup, TReturn = unknown> {
  constructor(
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly destroyRef: DestroyRef,
  ) {
    this.disableSwipeNextOnActiveControlStatusChange().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  @Input() swiperOptions?: SwiperOptions = {
    slidesPerView: 1,
    keyboard: {
      enabled: true,
      onlyInViewport: true, // Set to false if you want it to work even when not in viewport
    },
  };

  @Input() goNextDelayMs?: number;

  @Input() paginationTop?: boolean;

  readonly form = input<TFormGroup>(new FormGroup({}) as TFormGroup);
  readonly submitFn = input<FormConfirmFn<TFormGroup, TReturn>>();

  private readonly pending = signal(false);
  readonly pending$ = toObservable(this.pending);

  readonly swiperComponent = viewChild(SwiperComponent);
  readonly swiperInstance = computed(() => this.swiperComponent()?.instance());

  private readonly swiperComponent$ = toObservable(this.swiperComponent).pipe(filter(Boolean));
  readonly swiperInstance$ = toObservable(this.swiperInstance).pipe(filter(Boolean));

  readonly slides = contentChildren(SwiperSlideDirective);

  readonly form$ = toObservable(this.form).pipe(filter(Boolean));

  readonly activeSlideIndex$ = this.swiperComponent$.pipe(
    switchMap((swiperComponent) => swiperComponent.activeSlideIndex$),
  );

  readonly activeSlide$ = combineLatest([toObservable(this.slides), this.activeSlideIndex$]).pipe(
    map(([slides, activeIndex]) => {
      const activeSlide = slides[activeIndex];
      if (!activeSlide) {
        this.errorHandlerService.error(
          `[SwiperStepperComponent.activeSlide$]: No slide found at index ${activeIndex}`,
          {
            context: { slides, activeIndex, activeSlide },
          },
        );
        return undefined;
      }
      return activeSlide;
    }),
  );

  readonly nextSlideChangeActiveIndex$ = this.swiperInstance$.pipe(
    switchMap(
      (swiper) =>
        new Observable<number>((observer) => {
          swiper.on('slideNextTransitionStart', () => {
            observer.next(swiper.activeIndex);
          });
          return () => swiper.off('slideNextTransitionStart');
        }),
    ),
    takeUntilDestroyed(),
  );

  readonly previousSlideChangeActiveIndex$ = this.swiperInstance$.pipe(
    switchMap(
      (swiper) =>
        new Observable<number>((observer) => {
          swiper.on('slidePrevTransitionStart', () => {
            observer.next(swiper.activeIndex);
          });
          return () => swiper.off('slidePrevTransitionStart');
        }),
    ),
    takeUntilDestroyed(),
  );

  readonly activeControl$ = combineLatest([
    this.form$,
    this.activeSlide$.pipe(
      filter(Boolean),
      switchMap((activeSlide) => activeSlide.controlName$),
    ),
  ]).pipe(
    map(([form, controlName]) => {
      if (!controlName) {
        return null;
      }

      const ctl = form.get(controlName);
      if (!ctl) {
        console.warn(`[SwiperStepperComponent.activeControl$]: No control found with name ${controlName}`, {
          controlName,
          form,
        });
        return undefined;
      }
      return ctl;
    }),
  );

  readonly activeControlStatus$: Observable<FormControlStatus | null | undefined> = this.activeControl$.pipe(
    switchMap((control) => {
      if (!control) {
        return of(control);
      }
      return control.statusChanges.pipe(startWith(control.status));
    }),
    distinctUntilChanged(),
  );

  readonly isFirstStep$ = this.activeSlideIndex$.pipe(
    map((index) => index === 0),
    distinctUntilChanged(),
  );

  readonly isLastStep$ = this.activeSlideIndex$.pipe(
    withLatestFrom(this.swiperInstance$),
    map(([, swiperInstance]) => swiperInstance.isEnd),
    distinctUntilChanged(),
  );

  private readonly goNextTrigger$$ = new Subject<{ force: boolean }>();
  readonly goNextTrigger$ = this.goNextTrigger$$.asObservable();

  setActiveStepByIndex(index: number) {
    this.swiperInstance$
      .pipe(
        first(Boolean),
        tap((swiper) => {
          if (!swiper) {
            this.errorHandlerService.error('[SwiperStepperComponent.setActiveStep]: Swiper instance not found');
            return;
          }

          if (index < 0 || index >= swiper.slides.length) {
            this.errorHandlerService.error(
              `[SwiperStepperComponent.setActiveStep]: Invalid index ${index}. Must be between 0 and ${
                swiper.slides.length - 1
              }`,
            );
            return;
          }

          swiper.slideTo(index);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  goNextOrSubmit({ force }: { force: boolean } = { force: false }) {
    const swiperInstance = this.swiperInstance()!;

    if (swiperInstance?.isEnd) {
      return from(this.submitForm({ checkFormValidity: !force }));
    }

    const currentIndex = swiperInstance?.activeIndex;
    const slides = this.slides();
    const nextSlideIndex = slides.findIndex((slide, index) => index > currentIndex && !slide.skip());

    if (nextSlideIndex === -1) {
      return EMPTY;
    }

    return timer(this.goNextDelayMs ?? 0).pipe(
      tap(() => {
        swiperInstance.slideTo(nextSlideIndex);
      }),
      mapToVoid(),
    );
  }

  private disableSwipeNextOnActiveControlStatusChange() {
    return this.activeControlStatus$.pipe(
      filter((status) => status !== undefined),
      tap((status) => {
        const swiperComponent = this.swiperComponent();
        assertNonNullable(swiperComponent, 'swiperComponent');
        swiperComponent.updateOption('allowSlideNext', !status || status === 'VALID');
      }),
    );
  }

  private async submitForm({ checkFormValidity }: { checkFormValidity: boolean } = { checkFormValidity: true }) {
    if (this.pending()) {
      return;
    }

    this.pending.set(true);
    const form = this.form();
    await markFormAsSubmitted(form);

    if (checkFormValidity && !form.valid) {
      this.pending.set(false);
      return;
    }

    try {
      const confirmFn = this.submitFn();
      if (!confirmFn) {
        return;
      }

      const asyncFnOrResult = confirmFn(form);
      let _result: FormConfirmFnReturnType = asyncFnOrResult;

      if (isObservable(asyncFnOrResult)) {
        _result = await firstValueFrom(asyncFnOrResult.pipe(takeUntilDestroyed(this.destroyRef)), {
          defaultValue: undefined,
        });
      } else {
        _result = await asyncFnOrResult;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.pending.set(false);
    }
  }
}
