import { Directive, Input, TemplateRef } from '@angular/core';

export interface FilterableListItemContext<T extends string | object> {
  $implicit: T;
  itemsFilter: string;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ng-template[dojoListItem]',
  standalone: true,
})
export class FilterableListItemDirective<T extends string | object> {
  constructor(public template: TemplateRef<FilterableListItemContext<T>>) {}

  @Input('dojoListItem') listItems!: T[];

  static ngTemplateContextGuard<T extends string | object>(
    dir: FilterableListItemDirective<T>,
    ctx: unknown,
  ): ctx is FilterableListItemContext<T> {
    return true;
  }
}
