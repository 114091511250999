import { Injectable } from '@angular/core';
import { EntityState, EntityStore } from '@examdojo/state';
import { TopicLevel3StoreModel } from './topic-level-3.model';

export type TopicLevel3State = EntityState<TopicLevel3StoreModel, object, number>;

@Injectable({ providedIn: 'root' })
export class TopicLevel3Store extends EntityStore<TopicLevel3State, TopicLevel3StoreModel, 'id', number> {
  constructor() {
    super({
      name: 'topics-level-3',
      idKey: 'id',
    });
  }
}
