import { Injectable } from '@angular/core';
import { ExamdojoAuthService } from '@examdojo/auth';
import { DateTimeService } from '@examdojo/core/date-time';
import { shareOneReplay } from '@examdojo/core/rxjs';
import { isNotNullish } from '@examdojo/core/util/nullish';
import { OnboardingStep, USER_ROLE_ORDER, UserRole, UserStoreModel, UserUIModel } from '@examdojo/models/user';
import { QueryEntity } from '@examdojo/state';
import { distinctUntilChanged, filter, map, Observable } from 'rxjs';
import { UserHttpService } from './user-http.service';
import { UserState, UserStore } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserQuery extends QueryEntity<UserState, UserStoreModel, UserUIModel, 'id'> {
  constructor(
    protected override store: UserStore,
    private readonly dateTimeService: DateTimeService,
    private readonly userHttpService: UserHttpService,
    private readonly authService: ExamdojoAuthService,
  ) {
    super(store);
  }

  readonly profilePic$ = this.active$.pipe(
    map((active) => (active?.profile_pic ? this.getProfilePicturePublicUrl(active.profile_pic) : null)),
    shareOneReplay(),
  );

  readonly currentUserRole$ = this.select((state) => state.props.currentUserRole);
  readonly oAuthProfilePic$: Observable<string | null> = this.authService.oAuthProfilePic$;

  readonly daysUntilExam$ = this.active$.pipe(
    map((user) => user?.final_exam_date),
    map((rawExamDate) => {
      if (!rawExamDate) {
        return null;
      }

      const examDate = this.dateTimeService.parseDate(rawExamDate);
      if (!(examDate instanceof Date) || isNaN(examDate.getTime())) {
        return null;
      }

      return this.dateTimeService.diff(new Date(), examDate, 'days');
    }),
  );

  readonly isUserOnboarded$: Observable<boolean> = this.active$.pipe(
    filter(Boolean),
    map((user) => this.isUserOnboarded(user)),
    distinctUntilChanged(),
  );

  readonly currentOnboardingStep$: Observable<OnboardingStep | null> = this.active$.pipe(
    filter(Boolean),
    map((user) => user.current_onboarding_step),
    distinctUntilChanged(),
  );

  readonly activeCourseLevel$ = this.active$.pipe(
    map((user) => user?.course_level),
    filter(Boolean),
    distinctUntilChanged(),
  );

  override readonly toUIModelFn = (entity: UserStoreModel): UserUIModel => {
    return {
      ...entity,
      fullName: `${entity.first_name} ${entity.last_name}`,
    };
  };

  isUserOnboarded(user: UserUIModel): boolean {
    return !!(user.school_name && user.final_exam_date && user.syllabus_id && user.course_level && user.origin);
  }

  getProfilePicturePublicUrl(profilePic: string): string {
    return this.userHttpService.getProfilePicturePublicUrl(profilePic);
  }

  selectHasRole(role: UserRole): Observable<boolean> {
    return this.currentUserRole$.pipe(
      filter(isNotNullish),
      map((currentUserRole) => currentUserRole === role),
    );
  }

  selectHasRoleOrHigher(role: UserRole): Observable<boolean> {
    const index = USER_ROLE_ORDER.indexOf(role);

    return this.currentUserRole$.pipe(
      filter(isNotNullish),
      map((currentUserRole) => {
        const currentUserRoleIndex = USER_ROLE_ORDER.indexOf(currentUserRole);
        return currentUserRoleIndex >= index;
      }),
    );
  }

  hasRoleOrHigher(role: UserRole): boolean {
    const index = USER_ROLE_ORDER.indexOf(role);
    const currentUserRole = this.getValue().props.currentUserRole;

    if (!currentUserRole) {
      return false;
    }

    const currentUserRoleIndex = USER_ROLE_ORDER.indexOf(currentUserRole);
    return currentUserRoleIndex >= index;
  }
}
