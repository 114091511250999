import { DestroyRef, Directive, EventEmitter, HostListener, input, Output } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormGroup } from '@angular/forms';
import { connectState } from '@examdojo/angular/util';
import { BaseButton } from '@examdojo/core/button';
import { isNullish } from '@examdojo/util/nullish';
import { combineLatest, distinctUntilChanged, filter, map, startWith, tap } from 'rxjs';
import { SwiperStepperComponent } from './swiper-stepper.component';

@Directive({
  selector: '[swiperStepperPrevious]',
  standalone: true,
  host: {
    class: '`transition-all transition-discrete',
    '[class.hidden]': 'state.shouldHide',
  },
})
export class SwiperStepperPreviousDirective {
  constructor(
    private readonly button: BaseButton,
    private readonly swiperStepperComponent: SwiperStepperComponent<FormGroup>,
    private readonly destroyRef: DestroyRef,
  ) {
    this.disableOnFirstSlide().pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  readonly enabled = input(true, {
    alias: 'swiperStepperPrevious',
    transform: (value?: boolean | ''): boolean => {
      if (isNullish(value) || value === '') {
        return true;
      }
      return value;
    },
  });

  readonly hideOnFirstStep = input(false);

  readonly state = connectState({
    shouldHide: combineLatest([
      toObservable(this.enabled),
      toObservable(this.hideOnFirstStep),
      this.swiperStepperComponent.isFirstStep$,
    ]).pipe(
      map(([enabled, hideOnFirstStep, isFirstStep]) => enabled && hideOnFirstStep && isFirstStep),
      startWith(this.hideOnFirstStep),
      distinctUntilChanged(),
    ),
  });

  @Output() triggered = new EventEmitter();

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.enabled()) {
      return;
    }

    const swiperInstance = this.swiperStepperComponent.swiperInstance()!;
    const currentIndex = swiperInstance.activeIndex;
    const slides = this.swiperStepperComponent.slides();
    const previousSlideIndex = slides.slice(0, currentIndex).findLastIndex((slide) => !slide.skip());

    if (previousSlideIndex === -1) {
      return;
    }

    swiperInstance.slideTo(previousSlideIndex);
    this.triggered.emit();
  }

  private disableOnFirstSlide() {
    return this.swiperStepperComponent.activeSlideIndex$.pipe(
      filter(() => this.enabled()),
      distinctUntilChanged(),
      tap((slideIndex) => {
        this.button.disabled.set(slideIndex === 0);
      }),
    );
  }
}
