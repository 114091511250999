import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, map, of, switchMap, withLatestFrom } from 'rxjs';
import { QuestionQuery } from '../../question-v2.query';
import { QuestionService } from '../../question-v2.service';
import { CmsQuestionQuery } from '../cms-question.query';

@Injectable()
export class SyncQuestionStores {
  constructor(
    private readonly cmsQuestionQuery: CmsQuestionQuery,
    private readonly questionService: QuestionService,
    private readonly questionQuery: QuestionQuery,
  ) {
    this.updateQuestionAttemptStoreWhenQuestionStoreChanges().pipe(takeUntilDestroyed()).subscribe();
  }

  // TODO: After refactoring of QuestionAttempt and Question domains
  // it will not be necessary as the Question domain will be the source of truth.
  private updateQuestionAttemptStoreWhenQuestionStoreChanges() {
    // We should be in the context of the same question.
    return this.cmsQuestionQuery.questionUpdated$.pipe(
      withLatestFrom(this.questionQuery.question$.pipe(map((question) => question?.id))),
      switchMap(([, questionId]) => {
        if (!questionId) {
          return of(null);
        }

        return combineLatest([
          this.questionService.fetchQuestion(questionId),
          this.questionService.fetchQuestionItems(questionId),
        ]);
      }),
    );
  }
}
