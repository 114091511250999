import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, Subscription, tap } from 'rxjs';

/**
 * This directive provides the arrow navigation for a focusable element.
 *
 *  @example
 * ```html
 *  <div dojoArrowNavigationContainer>
 *    <button dojoArrowNavigable>Button 1</button>
 *    <button dojoArrowNavigable>Button 2</button>
 *    <p>Some other content</p>
 *    <button dojoArrowNavigable>Button 3</button>
 *  </div>
 * ```
 */
@Directive({
  standalone: true,
  selector: '[dojoArrowNavigable]',
})
export class ArrowNavigableDirective {
  @Output() y42ArrowDown = new EventEmitter<void>();
  @Output() y42ArrowUp = new EventEmitter<void>();
  @Input() y42ArrowNavigationData?: unknown;

  @HostListener('keydown.arrowdown', ['$event'])
  onArrowDown(event: KeyboardEvent) {
    // prevents unnecessary content scrolling.
    event.preventDefault();
    this.y42ArrowDown.next();
  }

  @HostListener('keydown.arrowup', ['$event'])
  onArrowUp(event: KeyboardEvent) {
    // prevents unnecessary content scrolling.
    event.preventDefault();
    this.y42ArrowUp.next();
  }
}

/**
 * This directive wraps the related arrow navigable components and provides the API
 * that exposes event emitters for focused, `y42FocusedBefore` and `y42FocusedAfter` events.
 *
 * In the following example the `y42FocusedBefore` will be emitted when the focus is on the first button and the user
 * presses the up arrow key. The `y42FocusedAfter` will be emitted when the focus is on the last button and the user
 * presses the down arrow key.
 *
 *  @example
 * ```html
 *  <div dojoArrowNavigationContainer>
 *    <button dojoArrowNavigable>Button 1</button>
 *    <button dojoArrowNavigable>Button 2</button>
 *    <p>Some other content</p>
 *    <button dojoArrowNavigable>Button 3</button>
 *  </div>
 * ```
 */
@UntilDestroy()
@Directive({
  standalone: true,
  selector: '[dojoArrowNavigationContainer]',
})
export class ArrowNavigationContainerDirective implements AfterViewInit {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  @Output() y42FocusedBefore = new EventEmitter<void>();
  @Output() y42FocusedAfter = new EventEmitter<void>();
  @Output() y42ItemFocused = new EventEmitter<{ data: unknown }>();

  @ContentChildren(ArrowNavigableDirective, { descendants: true }) navigableItems?: QueryList<ArrowNavigableDirective>;
  @ContentChildren(ArrowNavigableDirective, { read: ElementRef, descendants: true }) itemRefs?: QueryList<
    ElementRef<HTMLButtonElement>
  >;

  private oldSubscriptions?: Subscription[];
  private lastActiveElement?: HTMLElement;

  focusFirst() {
    this.itemRefs?.first?.nativeElement.focus();
  }

  focus(dir?: 'next' | 'prev') {
    const buttonRefs = this.itemRefs instanceof Array ? this.itemRefs : this.itemRefs ? this.itemRefs.toArray() : [];
    let index = buttonRefs.findIndex((buttonRef) => buttonRef.nativeElement === this.document.activeElement);

    if (!dir) {
      if (this.lastActiveElement && buttonRefs.some((ref) => ref.nativeElement === this.lastActiveElement)) {
        this.lastActiveElement?.focus();
      } else {
        this.focusFirst();
      }

      return;
    }

    if (index === buttonRefs.length - 1 && dir === 'next') {
      this.y42FocusedAfter.next();
      return;
    }

    // When there is no active element and we want to focus the previous element,
    // we need to start from the end.
    if (dir === 'prev' && index === -1) {
      index = buttonRefs.length;
    }

    if (index === 0 && dir === 'prev') {
      this.y42FocusedBefore.next();
      return;
    }

    const newIndex = index + (dir === 'next' ? 1 : -1);
    this.lastActiveElement = buttonRefs[newIndex]?.nativeElement;
    const navigableItem = this.navigableItems?.toArray()[newIndex];

    if (this.lastActiveElement) {
      this.lastActiveElement.focus();

      this.y42ItemFocused.next({ data: navigableItem?.y42ArrowNavigationData });
    }
  }

  ngAfterViewInit() {
    this.subscribeToChildrenEvents();

    this.navigableItems!.changes.pipe(
      tap(() => {
        this.subscribeToChildrenEvents();
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  subscribeToChildrenEvents() {
    this.oldSubscriptions?.forEach((sub) => sub.unsubscribe());

    this.oldSubscriptions = this.navigableItems!.map((item) =>
      merge(item.y42ArrowDown.pipe(tap(() => this.focus('next'))), item.y42ArrowUp.pipe(tap(() => this.focus('prev'))))
        .pipe(untilDestroyed(this))
        .subscribe(),
    );
  }
}
