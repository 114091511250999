export type Json = string | number | boolean | null | { [key: string]: Json | undefined } | Json[];

export type Database = {
  categories: {
    Tables: {
      grade_boundaries: {
        Row: {
          course_level: Database['categories']['Enums']['course_level'];
          created_at: string | null;
          grade: number;
          lower_bound_percentage: number;
          syllabus_id: number;
          updated_at: string | null;
        };
        Insert: {
          course_level: Database['categories']['Enums']['course_level'];
          created_at?: string | null;
          grade: number;
          lower_bound_percentage: number;
          syllabus_id: number;
          updated_at?: string | null;
        };
        Update: {
          course_level?: Database['categories']['Enums']['course_level'];
          created_at?: string | null;
          grade?: number;
          lower_bound_percentage?: number;
          syllabus_id?: number;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'grade_boundaries_syllabus_id_fkey';
            columns: ['syllabus_id'];
            isOneToOne: false;
            referencedRelation: 'syllabuses';
            referencedColumns: ['id'];
          },
        ];
      };
      pace_boundaries: {
        Row: {
          bucket_comment: Json | null;
          bucket_name: Json | null;
          bucket_num: number;
          course_level: Database['categories']['Enums']['course_level'];
          created_at: string | null;
          lower_bound_seconds: number;
          pace_boundary_type: Database['categories']['Enums']['pace_boundary_types'];
          updated_at: string | null;
        };
        Insert: {
          bucket_comment?: Json | null;
          bucket_name?: Json | null;
          bucket_num: number;
          course_level: Database['categories']['Enums']['course_level'];
          created_at?: string | null;
          lower_bound_seconds: number;
          pace_boundary_type: Database['categories']['Enums']['pace_boundary_types'];
          updated_at?: string | null;
        };
        Update: {
          bucket_comment?: Json | null;
          bucket_name?: Json | null;
          bucket_num?: number;
          course_level?: Database['categories']['Enums']['course_level'];
          created_at?: string | null;
          lower_bound_seconds?: number;
          pace_boundary_type?: Database['categories']['Enums']['pace_boundary_types'];
          updated_at?: string | null;
        };
        Relationships: [];
      };
      syllabuses: {
        Row: {
          cdc_name: Json | null;
          cdc_short_name: Json | null;
          created_at: string | null;
          description: Json;
          formular_booklet: Json;
          grade_boundaries_explanation: Json;
          id: number;
          markscheme_explanation: Json;
          name: Json;
          short_name: Json | null;
          updated_at: string | null;
        };
        Insert: {
          cdc_name?: Json | null;
          cdc_short_name?: Json | null;
          created_at?: string | null;
          description: Json;
          formular_booklet: Json;
          grade_boundaries_explanation: Json;
          id?: number;
          markscheme_explanation: Json;
          name: Json;
          short_name?: Json | null;
          updated_at?: string | null;
        };
        Update: {
          cdc_name?: Json | null;
          cdc_short_name?: Json | null;
          created_at?: string | null;
          description?: Json;
          formular_booklet?: Json;
          grade_boundaries_explanation?: Json;
          id?: number;
          markscheme_explanation?: Json;
          name?: Json;
          short_name?: Json | null;
          updated_at?: string | null;
        };
        Relationships: [];
      };
      topic_groups: {
        Row: {
          created_at: string | null;
          description: Json;
          id: number;
          latex_sample: string;
          name: Json;
          sort_order: number;
          topic_level_01_id: number;
          updated_at: string | null;
        };
        Insert: {
          created_at?: string | null;
          description: Json;
          id?: number;
          latex_sample: string;
          name: Json;
          sort_order: number;
          topic_level_01_id: number;
          updated_at?: string | null;
        };
        Update: {
          created_at?: string | null;
          description?: Json;
          id?: number;
          latex_sample?: string;
          name?: Json;
          sort_order?: number;
          topic_level_01_id?: number;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'topic_groups_topic_level_01_id_fkey';
            columns: ['topic_level_01_id'];
            isOneToOne: false;
            referencedRelation: 'topics_level_01';
            referencedColumns: ['id'];
          },
        ];
      };
      topics_level_01: {
        Row: {
          active: boolean | null;
          cdc_name: Json | null;
          color_name: string | null;
          created_at: string | null;
          description: Json | null;
          id: number;
          name: Json;
          sort_order: number;
          syllabus_id: number;
          updated_at: string | null;
        };
        Insert: {
          active?: boolean | null;
          cdc_name?: Json | null;
          color_name?: string | null;
          created_at?: string | null;
          description?: Json | null;
          id?: number;
          name: Json;
          sort_order: number;
          syllabus_id: number;
          updated_at?: string | null;
        };
        Update: {
          active?: boolean | null;
          cdc_name?: Json | null;
          color_name?: string | null;
          created_at?: string | null;
          description?: Json | null;
          id?: number;
          name?: Json;
          sort_order?: number;
          syllabus_id?: number;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'topics_level_01_syllabus_id_fkey';
            columns: ['syllabus_id'];
            isOneToOne: false;
            referencedRelation: 'syllabuses';
            referencedColumns: ['id'];
          },
        ];
      };
      topics_level_02: {
        Row: {
          active: boolean | null;
          cdc_name: Json | null;
          cdc_short_name: Json | null;
          code: string;
          course_level: Database['categories']['Enums']['course_level'];
          created_at: string | null;
          description: Json;
          example: Json;
          icon: string | null;
          id: number;
          name: Json;
          short_name: Json | null;
          sort_order: number;
          topic_group_id: number;
          topic_level_01_id: number;
          updated_at: string | null;
        };
        Insert: {
          active?: boolean | null;
          cdc_name?: Json | null;
          cdc_short_name?: Json | null;
          code: string;
          course_level: Database['categories']['Enums']['course_level'];
          created_at?: string | null;
          description: Json;
          example: Json;
          icon?: string | null;
          id?: number;
          name: Json;
          short_name?: Json | null;
          sort_order: number;
          topic_group_id: number;
          topic_level_01_id: number;
          updated_at?: string | null;
        };
        Update: {
          active?: boolean | null;
          cdc_name?: Json | null;
          cdc_short_name?: Json | null;
          code?: string;
          course_level?: Database['categories']['Enums']['course_level'];
          created_at?: string | null;
          description?: Json;
          example?: Json;
          icon?: string | null;
          id?: number;
          name?: Json;
          short_name?: Json | null;
          sort_order?: number;
          topic_group_id?: number;
          topic_level_01_id?: number;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'topics_level_02_topic_group_id_fkey';
            columns: ['topic_group_id'];
            isOneToOne: false;
            referencedRelation: 'topic_groups';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'topics_level_02_topic_level_01_id_fkey';
            columns: ['topic_level_01_id'];
            isOneToOne: false;
            referencedRelation: 'topics_level_01';
            referencedColumns: ['id'];
          },
        ];
      };
      topics_level_03: {
        Row: {
          cdc_name: Json | null;
          code: string;
          created_at: string | null;
          id: number;
          name: Json;
          sort_order: number;
          testable_concepts: Json;
          topic_level_02_id: number | null;
          updated_at: string | null;
        };
        Insert: {
          cdc_name?: Json | null;
          code: string;
          created_at?: string | null;
          id?: number;
          name: Json;
          sort_order: number;
          testable_concepts: Json;
          topic_level_02_id?: number | null;
          updated_at?: string | null;
        };
        Update: {
          cdc_name?: Json | null;
          code?: string;
          created_at?: string | null;
          id?: number;
          name?: Json;
          sort_order?: number;
          testable_concepts?: Json;
          topic_level_02_id?: number | null;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'topics_level_03_topic_level_02_id_fkey';
            columns: ['topic_level_02_id'];
            isOneToOne: false;
            referencedRelation: 'topics_level_02';
            referencedColumns: ['id'];
          },
        ];
      };
    };
    Views: {
      topics_tree: {
        Row: {
          jsonb_agg: Json | null;
        };
        Relationships: [];
      };
    };
    Functions: {
      [_ in never]: never;
    };
    Enums: {
      course_level: 'SL' | 'HL';
      pace_boundary_types: 'practice';
    };
    CompositeTypes: {
      [_ in never]: never;
    };
  };
  learn_hub: {
    Tables: {
      page_versions: {
        Row: {
          content: string | null;
          created_at: string | null;
          id: number;
          language_code: Database['learn_hub']['Enums']['language_code'];
          page_status: Database['learn_hub']['Enums']['page_status'];
          page_type: Database['learn_hub']['Enums']['page_type'];
          topic_level_02_id: number | null;
          updated_at: string | null;
        };
        Insert: {
          content?: string | null;
          created_at?: string | null;
          id?: number;
          language_code?: Database['learn_hub']['Enums']['language_code'];
          page_status?: Database['learn_hub']['Enums']['page_status'];
          page_type: Database['learn_hub']['Enums']['page_type'];
          topic_level_02_id?: number | null;
          updated_at?: string | null;
        };
        Update: {
          content?: string | null;
          created_at?: string | null;
          id?: number;
          language_code?: Database['learn_hub']['Enums']['language_code'];
          page_status?: Database['learn_hub']['Enums']['page_status'];
          page_type?: Database['learn_hub']['Enums']['page_type'];
          topic_level_02_id?: number | null;
          updated_at?: string | null;
        };
        Relationships: [];
      };
    };
    Views: {
      [_ in never]: never;
    };
    Functions: {
      create_draft: {
        Args: {
          args: Database['learn_hub']['CompositeTypes']['create_draft_args'];
        };
        Returns: undefined;
      };
      publish_version: {
        Args: {
          args: Database['learn_hub']['CompositeTypes']['publish_args'];
        };
        Returns: undefined;
      };
    };
    Enums: {
      language_code: 'en';
      page_status: 'draft' | 'published' | 'archived';
      page_type: 'concept' | 'cheat_sheet' | 'insights' | 'past_papers';
    };
    CompositeTypes: {
      create_draft_args: {
        topic_level_02_id: number | null;
        page_type: Database['learn_hub']['Enums']['page_type'] | null;
        language_code: Database['learn_hub']['Enums']['language_code'] | null;
        content: string | null;
      };
      publish_args: {
        topic_level_02_id: number | null;
        page_type: Database['learn_hub']['Enums']['page_type'] | null;
        language_code: Database['learn_hub']['Enums']['language_code'] | null;
        page_version_id: number | null;
      };
    };
  };
  learning: {
    Tables: {
      activities: {
        Row: {
          challenge_activity_id: string | null;
          created_at: string;
          practice_activity_id: string | null;
          updated_at: string;
          user_id: string;
        };
        Insert: {
          challenge_activity_id?: string | null;
          created_at?: string;
          practice_activity_id?: string | null;
          updated_at?: string;
          user_id: string;
        };
        Update: {
          challenge_activity_id?: string | null;
          created_at?: string;
          practice_activity_id?: string | null;
          updated_at?: string;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'activities_challenge_activity_id_fkey';
            columns: ['challenge_activity_id'];
            isOneToOne: false;
            referencedRelation: 'challenge_activities';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'activities_practice_activity_id_fkey';
            columns: ['practice_activity_id'];
            isOneToOne: false;
            referencedRelation: 'practice_activities';
            referencedColumns: ['id'];
          },
        ];
      };
      assessments: {
        Row: {
          active: boolean | null;
          assessment_type: 'class_test' | 'mock_exam';
          course_level: Database['categories']['Enums']['course_level'];
          created_at: string;
          grade_percentage: number | null;
          id: number;
          syllabus_id: number;
          target_date: string;
          title: string;
          user_id: string;
        };
        Insert: {
          active?: boolean | null;
          assessment_type: 'class_test' | 'mock_exam';
          course_level: Database['categories']['Enums']['course_level'];
          created_at?: string;
          grade_percentage?: number | null;
          id?: number;
          syllabus_id: number;
          target_date: string;
          title: string;
          user_id: string;
        };
        Update: {
          active?: boolean | null;
          assessment_type?: 'class_test' | 'mock_exam';
          course_level?: Database['categories']['Enums']['course_level'];
          created_at?: string;
          grade_percentage?: number | null;
          id?: number;
          syllabus_id?: number;
          target_date?: string;
          title?: string;
          user_id?: string;
        };
        Relationships: [];
      };
      assessments_topics_level_02: {
        Row: {
          assessment_id: number;
          topic_level_02_id: number;
        };
        Insert: {
          assessment_id: number;
          topic_level_02_id: number;
        };
        Update: {
          assessment_id?: number;
          topic_level_02_id?: number;
        };
        Relationships: [
          {
            foreignKeyName: 'assessments_topics_level_02_assessment_id_fkey';
            columns: ['assessment_id'];
            isOneToOne: false;
            referencedRelation: 'assessments';
            referencedColumns: ['id'];
          },
        ];
      };
      assignment_section_questions: {
        Row: {
          assignment_id: string;
          assignment_section_id: string;
          created_at: string;
          position: number;
          question_id: number;
          sys_period: unknown;
          updated_at: string;
        };
        Insert: {
          assignment_id: string;
          assignment_section_id: string;
          created_at?: string;
          position: number;
          question_id: number;
          sys_period?: unknown;
          updated_at?: string;
        };
        Update: {
          assignment_id?: string;
          assignment_section_id?: string;
          created_at?: string;
          position?: number;
          question_id?: number;
          sys_period?: unknown;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'assignment_section_questions_assignment_id_assignment_sect_fkey';
            columns: ['assignment_id', 'assignment_section_id'];
            isOneToOne: false;
            referencedRelation: 'assignment_sections';
            referencedColumns: ['assignment_id', 'id'];
          },
        ];
      };
      assignment_section_questions_history: {
        Row: {
          assignment_id: string;
          assignment_section_id: string;
          created_at: string;
          position: number;
          question_id: number;
          sys_period: unknown;
          updated_at: string;
        };
        Insert: {
          assignment_id: string;
          assignment_section_id: string;
          created_at: string;
          position: number;
          question_id: number;
          sys_period: unknown;
          updated_at: string;
        };
        Update: {
          assignment_id?: string;
          assignment_section_id?: string;
          created_at?: string;
          position?: number;
          question_id?: number;
          sys_period?: unknown;
          updated_at?: string;
        };
        Relationships: [];
      };
      assignment_sections: {
        Row: {
          assignment_id: string;
          break_in_minutes: number | null;
          course_level: Database['categories']['Enums']['course_level'];
          created_at: string;
          id: string;
          name: Json;
          position: number;
          sys_period: unknown;
          time_limit_in_minutes: number | null;
          updated_at: string;
        };
        Insert: {
          assignment_id: string;
          break_in_minutes?: number | null;
          course_level: Database['categories']['Enums']['course_level'];
          created_at?: string;
          id?: string;
          name: Json;
          position: number;
          sys_period?: unknown;
          time_limit_in_minutes?: number | null;
          updated_at?: string;
        };
        Update: {
          assignment_id?: string;
          break_in_minutes?: number | null;
          course_level?: Database['categories']['Enums']['course_level'];
          created_at?: string;
          id?: string;
          name?: Json;
          position?: number;
          sys_period?: unknown;
          time_limit_in_minutes?: number | null;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'assignment_sections_assignment_id_fkey';
            columns: ['assignment_id'];
            isOneToOne: false;
            referencedRelation: 'assignments';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'assignment_sections_assignment_id_fkey';
            columns: ['assignment_id'];
            isOneToOne: false;
            referencedRelation: 'assignments_available';
            referencedColumns: ['id'];
          },
        ];
      };
      assignment_sections_history: {
        Row: {
          assignment_id: string;
          break_in_minutes: number | null;
          course_level: Database['categories']['Enums']['course_level'];
          created_at: string;
          id: string;
          name: Json;
          position: number;
          sys_period: unknown;
          time_limit_in_minutes: number | null;
          updated_at: string;
        };
        Insert: {
          assignment_id: string;
          break_in_minutes?: number | null;
          course_level: Database['categories']['Enums']['course_level'];
          created_at: string;
          id: string;
          name: Json;
          position: number;
          sys_period: unknown;
          time_limit_in_minutes?: number | null;
          updated_at: string;
        };
        Update: {
          assignment_id?: string;
          break_in_minutes?: number | null;
          course_level?: Database['categories']['Enums']['course_level'];
          created_at?: string;
          id?: string;
          name?: Json;
          position?: number;
          sys_period?: unknown;
          time_limit_in_minutes?: number | null;
          updated_at?: string;
        };
        Relationships: [];
      };
      assignments: {
        Row: {
          created_at: string;
          id: string;
          is_original: boolean;
          name: Json;
          syllabus_id: number;
          sys_period: unknown;
          updated_at: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          is_original: boolean;
          name: Json;
          syllabus_id: number;
          sys_period?: unknown;
          updated_at?: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          is_original?: boolean;
          name?: Json;
          syllabus_id?: number;
          sys_period?: unknown;
          updated_at?: string;
        };
        Relationships: [];
      };
      assignments_history: {
        Row: {
          created_at: string;
          id: string;
          is_original: boolean;
          name: Json;
          syllabus_id: number;
          sys_period: unknown;
          updated_at: string;
        };
        Insert: {
          created_at: string;
          id: string;
          is_original: boolean;
          name: Json;
          syllabus_id: number;
          sys_period: unknown;
          updated_at: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          is_original?: boolean;
          name?: Json;
          syllabus_id?: number;
          sys_period?: unknown;
          updated_at?: string;
        };
        Relationships: [];
      };
      challenge_activities: {
        Row: {
          assignment_section_id: string;
          created_at: string;
          id: string;
          status: Database['learning']['Enums']['challenge_activity_status'];
          updated_at: string;
          user_id: string;
        };
        Insert: {
          assignment_section_id: string;
          created_at?: string;
          id?: string;
          status?: Database['learning']['Enums']['challenge_activity_status'];
          updated_at?: string;
          user_id: string;
        };
        Update: {
          assignment_section_id?: string;
          created_at?: string;
          id?: string;
          status?: Database['learning']['Enums']['challenge_activity_status'];
          updated_at?: string;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'challenge_activities_assignment_section_id_fkey';
            columns: ['assignment_section_id'];
            isOneToOne: false;
            referencedRelation: 'assignment_sections';
            referencedColumns: ['id'];
          },
        ];
      };
      challenge_activity_statuses: {
        Row: {
          challenge_activity_id: string;
          created_at: string;
          id: string;
          status: Database['learning']['Enums']['challenge_activity_status'];
        };
        Insert: {
          challenge_activity_id: string;
          created_at?: string;
          id?: string;
          status: Database['learning']['Enums']['challenge_activity_status'];
        };
        Update: {
          challenge_activity_id?: string;
          created_at?: string;
          id?: string;
          status?: Database['learning']['Enums']['challenge_activity_status'];
        };
        Relationships: [
          {
            foreignKeyName: 'challenge_activity_statuses_challenge_activity_id_fkey';
            columns: ['challenge_activity_id'];
            isOneToOne: false;
            referencedRelation: 'challenge_activities';
            referencedColumns: ['id'];
          },
        ];
      };
      practice_activities: {
        Row: {
          created_at: string;
          id: string;
          question_attempt_id: string | null;
          status: Database['learning']['Enums']['practice_activity_status'];
          updated_at: string;
          user_id: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          question_attempt_id?: string | null;
          status?: Database['learning']['Enums']['practice_activity_status'];
          updated_at?: string;
          user_id: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          question_attempt_id?: string | null;
          status?: Database['learning']['Enums']['practice_activity_status'];
          updated_at?: string;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'practice_activities_question_attempt_id_fkey';
            columns: ['question_attempt_id'];
            isOneToOne: false;
            referencedRelation: 'question_attempts';
            referencedColumns: ['id'];
          },
        ];
      };
      practice_activity_candidates: {
        Row: {
          created_at: string;
          id: string;
          metadata: Json | null;
          practice_activity_id: string;
          question_id: number;
          updated_at: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          metadata?: Json | null;
          practice_activity_id: string;
          question_id: number;
          updated_at?: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          metadata?: Json | null;
          practice_activity_id?: string;
          question_id?: number;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'practice_activity_candidates_practice_activity_id_fkey';
            columns: ['practice_activity_id'];
            isOneToOne: false;
            referencedRelation: 'practice_activities';
            referencedColumns: ['id'];
          },
        ];
      };
      practice_activity_statuses: {
        Row: {
          created_at: string;
          id: string;
          practice_activity_id: string;
          status: Database['learning']['Enums']['practice_activity_status'];
        };
        Insert: {
          created_at?: string;
          id?: string;
          practice_activity_id: string;
          status: Database['learning']['Enums']['practice_activity_status'];
        };
        Update: {
          created_at?: string;
          id?: string;
          practice_activity_id?: string;
          status?: Database['learning']['Enums']['practice_activity_status'];
        };
        Relationships: [
          {
            foreignKeyName: 'practice_activity_statuses_practice_activity_id_fkey';
            columns: ['practice_activity_id'];
            isOneToOne: false;
            referencedRelation: 'practice_activities';
            referencedColumns: ['id'];
          },
        ];
      };
      question_attempt_chats: {
        Row: {
          created_at: string;
          post_grading_llm_chat_id: number | null;
          pre_grading_llm_chat_id: number | null;
          question_attempt_id: string;
          updated_at: string;
        };
        Insert: {
          created_at?: string;
          post_grading_llm_chat_id?: number | null;
          pre_grading_llm_chat_id?: number | null;
          question_attempt_id: string;
          updated_at?: string;
        };
        Update: {
          created_at?: string;
          post_grading_llm_chat_id?: number | null;
          pre_grading_llm_chat_id?: number | null;
          question_attempt_id?: string;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'question_attempt_chats_question_attempt_id_fkey';
            columns: ['question_attempt_id'];
            isOneToOne: true;
            referencedRelation: 'question_attempts';
            referencedColumns: ['id'];
          },
        ];
      };
      question_attempt_grading_feedbacks: {
        Row: {
          author: string;
          created_at: string;
          id: string;
          score: number;
          text_feedback: string;
          updated_at: string;
        };
        Insert: {
          author: string;
          created_at?: string;
          id: string;
          score: number;
          text_feedback: string;
          updated_at?: string;
        };
        Update: {
          author?: string;
          created_at?: string;
          id?: string;
          score?: number;
          text_feedback?: string;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'question_attempt_grading_feedbacks_id_fkey';
            columns: ['id'];
            isOneToOne: true;
            referencedRelation: 'question_attempt_gradings';
            referencedColumns: ['id'];
          },
        ];
      };
      question_attempt_gradings: {
        Row: {
          created_at: string;
          grading_result: Json;
          id: string;
          question_attempt_id: string;
          stems: number[] | null;
          updated_at: string;
        };
        Insert: {
          created_at?: string;
          grading_result: Json;
          id?: string;
          question_attempt_id: string;
          stems?: number[] | null;
          updated_at?: string;
        };
        Update: {
          created_at?: string;
          grading_result?: Json;
          id?: string;
          question_attempt_id?: string;
          stems?: number[] | null;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'question_attempt_gradings_question_attempt_id_fkey';
            columns: ['question_attempt_id'];
            isOneToOne: false;
            referencedRelation: 'question_attempts';
            referencedColumns: ['id'];
          },
        ];
      };
      question_attempt_response_images: {
        Row: {
          bucket_name: string | null;
          created_at: string;
          id: string;
          image_name: string | null;
          order: number;
          question_attempt_grading_id: string | null;
          question_attempt_id: string;
          updated_at: string;
        };
        Insert: {
          bucket_name?: string | null;
          created_at?: string;
          id?: string;
          image_name?: string | null;
          order?: number;
          question_attempt_grading_id?: string | null;
          question_attempt_id: string;
          updated_at?: string;
        };
        Update: {
          bucket_name?: string | null;
          created_at?: string;
          id?: string;
          image_name?: string | null;
          order?: number;
          question_attempt_grading_id?: string | null;
          question_attempt_id?: string;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'question_attempt_response_imag_question_attempt_grading_id_fkey';
            columns: ['question_attempt_grading_id'];
            isOneToOne: false;
            referencedRelation: 'question_attempt_gradings';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'question_attempt_response_images_question_attempt_id_fkey';
            columns: ['question_attempt_id'];
            isOneToOne: false;
            referencedRelation: 'question_attempts';
            referencedColumns: ['id'];
          },
        ];
      };
      question_attempt_statuses: {
        Row: {
          created_at: string;
          id: string;
          question_attempt_id: string;
          status: Database['learning']['Enums']['question_attempt_status'];
        };
        Insert: {
          created_at?: string;
          id?: string;
          question_attempt_id: string;
          status: Database['learning']['Enums']['question_attempt_status'];
        };
        Update: {
          created_at?: string;
          id?: string;
          question_attempt_id?: string;
          status?: Database['learning']['Enums']['question_attempt_status'];
        };
        Relationships: [
          {
            foreignKeyName: 'question_attempt_statuses_question_attempt_id_fkey';
            columns: ['question_attempt_id'];
            isOneToOne: false;
            referencedRelation: 'question_attempts';
            referencedColumns: ['id'];
          },
        ];
      };
      question_attempts: {
        Row: {
          created_at: string;
          grading_result: Json | null;
          id: string;
          question_id: number;
          status: Database['learning']['Enums']['question_attempt_status'];
          updated_at: string;
          user_id: string;
        };
        Insert: {
          created_at?: string;
          grading_result?: Json | null;
          id?: string;
          question_id: number;
          status?: Database['learning']['Enums']['question_attempt_status'];
          updated_at?: string;
          user_id: string;
        };
        Update: {
          created_at?: string;
          grading_result?: Json | null;
          id?: string;
          question_id?: number;
          status?: Database['learning']['Enums']['question_attempt_status'];
          updated_at?: string;
          user_id?: string;
        };
        Relationships: [];
      };
      topic_level_03_attempts: {
        Row: {
          activity_type: Database['learning']['Enums']['activity_type'];
          actual_marks: number | null;
          created_at: string;
          id: string;
          max_marks: number | null;
          question_attempt_id: string;
          topic_id: number;
          updated_at: string;
          user_id: string;
        };
        Insert: {
          activity_type: Database['learning']['Enums']['activity_type'];
          actual_marks?: number | null;
          created_at?: string;
          id?: string;
          max_marks?: number | null;
          question_attempt_id: string;
          topic_id: number;
          updated_at?: string;
          user_id: string;
        };
        Update: {
          activity_type?: Database['learning']['Enums']['activity_type'];
          actual_marks?: number | null;
          created_at?: string;
          id?: string;
          max_marks?: number | null;
          question_attempt_id?: string;
          topic_id?: number;
          updated_at?: string;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'topic_level_03_attempts_question_attempt_id_fkey';
            columns: ['question_attempt_id'];
            isOneToOne: false;
            referencedRelation: 'question_attempts';
            referencedColumns: ['id'];
          },
        ];
      };
    };
    Views: {
      assignments_available: {
        Row: {
          created_at: string | null;
          id: string | null;
          name: Json | null;
          syllabus_id: number | null;
          updated_at: string | null;
        };
        Relationships: [];
      };
      streak: {
        Row: {
          end_date: string | null;
          start_date: string | null;
          streak_status: string | null;
        };
        Relationships: [];
      };
      topic_level_02_weights: {
        Row: {
          course_level: string | null;
          syllabus_id: number | null;
          topic_id: number | null;
          weight: number | null;
        };
        Relationships: [];
      };
      topic_level_03_weights: {
        Row: {
          course_level: string | null;
          syllabus_id: number | null;
          topic_id: number | null;
          topic_level_02_id: number | null;
          weight: number | null;
        };
        Relationships: [];
      };
      user_mark_attempts_and_limits: {
        Row: {
          next_refresh: string | null;
          point_limit: number | null;
          spent_points: number | null;
          user_id: string | null;
          user_type: string | null;
        };
        Relationships: [];
      };
    };
    Functions: {
      challenge_start: {
        Args: {
          p_assignment_section_id: string;
        };
        Returns: string;
      };
      save_assessment_topics: {
        Args: {
          target_assessment_id: number;
          topics_level_02_ids: number[];
        };
        Returns: undefined;
      };
      set_assessment_active: {
        Args: {
          assessement_id: number;
        };
        Returns: undefined;
      };
    };
    Enums: {
      activity_type: 'PRACTICE' | 'TEST';
      challenge_activity_status: 'IN_PROGRESS' | 'COMPLETED' | 'DISCARDED' | 'FAILED';
      practice_activity_status: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED' | 'DISCARDED' | 'FAILED';
      question_attempt_status: 'PENDING' | 'SUBMITTED' | 'GRADED' | 'FAILED';
    };
    CompositeTypes: {
      [_ in never]: never;
    };
  };
  public: {
    Tables: {
      categories: {
        Row: {
          active: boolean | null;
          category_level: Database['public']['Enums']['category_level'] | null;
          color_primary: string | null;
          color_secondary: string | null;
          course: string | null;
          description: string | null;
          detail_json: Json | null;
          id: number;
          latex_sample: string | null;
          order_int: number | null;
          path: unknown | null;
          subject: string | null;
          topic: string | null;
          topic_area: string | null;
        };
        Insert: {
          active?: boolean | null;
          category_level?: Database['public']['Enums']['category_level'] | null;
          color_primary?: string | null;
          color_secondary?: string | null;
          course?: string | null;
          description?: string | null;
          detail_json?: Json | null;
          id: number;
          latex_sample?: string | null;
          order_int?: number | null;
          path?: unknown | null;
          subject?: string | null;
          topic?: string | null;
          topic_area?: string | null;
        };
        Update: {
          active?: boolean | null;
          category_level?: Database['public']['Enums']['category_level'] | null;
          color_primary?: string | null;
          color_secondary?: string | null;
          course?: string | null;
          description?: string | null;
          detail_json?: Json | null;
          id?: number;
          latex_sample?: string | null;
          order_int?: number | null;
          path?: unknown | null;
          subject?: string | null;
          topic?: string | null;
          topic_area?: string | null;
        };
        Relationships: [];
      };
      exam_dates: {
        Row: {
          course_level: Database['categories']['Enums']['course_level'] | null;
          first_exam_date: string | null;
          id: number;
          last_exam_date: string | null;
          syllabus_id: number | null;
        };
        Insert: {
          course_level?: Database['categories']['Enums']['course_level'] | null;
          first_exam_date?: string | null;
          id?: never;
          last_exam_date?: string | null;
          syllabus_id?: number | null;
        };
        Update: {
          course_level?: Database['categories']['Enums']['course_level'] | null;
          first_exam_date?: string | null;
          id?: never;
          last_exam_date?: string | null;
          syllabus_id?: number | null;
        };
        Relationships: [];
      };
      generation_runs: {
        Row: {
          created_at: string | null;
          id: number;
          reference_question_id: number | null;
          thinking: string | null;
          user_id: string | null;
          variation_count: number | null;
        };
        Insert: {
          created_at?: string | null;
          id?: never;
          reference_question_id?: number | null;
          thinking?: string | null;
          user_id?: string | null;
          variation_count?: number | null;
        };
        Update: {
          created_at?: string | null;
          id?: never;
          reference_question_id?: number | null;
          thinking?: string | null;
          user_id?: string | null;
          variation_count?: number | null;
        };
        Relationships: [
          {
            foreignKeyName: 'generation_run_reference_question_id_fkey';
            columns: ['reference_question_id'];
            isOneToOne: false;
            referencedRelation: 'admin_reference_question_list_view';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'generation_run_reference_question_id_fkey';
            columns: ['reference_question_id'];
            isOneToOne: false;
            referencedRelation: 'reference_questions';
            referencedColumns: ['id'];
          },
        ];
      };
      leveling: {
        Row: {
          cumulative_xp_required: number;
          easy: number;
          hard: number;
          id: number;
          level: number;
          level_multiplier: number;
          medium: number;
        };
        Insert: {
          cumulative_xp_required: number;
          easy: number;
          hard: number;
          id?: number;
          level: number;
          level_multiplier: number;
          medium: number;
        };
        Update: {
          cumulative_xp_required?: number;
          easy?: number;
          hard?: number;
          id?: number;
          level?: number;
          level_multiplier?: number;
          medium?: number;
        };
        Relationships: [];
      };
      llm_chat_message_user_feedback: {
        Row: {
          author: string;
          created_at: string;
          id: number;
          score: number;
          text_feedback: string;
        };
        Insert: {
          author: string;
          created_at?: string;
          id: number;
          score: number;
          text_feedback: string;
        };
        Update: {
          author?: string;
          created_at?: string;
          id?: number;
          score?: number;
          text_feedback?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'llm_chat_message_user_feedback_id_fkey';
            columns: ['id'];
            isOneToOne: true;
            referencedRelation: 'llm_chat_messages';
            referencedColumns: ['id'];
          },
        ];
      };
      llm_chat_messages: {
        Row: {
          author: string | null;
          created_at: string;
          created_by_role: Database['public']['Enums']['ai_chat_role_enum'];
          id: number;
          input_tokens: number | null;
          llm_chat_id: number;
          message: Json;
          metadata: Json | null;
          output_tokens: number | null;
          responds_to: number | null;
          stop_reason: string | null;
        };
        Insert: {
          author?: string | null;
          created_at?: string;
          created_by_role: Database['public']['Enums']['ai_chat_role_enum'];
          id?: number;
          input_tokens?: number | null;
          llm_chat_id: number;
          message: Json;
          metadata?: Json | null;
          output_tokens?: number | null;
          responds_to?: number | null;
          stop_reason?: string | null;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          created_by_role?: Database['public']['Enums']['ai_chat_role_enum'];
          id?: number;
          input_tokens?: number | null;
          llm_chat_id?: number;
          message?: Json;
          metadata?: Json | null;
          output_tokens?: number | null;
          responds_to?: number | null;
          stop_reason?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'llm_chat_messages_llm_chat_id_fkey';
            columns: ['llm_chat_id'];
            isOneToOne: false;
            referencedRelation: 'llm_chats';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'llm_chat_messages_responds_to_fkey';
            columns: ['responds_to'];
            isOneToOne: false;
            referencedRelation: 'llm_chat_messages';
            referencedColumns: ['id'];
          },
        ];
      };
      llm_chats: {
        Row: {
          id: number;
        };
        Insert: {
          id?: number;
        };
        Update: {
          id?: number;
        };
        Relationships: [];
      };
      mark_history: {
        Row: {
          created_at: string;
          feedback: string | null;
          id: number;
          initiator: string | null;
          marks: Json | null;
          question_engagement_id: number;
          thinking: string | null;
        };
        Insert: {
          created_at?: string;
          feedback?: string | null;
          id?: number;
          initiator?: string | null;
          marks?: Json | null;
          question_engagement_id: number;
          thinking?: string | null;
        };
        Update: {
          created_at?: string;
          feedback?: string | null;
          id?: number;
          initiator?: string | null;
          marks?: Json | null;
          question_engagement_id?: number;
          thinking?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'mark_history_question_engagement_id_fkey';
            columns: ['question_engagement_id'];
            isOneToOne: false;
            referencedRelation: 'question_engagements';
            referencedColumns: ['id'];
          },
        ];
      };
      markscheme_images: {
        Row: {
          author: string | null;
          created_at: string;
          description: Json | null;
          id: number;
          image_bucket: string | null;
          image_name: string | null;
          question_id: number;
          sys_period: unknown;
          updated_at: string;
        };
        Insert: {
          author?: string | null;
          created_at?: string;
          description?: Json | null;
          id?: number;
          image_bucket?: string | null;
          image_name?: string | null;
          question_id: number;
          sys_period?: unknown;
          updated_at?: string;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          description?: Json | null;
          id?: number;
          image_bucket?: string | null;
          image_name?: string | null;
          question_id?: number;
          sys_period?: unknown;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'markscheme_images_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'admin_question_list_view';
            referencedColumns: ['question_id'];
          },
          {
            foreignKeyName: 'markscheme_images_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'questions';
            referencedColumns: ['id'];
          },
        ];
      };
      markscheme_images_history: {
        Row: {
          author: string | null;
          created_at: string;
          description: Json | null;
          id: number;
          image_bucket: string | null;
          image_name: string | null;
          question_id: number;
          sys_period: unknown;
          updated_at: string;
        };
        Insert: {
          author?: string | null;
          created_at: string;
          description?: Json | null;
          id: number;
          image_bucket?: string | null;
          image_name?: string | null;
          question_id: number;
          sys_period: unknown;
          updated_at: string;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          description?: Json | null;
          id?: number;
          image_bucket?: string | null;
          image_name?: string | null;
          question_id?: number;
          sys_period?: unknown;
          updated_at?: string;
        };
        Relationships: [];
      };
      markscheme_texts: {
        Row: {
          author: string | null;
          created_at: string;
          id: number;
          question_id: number;
          sys_period: unknown;
          text: Json;
          updated_at: string;
        };
        Insert: {
          author?: string | null;
          created_at?: string;
          id?: number;
          question_id: number;
          sys_period?: unknown;
          text: Json;
          updated_at?: string;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          id?: number;
          question_id?: number;
          sys_period?: unknown;
          text?: Json;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'markscheme_texts_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'admin_question_list_view';
            referencedColumns: ['question_id'];
          },
          {
            foreignKeyName: 'markscheme_texts_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'questions';
            referencedColumns: ['id'];
          },
        ];
      };
      markscheme_texts_history: {
        Row: {
          author: string | null;
          created_at: string;
          id: number;
          question_id: number;
          sys_period: unknown;
          text: Json;
          updated_at: string;
        };
        Insert: {
          author?: string | null;
          created_at: string;
          id: number;
          question_id: number;
          sys_period: unknown;
          text: Json;
          updated_at: string;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          id?: number;
          question_id?: number;
          sys_period?: unknown;
          text?: Json;
          updated_at?: string;
        };
        Relationships: [];
      };
      question_engagement_grading_user_feedback: {
        Row: {
          author: string;
          created_at: string;
          id: number;
          score: number;
          text_feedback: string;
        };
        Insert: {
          author: string;
          created_at?: string;
          id: number;
          score: number;
          text_feedback: string;
        };
        Update: {
          author?: string;
          created_at?: string;
          id?: number;
          score?: number;
          text_feedback?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'question_engagement_grading_user_feedback_id_fkey';
            columns: ['id'];
            isOneToOne: true;
            referencedRelation: 'question_engagement_gradings';
            referencedColumns: ['id'];
          },
        ];
      };
      question_engagement_gradings: {
        Row: {
          created_at: string;
          grading_result: Json;
          id: number;
          question_engagement_id: number;
          stems: number[] | null;
        };
        Insert: {
          created_at?: string;
          grading_result: Json;
          id?: number;
          question_engagement_id: number;
          stems?: number[] | null;
        };
        Update: {
          created_at?: string;
          grading_result?: Json;
          id?: number;
          question_engagement_id?: number;
          stems?: number[] | null;
        };
        Relationships: [
          {
            foreignKeyName: 'question_engagement_gradings_question_engagement_id_fkey';
            columns: ['question_engagement_id'];
            isOneToOne: false;
            referencedRelation: 'question_engagements';
            referencedColumns: ['id'];
          },
        ];
      };
      question_engagement_response_images: {
        Row: {
          bucket_name: string | null;
          created_at: string;
          id: number;
          image_name: string | null;
          order: number;
          question_engagement_grading_id: number | null;
          question_engagement_id: number;
        };
        Insert: {
          bucket_name?: string | null;
          created_at?: string;
          id?: number;
          image_name?: string | null;
          order?: number;
          question_engagement_grading_id?: number | null;
          question_engagement_id: number;
        };
        Update: {
          bucket_name?: string | null;
          created_at?: string;
          id?: number;
          image_name?: string | null;
          order?: number;
          question_engagement_grading_id?: number | null;
          question_engagement_id?: number;
        };
        Relationships: [
          {
            foreignKeyName: 'question_engagement_response_images_grading_id_fkey';
            columns: ['question_engagement_grading_id'];
            isOneToOne: false;
            referencedRelation: 'question_engagement_gradings';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'stem_response_images_question_engagement_id_fkey';
            columns: ['question_engagement_id'];
            isOneToOne: false;
            referencedRelation: 'question_engagements';
            referencedColumns: ['id'];
          },
        ];
      };
      question_engagement_statuses: {
        Row: {
          created_at: string;
          id: number;
          metadata: Json | null;
          question_engagement_id: number;
          status: Database['public']['Enums']['question_engagement_status'];
        };
        Insert: {
          created_at?: string;
          id?: number;
          metadata?: Json | null;
          question_engagement_id: number;
          status: Database['public']['Enums']['question_engagement_status'];
        };
        Update: {
          created_at?: string;
          id?: number;
          metadata?: Json | null;
          question_engagement_id?: number;
          status?: Database['public']['Enums']['question_engagement_status'];
        };
        Relationships: [
          {
            foreignKeyName: 'question_engagement_statuses_question_engagement_id_fkey';
            columns: ['question_engagement_id'];
            isOneToOne: false;
            referencedRelation: 'question_engagements';
            referencedColumns: ['id'];
          },
        ];
      };
      question_engagements: {
        Row: {
          category_id: number;
          category_level: Database['public']['Enums']['category_level'] | null;
          created_at: string;
          id: number;
          language: string | null;
          marks: Json | null;
          post_grading_llm_chat_id: number | null;
          pre_grading_llm_chat_id: number | null;
          question_id: number;
          updated_at: string;
          user_id: string;
        };
        Insert: {
          category_id: number;
          category_level?: Database['public']['Enums']['category_level'] | null;
          created_at?: string;
          id?: number;
          language?: string | null;
          marks?: Json | null;
          post_grading_llm_chat_id?: number | null;
          pre_grading_llm_chat_id?: number | null;
          question_id: number;
          updated_at?: string;
          user_id: string;
        };
        Update: {
          category_id?: number;
          category_level?: Database['public']['Enums']['category_level'] | null;
          created_at?: string;
          id?: number;
          language?: string | null;
          marks?: Json | null;
          post_grading_llm_chat_id?: number | null;
          pre_grading_llm_chat_id?: number | null;
          question_id?: number;
          updated_at?: string;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'question_engagements_category_id_fkey';
            columns: ['category_id'];
            isOneToOne: false;
            referencedRelation: 'categories';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'question_engagements_post_grading_llm_chat_id_fkey';
            columns: ['post_grading_llm_chat_id'];
            isOneToOne: false;
            referencedRelation: 'llm_chats';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'question_engagements_pre_grading_llm_chat_id_fkey';
            columns: ['pre_grading_llm_chat_id'];
            isOneToOne: false;
            referencedRelation: 'llm_chats';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'question_engagements_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'admin_question_list_view';
            referencedColumns: ['question_id'];
          },
          {
            foreignKeyName: 'question_engagements_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'questions';
            referencedColumns: ['id'];
          },
        ];
      };
      question_image_snapshots: {
        Row: {
          author: string | null;
          created_at: string;
          description: Json | null;
          id: number;
          question_id: number | null;
          question_image_bucket: string | null;
          question_image_name: string | null;
          question_snapshot_id: number;
          snapshot_id: number;
          sys_period: unknown;
          updated_at: string;
        };
        Insert: {
          author?: string | null;
          created_at?: string;
          description?: Json | null;
          id?: number;
          question_id?: number | null;
          question_image_bucket?: string | null;
          question_image_name?: string | null;
          question_snapshot_id: number;
          snapshot_id?: never;
          sys_period?: unknown;
          updated_at?: string;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          description?: Json | null;
          id?: number;
          question_id?: number | null;
          question_image_bucket?: string | null;
          question_image_name?: string | null;
          question_snapshot_id?: number;
          snapshot_id?: never;
          sys_period?: unknown;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'question_image_snapshots_question_snapshot_id_fkey';
            columns: ['question_snapshot_id'];
            isOneToOne: false;
            referencedRelation: 'question_snapshots';
            referencedColumns: ['snapshot_id'];
          },
        ];
      };
      question_images: {
        Row: {
          author: string | null;
          created_at: string;
          description: Json | null;
          id: number;
          question_id: number | null;
          question_image_bucket: string | null;
          question_image_name: string | null;
          sys_period: unknown;
          updated_at: string;
        };
        Insert: {
          author?: string | null;
          created_at?: string;
          description?: Json | null;
          id?: number;
          question_id?: number | null;
          question_image_bucket?: string | null;
          question_image_name?: string | null;
          sys_period?: unknown;
          updated_at?: string;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          description?: Json | null;
          id?: number;
          question_id?: number | null;
          question_image_bucket?: string | null;
          question_image_name?: string | null;
          sys_period?: unknown;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'question_images_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'admin_question_list_view';
            referencedColumns: ['question_id'];
          },
          {
            foreignKeyName: 'question_images_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'questions';
            referencedColumns: ['id'];
          },
        ];
      };
      question_images_history: {
        Row: {
          author: string | null;
          created_at: string;
          description: Json | null;
          id: number;
          question_id: number | null;
          question_image_bucket: string | null;
          question_image_name: string | null;
          sys_period: unknown;
          updated_at: string;
        };
        Insert: {
          author?: string | null;
          created_at: string;
          description?: Json | null;
          id: number;
          question_id?: number | null;
          question_image_bucket?: string | null;
          question_image_name?: string | null;
          sys_period: unknown;
          updated_at: string;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          description?: Json | null;
          id?: number;
          question_id?: number | null;
          question_image_bucket?: string | null;
          question_image_name?: string | null;
          sys_period?: unknown;
          updated_at?: string;
        };
        Relationships: [];
      };
      question_operations: {
        Row: {
          created_at: string;
          id: string;
          model_name: string | null;
          operation: string;
          question_id: number;
          updated_at: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          model_name?: string | null;
          operation: string;
          question_id: number;
          updated_at?: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          model_name?: string | null;
          operation?: string;
          question_id?: number;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'question_operations_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'admin_question_list_view';
            referencedColumns: ['question_id'];
          },
          {
            foreignKeyName: 'question_operations_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'questions';
            referencedColumns: ['id'];
          },
        ];
      };
      question_snapshots: {
        Row: {
          author: string | null;
          calculator_allowed: boolean;
          created_at: string;
          feedback: Json | null;
          generation_run_id: number | null;
          id: number;
          image_llm_feedback: Json | null;
          items: Database['public']['CompositeTypes']['question_item_ref'][];
          llm_feedback: Json | null;
          markscheme_items: Database['public']['CompositeTypes']['markscheme_item_ref'][] | null;
          max_points: number | null;
          needs_graphics: boolean | null;
          question_number: number;
          snapshot_id: number;
          solution_paths: Json | null;
          status: Database['public']['Enums']['question_status'];
          sys_period: unknown;
          updated_at: string;
          updated_by: string | null;
        };
        Insert: {
          author?: string | null;
          calculator_allowed: boolean;
          created_at?: string;
          feedback?: Json | null;
          generation_run_id?: number | null;
          id?: number;
          image_llm_feedback?: Json | null;
          items?: Database['public']['CompositeTypes']['question_item_ref'][];
          llm_feedback?: Json | null;
          markscheme_items?: Database['public']['CompositeTypes']['markscheme_item_ref'][] | null;
          max_points?: number | null;
          needs_graphics?: boolean | null;
          question_number: number;
          snapshot_id?: never;
          solution_paths?: Json | null;
          status?: Database['public']['Enums']['question_status'];
          sys_period?: unknown;
          updated_at?: string;
          updated_by?: string | null;
        };
        Update: {
          author?: string | null;
          calculator_allowed?: boolean;
          created_at?: string;
          feedback?: Json | null;
          generation_run_id?: number | null;
          id?: number;
          image_llm_feedback?: Json | null;
          items?: Database['public']['CompositeTypes']['question_item_ref'][];
          llm_feedback?: Json | null;
          markscheme_items?: Database['public']['CompositeTypes']['markscheme_item_ref'][] | null;
          max_points?: number | null;
          needs_graphics?: boolean | null;
          question_number?: number;
          snapshot_id?: never;
          solution_paths?: Json | null;
          status?: Database['public']['Enums']['question_status'];
          sys_period?: unknown;
          updated_at?: string;
          updated_by?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'question_snapshots_generation_run_id_fkey';
            columns: ['generation_run_id'];
            isOneToOne: false;
            referencedRelation: 'generation_runs';
            referencedColumns: ['id'];
          },
        ];
      };
      question_solution_path_topic_points: {
        Row: {
          cdc_path: string | null;
          path: Json;
          points: number;
          question_id: number;
          topic_id: number;
        };
        Insert: {
          cdc_path?: string | null;
          path: Json;
          points: number;
          question_id: number;
          topic_id: number;
        };
        Update: {
          cdc_path?: string | null;
          path?: Json;
          points?: number;
          question_id?: number;
          topic_id?: number;
        };
        Relationships: [
          {
            foreignKeyName: 'question_solution_path_topic_points_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'admin_question_list_view';
            referencedColumns: ['question_id'];
          },
          {
            foreignKeyName: 'question_solution_path_topic_points_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'questions';
            referencedColumns: ['id'];
          },
        ];
      };
      question_votes: {
        Row: {
          created_at: string;
          id: number;
          obsolete: boolean | null;
          problems: Json | null;
          question_id: number;
          text_feedback: string | null;
          user_id: string;
          vote: boolean;
        };
        Insert: {
          created_at?: string;
          id?: number;
          obsolete?: boolean | null;
          problems?: Json | null;
          question_id: number;
          text_feedback?: string | null;
          user_id: string;
          vote: boolean;
        };
        Update: {
          created_at?: string;
          id?: number;
          obsolete?: boolean | null;
          problems?: Json | null;
          question_id?: number;
          text_feedback?: string | null;
          user_id?: string;
          vote?: boolean;
        };
        Relationships: [
          {
            foreignKeyName: 'question_votes_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'admin_question_list_view';
            referencedColumns: ['question_id'];
          },
          {
            foreignKeyName: 'question_votes_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'questions';
            referencedColumns: ['id'];
          },
        ];
      };
      questions: {
        Row: {
          author: string | null;
          calculator_allowed: boolean;
          created_at: string;
          feedback: Json | null;
          generation_run_id: number | null;
          id: number;
          image_llm_feedback: Json | null;
          items: Database['public']['CompositeTypes']['question_item_ref'][];
          llm_feedback: Json | null;
          markscheme_items: Database['public']['CompositeTypes']['markscheme_item_ref'][] | null;
          max_points: number | null;
          needs_graphics: boolean | null;
          question_number: number;
          solution_paths: Json | null;
          status: Database['public']['Enums']['question_status'];
          sys_period: unknown;
          updated_at: string;
          updated_by: string | null;
        };
        Insert: {
          author?: string | null;
          calculator_allowed: boolean;
          created_at?: string;
          feedback?: Json | null;
          generation_run_id?: number | null;
          id?: number;
          image_llm_feedback?: Json | null;
          items?: Database['public']['CompositeTypes']['question_item_ref'][];
          llm_feedback?: Json | null;
          markscheme_items?: Database['public']['CompositeTypes']['markscheme_item_ref'][] | null;
          max_points?: number | null;
          needs_graphics?: boolean | null;
          question_number: number;
          solution_paths?: Json | null;
          status?: Database['public']['Enums']['question_status'];
          sys_period?: unknown;
          updated_at?: string;
          updated_by?: string | null;
        };
        Update: {
          author?: string | null;
          calculator_allowed?: boolean;
          created_at?: string;
          feedback?: Json | null;
          generation_run_id?: number | null;
          id?: number;
          image_llm_feedback?: Json | null;
          items?: Database['public']['CompositeTypes']['question_item_ref'][];
          llm_feedback?: Json | null;
          markscheme_items?: Database['public']['CompositeTypes']['markscheme_item_ref'][] | null;
          max_points?: number | null;
          needs_graphics?: boolean | null;
          question_number?: number;
          solution_paths?: Json | null;
          status?: Database['public']['Enums']['question_status'];
          sys_period?: unknown;
          updated_at?: string;
          updated_by?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'questions_generation_run_id_fkey';
            columns: ['generation_run_id'];
            isOneToOne: false;
            referencedRelation: 'generation_runs';
            referencedColumns: ['id'];
          },
        ];
      };
      questions_history: {
        Row: {
          author: string | null;
          calculator_allowed: boolean;
          created_at: string;
          feedback: Json | null;
          id: number;
          markscheme_items: Database['public']['CompositeTypes']['markscheme_item_ref'][] | null;
          question_number: number;
          status: Database['public']['Enums']['question_status'];
          sys_period: unknown;
          updated_at: string;
          updated_by: string | null;
        };
        Insert: {
          author?: string | null;
          calculator_allowed: boolean;
          created_at: string;
          feedback?: Json | null;
          id: number;
          markscheme_items?: Database['public']['CompositeTypes']['markscheme_item_ref'][] | null;
          question_number: number;
          status: Database['public']['Enums']['question_status'];
          sys_period: unknown;
          updated_at: string;
          updated_by?: string | null;
        };
        Update: {
          author?: string | null;
          calculator_allowed?: boolean;
          created_at?: string;
          feedback?: Json | null;
          id?: number;
          markscheme_items?: Database['public']['CompositeTypes']['markscheme_item_ref'][] | null;
          question_number?: number;
          status?: Database['public']['Enums']['question_status'];
          sys_period?: unknown;
          updated_at?: string;
          updated_by?: string | null;
        };
        Relationships: [];
      };
      reference_question_markscheme_images: {
        Row: {
          created_at: string | null;
          created_by: string | null;
          id: number;
          image_bucket: string | null;
          image_name: string;
          reference_question: number;
          sort_order: number;
          updated_at: string | null;
          updated_by: string | null;
        };
        Insert: {
          created_at?: string | null;
          created_by?: string | null;
          id?: number;
          image_bucket?: string | null;
          image_name: string;
          reference_question: number;
          sort_order: number;
          updated_at?: string | null;
          updated_by?: string | null;
        };
        Update: {
          created_at?: string | null;
          created_by?: string | null;
          id?: number;
          image_bucket?: string | null;
          image_name?: string;
          reference_question?: number;
          sort_order?: number;
          updated_at?: string | null;
          updated_by?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'reference_question_markscheme_images_reference_question_fkey';
            columns: ['reference_question'];
            isOneToOne: false;
            referencedRelation: 'admin_reference_question_list_view';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'reference_question_markscheme_images_reference_question_fkey';
            columns: ['reference_question'];
            isOneToOne: false;
            referencedRelation: 'reference_questions';
            referencedColumns: ['id'];
          },
        ];
      };
      reference_questions: {
        Row: {
          calculator_allowed: boolean;
          course_levels: Database['categories']['Enums']['course_level'][] | null;
          created_at: string | null;
          created_by: string | null;
          human_feedback: string | null;
          id: number;
          llm_feedback: Json | null;
          markscheme_image_bucket: string | null;
          markscheme_image_name: string | null;
          parsed_markscheme: Json | null;
          parsed_markscheme_llm_feedback: Json | null;
          question_image_bucket: string | null;
          question_image_name: string;
          question_labels: string[] | null;
          question_number: number;
          source_ref: Json;
          status:
            | 'IMAGE_QUALITY_APPROVED'
            | 'IMAGE_QUALITY_REJECTED'
            | 'PARSING_REJECTED'
            | 'PARSING_APPROVED'
            | 'PARSING_TOKEN_LIMIT'
            | 'HUMAN_REJECTED'
            | 'HUMAN_APPROVED'
            | 'NEEDS_HUMAN_REVIEW'
            | null;
          status_reason: string | null;
          syllabus_id: number | null;
          target_courses: number[];
          updated_at: string | null;
          updated_by: string | null;
        };
        Insert: {
          calculator_allowed: boolean;
          course_levels?: Database['categories']['Enums']['course_level'][] | null;
          created_at?: string | null;
          created_by?: string | null;
          human_feedback?: string | null;
          id?: never;
          llm_feedback?: Json | null;
          markscheme_image_bucket?: string | null;
          markscheme_image_name?: string | null;
          parsed_markscheme?: Json | null;
          parsed_markscheme_llm_feedback?: Json | null;
          question_image_bucket?: string | null;
          question_image_name: string;
          question_labels?: string[] | null;
          question_number: number;
          source_ref?: Json;
          status?:
            | 'IMAGE_QUALITY_APPROVED'
            | 'IMAGE_QUALITY_REJECTED'
            | 'PARSING_REJECTED'
            | 'PARSING_APPROVED'
            | 'PARSING_TOKEN_LIMIT'
            | 'HUMAN_REJECTED'
            | 'HUMAN_APPROVED'
            | 'NEEDS_HUMAN_REVIEW'
            | null;
          status_reason?: string | null;
          syllabus_id?: number | null;
          target_courses: number[];
          updated_at?: string | null;
          updated_by?: string | null;
        };
        Update: {
          calculator_allowed?: boolean;
          course_levels?: Database['categories']['Enums']['course_level'][] | null;
          created_at?: string | null;
          created_by?: string | null;
          human_feedback?: string | null;
          id?: never;
          llm_feedback?: Json | null;
          markscheme_image_bucket?: string | null;
          markscheme_image_name?: string | null;
          parsed_markscheme?: Json | null;
          parsed_markscheme_llm_feedback?: Json | null;
          question_image_bucket?: string | null;
          question_image_name?: string;
          question_labels?: string[] | null;
          question_number?: number;
          source_ref?: Json;
          status?:
            | 'IMAGE_QUALITY_APPROVED'
            | 'IMAGE_QUALITY_REJECTED'
            | 'PARSING_REJECTED'
            | 'PARSING_APPROVED'
            | 'PARSING_TOKEN_LIMIT'
            | 'HUMAN_REJECTED'
            | 'HUMAN_APPROVED'
            | 'NEEDS_HUMAN_REVIEW'
            | null;
          status_reason?: string | null;
          syllabus_id?: number | null;
          target_courses?: number[];
          updated_at?: string | null;
          updated_by?: string | null;
        };
        Relationships: [];
      };
      settings: {
        Row: {
          author: string | null;
          created_at: string;
          daily_marks_attempted_limit_free_users: number;
          daily_marks_attempted_limit_paid_users: number;
          id: number;
          sys_period: unknown;
          updated_at: string;
          weekly_marks_attempted_limit_free_users: number;
          weekly_marks_attempted_limit_paid_users: number;
        };
        Insert: {
          author?: string | null;
          created_at?: string;
          daily_marks_attempted_limit_free_users: number;
          daily_marks_attempted_limit_paid_users: number;
          id?: number;
          sys_period?: unknown;
          updated_at?: string;
          weekly_marks_attempted_limit_free_users?: number;
          weekly_marks_attempted_limit_paid_users?: number;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          daily_marks_attempted_limit_free_users?: number;
          daily_marks_attempted_limit_paid_users?: number;
          id?: number;
          sys_period?: unknown;
          updated_at?: string;
          weekly_marks_attempted_limit_free_users?: number;
          weekly_marks_attempted_limit_paid_users?: number;
        };
        Relationships: [];
      };
      settings_history: {
        Row: {
          author: string | null;
          created_at: string;
          daily_marks_attempted_limit_free_users: number;
          daily_marks_attempted_limit_paid_users: number;
          id: number;
          sys_period: unknown;
          updated_at: string;
          weekly_marks_attempted_limit_free_users: number;
          weekly_marks_attempted_limit_paid_users: number;
        };
        Insert: {
          author?: string | null;
          created_at: string;
          daily_marks_attempted_limit_free_users: number;
          daily_marks_attempted_limit_paid_users: number;
          id: number;
          sys_period: unknown;
          updated_at: string;
          weekly_marks_attempted_limit_free_users?: number;
          weekly_marks_attempted_limit_paid_users?: number;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          daily_marks_attempted_limit_free_users?: number;
          daily_marks_attempted_limit_paid_users?: number;
          id?: number;
          sys_period?: unknown;
          updated_at?: string;
          weekly_marks_attempted_limit_free_users?: number;
          weekly_marks_attempted_limit_paid_users?: number;
        };
        Relationships: [];
      };
      stem_snapshots: {
        Row: {
          author: string | null;
          created_at: string;
          difficulty_category: Database['public']['Enums']['stem_difficulty_category'] | null;
          id: number;
          markscheme: Json | null;
          question_id: number | null;
          question_snapshot_id: number;
          snapshot_id: number;
          solution: Json | null;
          stem: unknown[];
          sys_period: unknown;
          total_marks: number | null;
          updated_at: string;
        };
        Insert: {
          author?: string | null;
          created_at?: string;
          difficulty_category?: Database['public']['Enums']['stem_difficulty_category'] | null;
          id?: number;
          markscheme?: Json | null;
          question_id?: number | null;
          question_snapshot_id: number;
          snapshot_id?: never;
          solution?: Json | null;
          stem: unknown[];
          sys_period?: unknown;
          total_marks?: number | null;
          updated_at?: string;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          difficulty_category?: Database['public']['Enums']['stem_difficulty_category'] | null;
          id?: number;
          markscheme?: Json | null;
          question_id?: number | null;
          question_snapshot_id?: number;
          snapshot_id?: never;
          solution?: Json | null;
          stem?: unknown[];
          sys_period?: unknown;
          total_marks?: number | null;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'stem_snapshots_question_snapshot_id_fkey';
            columns: ['question_snapshot_id'];
            isOneToOne: false;
            referencedRelation: 'question_snapshots';
            referencedColumns: ['snapshot_id'];
          },
        ];
      };
      stems: {
        Row: {
          author: string | null;
          created_at: string;
          difficulty_category: Database['public']['Enums']['stem_difficulty_category'] | null;
          id: number;
          markscheme: Json | null;
          question_id: number | null;
          solution: Json | null;
          stem: unknown[];
          sys_period: unknown;
          total_marks: number | null;
          updated_at: string;
        };
        Insert: {
          author?: string | null;
          created_at?: string;
          difficulty_category?: Database['public']['Enums']['stem_difficulty_category'] | null;
          id?: number;
          markscheme?: Json | null;
          question_id?: number | null;
          solution?: Json | null;
          stem: unknown[];
          sys_period?: unknown;
          total_marks?: number | null;
          updated_at?: string;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          difficulty_category?: Database['public']['Enums']['stem_difficulty_category'] | null;
          id?: number;
          markscheme?: Json | null;
          question_id?: number | null;
          solution?: Json | null;
          stem?: unknown[];
          sys_period?: unknown;
          total_marks?: number | null;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'stems_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'admin_question_list_view';
            referencedColumns: ['question_id'];
          },
          {
            foreignKeyName: 'stems_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'questions';
            referencedColumns: ['id'];
          },
        ];
      };
      stems_history: {
        Row: {
          author: string | null;
          created_at: string;
          difficulty_category: Database['public']['Enums']['stem_difficulty_category'] | null;
          id: number;
          question_id: number | null;
          solution: Json | null;
          stem: unknown[];
          sys_period: unknown;
          updated_at: string;
        };
        Insert: {
          author?: string | null;
          created_at: string;
          difficulty_category?: Database['public']['Enums']['stem_difficulty_category'] | null;
          id: number;
          question_id?: number | null;
          solution?: Json | null;
          stem: unknown[];
          sys_period: unknown;
          updated_at: string;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          difficulty_category?: Database['public']['Enums']['stem_difficulty_category'] | null;
          id?: number;
          question_id?: number | null;
          solution?: Json | null;
          stem?: unknown[];
          sys_period?: unknown;
          updated_at?: string;
        };
        Relationships: [];
      };
      stems_topics: {
        Row: {
          stem_id: number;
          topic_id: number;
          topic_level: Database['public']['Enums']['category_level'] | null;
        };
        Insert: {
          stem_id: number;
          topic_id: number;
          topic_level?: Database['public']['Enums']['category_level'] | null;
        };
        Update: {
          stem_id?: number;
          topic_id?: number;
          topic_level?: Database['public']['Enums']['category_level'] | null;
        };
        Relationships: [
          {
            foreignKeyName: 'stems_topics_stem_id_fkey';
            columns: ['stem_id'];
            isOneToOne: false;
            referencedRelation: 'stems';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'stems_topics_topic_id_topic_level_fkey';
            columns: ['topic_id', 'topic_level'];
            isOneToOne: false;
            referencedRelation: 'categories';
            referencedColumns: ['id', 'category_level'];
          },
        ];
      };
      stimuli: {
        Row: {
          author: string | null;
          created_at: string;
          id: number;
          question_id: number | null;
          stimulus: Json;
          sys_period: unknown;
          updated_at: string;
        };
        Insert: {
          author?: string | null;
          created_at?: string;
          id?: number;
          question_id?: number | null;
          stimulus: Json;
          sys_period?: unknown;
          updated_at?: string;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          id?: number;
          question_id?: number | null;
          stimulus?: Json;
          sys_period?: unknown;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'stimuli_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'admin_question_list_view';
            referencedColumns: ['question_id'];
          },
          {
            foreignKeyName: 'stimuli_question_id_fkey';
            columns: ['question_id'];
            isOneToOne: false;
            referencedRelation: 'questions';
            referencedColumns: ['id'];
          },
        ];
      };
      stimuli_history: {
        Row: {
          author: string | null;
          created_at: string;
          id: number;
          question_id: number | null;
          stimulus: Json;
          sys_period: unknown;
          updated_at: string;
        };
        Insert: {
          author?: string | null;
          created_at: string;
          id: number;
          question_id?: number | null;
          stimulus: Json;
          sys_period: unknown;
          updated_at: string;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          id?: number;
          question_id?: number | null;
          stimulus?: Json;
          sys_period?: unknown;
          updated_at?: string;
        };
        Relationships: [];
      };
      stimulus_snapshots: {
        Row: {
          author: string | null;
          created_at: string;
          id: number;
          question_id: number | null;
          question_snapshot_id: number;
          snapshot_id: number;
          stimulus: Json;
          sys_period: unknown;
          updated_at: string;
        };
        Insert: {
          author?: string | null;
          created_at?: string;
          id?: number;
          question_id?: number | null;
          question_snapshot_id: number;
          snapshot_id?: never;
          stimulus: Json;
          sys_period?: unknown;
          updated_at?: string;
        };
        Update: {
          author?: string | null;
          created_at?: string;
          id?: number;
          question_id?: number | null;
          question_snapshot_id?: number;
          snapshot_id?: never;
          stimulus?: Json;
          sys_period?: unknown;
          updated_at?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'stimulus_snapshots_question_snapshot_id_fkey';
            columns: ['question_snapshot_id'];
            isOneToOne: false;
            referencedRelation: 'question_snapshots';
            referencedColumns: ['snapshot_id'];
          },
        ];
      };
      user_role_bindings: {
        Row: {
          created_at: string;
          id: string;
          role: Database['public']['Enums']['user_role'];
        };
        Insert: {
          created_at?: string;
          id: string;
          role: Database['public']['Enums']['user_role'];
        };
        Update: {
          created_at?: string;
          id?: string;
          role?: Database['public']['Enums']['user_role'];
        };
        Relationships: [];
      };
      user_settings: {
        Row: {
          notifications: Json | null;
          timezone: string;
          user_id: string;
        };
        Insert: {
          notifications?: Json | null;
          timezone?: string;
          user_id: string;
        };
        Update: {
          notifications?: Json | null;
          timezone?: string;
          user_id?: string;
        };
        Relationships: [];
      };
      user_skill_levels: {
        Row: {
          category_id: number;
          category_level: Database['public']['Enums']['category_level'] | null;
          created_at: string;
          id: number;
          leveling_id: number;
          updated_at: string;
          user_id: string;
          xp: number;
        };
        Insert: {
          category_id: number;
          category_level?: Database['public']['Enums']['category_level'] | null;
          created_at?: string;
          id?: number;
          leveling_id: number;
          updated_at?: string;
          user_id: string;
          xp?: number;
        };
        Update: {
          category_id?: number;
          category_level?: Database['public']['Enums']['category_level'] | null;
          created_at?: string;
          id?: number;
          leveling_id?: number;
          updated_at?: string;
          user_id?: string;
          xp?: number;
        };
        Relationships: [
          {
            foreignKeyName: 'user_skill_levels_category_id_fkey';
            columns: ['category_id'];
            isOneToOne: false;
            referencedRelation: 'categories';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'user_skill_levels_leveling_id_fkey';
            columns: ['leveling_id'];
            isOneToOne: false;
            referencedRelation: 'leveling';
            referencedColumns: ['id'];
          },
        ];
      };
      user_subscriptions: {
        Row: {
          created_at: string;
          customer_id: string;
          end_date: string | null;
          interval: Database['public']['Enums']['subscription_recurrence_interval'];
          interval_count: number;
          start_date: string | null;
          subscription_id: string | null;
          sys_period: unknown;
          updated_at: string;
          user_id: string;
        };
        Insert: {
          created_at?: string;
          customer_id: string;
          end_date?: string | null;
          interval?: Database['public']['Enums']['subscription_recurrence_interval'];
          interval_count?: number;
          start_date?: string | null;
          subscription_id?: string | null;
          sys_period?: unknown;
          updated_at?: string;
          user_id: string;
        };
        Update: {
          created_at?: string;
          customer_id?: string;
          end_date?: string | null;
          interval?: Database['public']['Enums']['subscription_recurrence_interval'];
          interval_count?: number;
          start_date?: string | null;
          subscription_id?: string | null;
          sys_period?: unknown;
          updated_at?: string;
          user_id?: string;
        };
        Relationships: [];
      };
      user_subscriptions_history: {
        Row: {
          created_at: string;
          customer_id: string;
          end_date: string | null;
          interval: Database['public']['Enums']['subscription_recurrence_interval'];
          interval_count: number;
          start_date: string | null;
          subscription_id: string | null;
          sys_period: unknown;
          updated_at: string;
          user_id: string;
        };
        Insert: {
          created_at: string;
          customer_id: string;
          end_date?: string | null;
          interval?: Database['public']['Enums']['subscription_recurrence_interval'];
          interval_count?: number;
          start_date?: string | null;
          subscription_id?: string | null;
          sys_period: unknown;
          updated_at: string;
          user_id: string;
        };
        Update: {
          created_at?: string;
          customer_id?: string;
          end_date?: string | null;
          interval?: Database['public']['Enums']['subscription_recurrence_interval'];
          interval_count?: number;
          start_date?: string | null;
          subscription_id?: string | null;
          sys_period?: unknown;
          updated_at?: string;
          user_id?: string;
        };
        Relationships: [];
      };
      user_xp_records: {
        Row: {
          accuracy_xp: number;
          actual_marks: number | null;
          actual_time: number | null;
          base_xp: number;
          baseline_time: number | null;
          created_at: string;
          id: number;
          max_marks: number | null;
          pace_xp: number;
          question_engagement_id: number | null;
          user_id: string;
        };
        Insert: {
          accuracy_xp?: number;
          actual_marks?: number | null;
          actual_time?: number | null;
          base_xp?: number;
          baseline_time?: number | null;
          created_at?: string;
          id?: number;
          max_marks?: number | null;
          pace_xp: number;
          question_engagement_id?: number | null;
          user_id: string;
        };
        Update: {
          accuracy_xp?: number;
          actual_marks?: number | null;
          actual_time?: number | null;
          base_xp?: number;
          baseline_time?: number | null;
          created_at?: string;
          id?: number;
          max_marks?: number | null;
          pace_xp?: number;
          question_engagement_id?: number | null;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'user_xp_records_question_engagement_id_fkey';
            columns: ['question_engagement_id'];
            isOneToOne: false;
            referencedRelation: 'question_engagements';
            referencedColumns: ['id'];
          },
        ];
      };
      users: {
        Row: {
          course_id: number | null;
          course_level: Database['categories']['Enums']['course_level'] | null;
          current_onboarding_step: Database['public']['Enums']['onboarding_step'] | null;
          email: string;
          exam_dates_id: number | null;
          final_exam_date: string | null;
          first_name: string | null;
          id: string;
          last_name: string | null;
          onboarded_app: boolean | null;
          onboarded_topic_practice: boolean | null;
          origin: Database['public']['Enums']['user_origin'] | null;
          profile_pic: string | null;
          school_name: string | null;
          storage: Json | null;
          streak_goal: number | null;
          syllabus_id: number | null;
          topic_level: Database['public']['Enums']['category_level'] | null;
          updated_at: string | null;
        };
        Insert: {
          course_id?: number | null;
          course_level?: Database['categories']['Enums']['course_level'] | null;
          current_onboarding_step?: Database['public']['Enums']['onboarding_step'] | null;
          email: string;
          exam_dates_id?: number | null;
          final_exam_date?: string | null;
          first_name?: string | null;
          id: string;
          last_name?: string | null;
          onboarded_app?: boolean | null;
          onboarded_topic_practice?: boolean | null;
          origin?: Database['public']['Enums']['user_origin'] | null;
          profile_pic?: string | null;
          school_name?: string | null;
          storage?: Json | null;
          streak_goal?: number | null;
          syllabus_id?: number | null;
          topic_level?: Database['public']['Enums']['category_level'] | null;
          updated_at?: string | null;
        };
        Update: {
          course_id?: number | null;
          course_level?: Database['categories']['Enums']['course_level'] | null;
          current_onboarding_step?: Database['public']['Enums']['onboarding_step'] | null;
          email?: string;
          exam_dates_id?: number | null;
          final_exam_date?: string | null;
          first_name?: string | null;
          id?: string;
          last_name?: string | null;
          onboarded_app?: boolean | null;
          onboarded_topic_practice?: boolean | null;
          origin?: Database['public']['Enums']['user_origin'] | null;
          profile_pic?: string | null;
          school_name?: string | null;
          storage?: Json | null;
          streak_goal?: number | null;
          syllabus_id?: number | null;
          topic_level?: Database['public']['Enums']['category_level'] | null;
          updated_at?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'reference_course';
            columns: ['course_id', 'topic_level'];
            isOneToOne: false;
            referencedRelation: 'categories';
            referencedColumns: ['id', 'category_level'];
          },
          {
            foreignKeyName: 'users_exam_dates_id_fkey';
            columns: ['exam_dates_id'];
            isOneToOne: false;
            referencedRelation: 'exam_dates';
            referencedColumns: ['id'];
          },
        ];
      };
    };
    Views: {
      admin_question_list_view: {
        Row: {
          author: string | null;
          author_name: string | null;
          created_at: string | null;
          items: Json | null;
          max_difficulty: string | null;
          question_id: number | null;
          status: string | null;
          topic_ids: number[] | null;
          updated_at: string | null;
        };
        Relationships: [];
      };
      admin_reference_question_list_view: {
        Row: {
          author_name: string | null;
          created_at: string | null;
          created_by: string | null;
          id: number | null;
          question_labels: string[] | null;
          question_number: number | null;
          source_ref: Json | null;
          status:
            | 'IMAGE_QUALITY_APPROVED'
            | 'IMAGE_QUALITY_REJECTED'
            | 'PARSING_REJECTED'
            | 'PARSING_APPROVED'
            | 'PARSING_TOKEN_LIMIT'
            | 'HUMAN_REJECTED'
            | 'HUMAN_APPROVED'
            | 'NEEDS_HUMAN_REVIEW'
            | null;
          status_reason: string | null;
          statuses: Json | null;
          target_courses: number[] | null;
        };
        Relationships: [];
      };
    };
    Functions: {
      can_create_question_engagement_status: {
        Args: {
          affected_question_engagement_id: number;
          new_status: Database['public']['Enums']['question_engagement_status'];
        };
        Returns: boolean;
      };
      custom_access_token_hook: {
        Args: {
          event: Json;
        };
        Returns: Json;
      };
      generate_next_engagement: {
        Args: {
          p_topic_id: number;
          p_difficulty?: Database['public']['Enums']['stem_difficulty_category'];
        };
        Returns: number;
      };
      get_course_structure: {
        Args: Record<PropertyKey, never>;
        Returns: Json;
      };
      get_current_user_profile: {
        Args: Record<PropertyKey, never>;
        Returns: {
          course_id: number | null;
          course_level: Database['categories']['Enums']['course_level'] | null;
          current_onboarding_step: Database['public']['Enums']['onboarding_step'] | null;
          email: string;
          exam_dates_id: number | null;
          final_exam_date: string | null;
          first_name: string | null;
          id: string;
          last_name: string | null;
          onboarded_app: boolean | null;
          onboarded_topic_practice: boolean | null;
          origin: Database['public']['Enums']['user_origin'] | null;
          profile_pic: string | null;
          school_name: string | null;
          storage: Json | null;
          streak_goal: number | null;
          syllabus_id: number | null;
          topic_level: Database['public']['Enums']['category_level'] | null;
          updated_at: string | null;
        };
      };
      get_current_user_role: {
        Args: Record<PropertyKey, never>;
        Returns: Database['public']['Enums']['user_role'];
      };
      get_latest_question_engagement_grading_feedback: {
        Args: {
          question_engagement_id_input: number;
        };
        Returns: {
          grading_id: number;
          feedback_score: number;
        }[];
      };
      get_question_items: {
        Args: {
          p_question_id: number;
        };
        Returns: Json;
      };
      get_question_vote: {
        Args: {
          q_id: number;
        };
        Returns: boolean;
      };
      get_reference_question_by_question_id: {
        Args: {
          question_id: number;
        };
        Returns: {
          calculator_allowed: boolean;
          course_levels: Database['categories']['Enums']['course_level'][] | null;
          created_at: string | null;
          created_by: string | null;
          human_feedback: string | null;
          id: number;
          llm_feedback: Json | null;
          markscheme_image_bucket: string | null;
          markscheme_image_name: string | null;
          parsed_markscheme: Json | null;
          parsed_markscheme_llm_feedback: Json | null;
          question_image_bucket: string | null;
          question_image_name: string;
          question_labels: string[] | null;
          question_number: number;
          source_ref: Json;
          status:
            | 'IMAGE_QUALITY_APPROVED'
            | 'IMAGE_QUALITY_REJECTED'
            | 'PARSING_REJECTED'
            | 'PARSING_APPROVED'
            | 'PARSING_TOKEN_LIMIT'
            | 'HUMAN_REJECTED'
            | 'HUMAN_APPROVED'
            | 'NEEDS_HUMAN_REVIEW'
            | null;
          status_reason: string | null;
          syllabus_id: number | null;
          target_courses: number[];
          updated_at: string | null;
          updated_by: string | null;
        }[];
      };
      get_weekly_marks: {
        Args: {
          tz: string;
        };
        Returns: {
          day: string;
          total_actual_marks: number;
        }[];
      };
      set_question_vote: {
        Args: {
          q_id: number;
          user_vote: boolean;
          user_text_feedback: string;
          user_problems: Json;
        };
        Returns: undefined;
      };
      validate_image_ref: {
        Args: {
          image: Json;
        };
        Returns: undefined;
      };
    };
    Enums: {
      ai_chat_role_enum: 'user' | 'ai';
      category_level: 'subject' | 'course' | 'topic_area' | 'topic';
      communication_type: 'email' | 'phone';
      exam_course: 'IB Math AA SL' | 'IB Math AA HL' | 'IB Math AI SL' | 'IB Math AI HL';
      onboarding_step: 'story' | 'origin' | 'course' | 'examDate' | 'assessment' | 'schoolName';
      question_engagement_status: 'EXPOSED' | 'DISCARDED' | 'DRAFT' | 'SUBMITTED' | 'GRADED' | 'COMPLETED';
      question_status:
        | 'DELETED'
        | 'DRAFT'
        | 'PUBLISHED'
        | 'GENERALLY_AVAILABLE'
        | 'NEEDS_REVIEW'
        | 'NEEDS_IMAGE'
        | 'IMPOSSIBLE_TO_FIX_BY_AI'
        | 'NEEDS_MARKSCHEME'
        | 'NEEDS_MARKSCHEME_V2'
        | 'INCORRECT_STEMS_AMOUNT'
        | 'PUBLISHED_V2'
        | 'NEEDS_IMAGE_REVIEW'
        | 'INCONSISTENT_POINTS'
        | 'HUMAN_REVIEWED'
        | 'NEEDS_FIX'
        | 'NEEDS_HUMAN_REVIEW'
        | 'NEEDS_AI_REVIEW'
        | 'NEEDS_PRESCREENING'
        | 'SNAPSHOT';
      stem_difficulty_category: 'EASY' | 'MEDIUM' | 'HARD';
      subject_sub_type: 'ALGEBRA' | 'GEOMETRY';
      subject_type: 'MATH';
      subscription_recurrence_interval: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
      user_origin:
        | 'friends_family'
        | 'teacher'
        | 'google_search'
        | 'youtube'
        | 'tiktok'
        | 'reddit'
        | 'instagram'
        | 'facebook'
        | 'linkedin'
        | 'other';
      user_role: 'STUDENT' | 'CONTENT_REVIEWER' | 'SENIOR_CONTENT_REVIEWER' | 'CONTENT_CREATOR' | 'ADMIN';
    };
    CompositeTypes: {
      markscheme_item_ref: {
        item_type: string | null;
        item_id: number | null;
      };
      question_item_ref: {
        item_type: string | null;
        item_id: number | null;
      };
    };
  };
  review: {
    Tables: {
      actions: {
        Row: {
          created_at: string;
          feedback: Json;
          id: string;
          reviewable_item_id: string;
          reviewable_item_snapshot_id: string;
          type: Database['review']['Enums']['action_type'];
          updated_at: string;
          user_id: string | null;
        };
        Insert: {
          created_at?: string;
          feedback: Json;
          id?: string;
          reviewable_item_id: string;
          reviewable_item_snapshot_id: string;
          type: Database['review']['Enums']['action_type'];
          updated_at?: string;
          user_id?: string | null;
        };
        Update: {
          created_at?: string;
          feedback?: Json;
          id?: string;
          reviewable_item_id?: string;
          reviewable_item_snapshot_id?: string;
          type?: Database['review']['Enums']['action_type'];
          updated_at?: string;
          user_id?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'actions_reviewable_item_id_fkey';
            columns: ['reviewable_item_id'];
            isOneToOne: false;
            referencedRelation: 'reviewable_items';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'actions_reviewable_item_snapshot_id_fkey';
            columns: ['reviewable_item_snapshot_id'];
            isOneToOne: false;
            referencedRelation: 'reviewable_item_snapshots';
            referencedColumns: ['id'];
          },
        ];
      };
      current_reviews: {
        Row: {
          created_at: string;
          review_session: string;
          reviewable_item_id: string;
          user_id: string;
        };
        Insert: {
          created_at?: string;
          review_session?: string;
          reviewable_item_id: string;
          user_id: string;
        };
        Update: {
          created_at?: string;
          review_session?: string;
          reviewable_item_id?: string;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'current_reviews_reviewable_item_id_fkey';
            columns: ['reviewable_item_id'];
            isOneToOne: false;
            referencedRelation: 'reviewable_items';
            referencedColumns: ['id'];
          },
        ];
      };
      reviewable_item_snapshots: {
        Row: {
          created_at: string;
          id: string;
          question_snapshot_id: number | null;
          updated_at: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          question_snapshot_id?: number | null;
          updated_at?: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          question_snapshot_id?: number | null;
          updated_at?: string;
        };
        Relationships: [];
      };
      reviewable_items: {
        Row: {
          created_at: string;
          id: string;
          question_id: number | null;
          reference_question_id: number | null;
          updated_at: string;
        };
        Insert: {
          created_at?: string;
          id?: string;
          question_id?: number | null;
          reference_question_id?: number | null;
          updated_at?: string;
        };
        Update: {
          created_at?: string;
          id?: string;
          question_id?: number | null;
          reference_question_id?: number | null;
          updated_at?: string;
        };
        Relationships: [];
      };
      seeds: {
        Row: {
          approval_expected: boolean;
          created_at: string;
          question_id: number;
          review_id: string | null;
          user_id: string;
        };
        Insert: {
          approval_expected: boolean;
          created_at?: string;
          question_id: number;
          review_id?: string | null;
          user_id: string;
        };
        Update: {
          approval_expected?: boolean;
          created_at?: string;
          question_id?: number;
          review_id?: string | null;
          user_id?: string;
        };
        Relationships: [];
      };
      skips: {
        Row: {
          created_at: string;
          reviewable_item_id: string;
          updated_at: string;
          user_id: string;
        };
        Insert: {
          created_at?: string;
          reviewable_item_id: string;
          updated_at?: string;
          user_id: string;
        };
        Update: {
          created_at?: string;
          reviewable_item_id?: string;
          updated_at?: string;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'skips_reviewable_item_id_fkey';
            columns: ['reviewable_item_id'];
            isOneToOne: false;
            referencedRelation: 'reviewable_items';
            referencedColumns: ['id'];
          },
        ];
      };
      user_images: {
        Row: {
          action_id: string | null;
          bucket_name: string | null;
          created_at: string;
          current_review_session: string | null;
          id: string;
          image_name: string | null;
          order: number;
          updated_at: string;
          user_id: string;
        };
        Insert: {
          action_id?: string | null;
          bucket_name?: string | null;
          created_at?: string;
          current_review_session?: string | null;
          id?: string;
          image_name?: string | null;
          order?: number;
          updated_at?: string;
          user_id: string;
        };
        Update: {
          action_id?: string | null;
          bucket_name?: string | null;
          created_at?: string;
          current_review_session?: string | null;
          id?: string;
          image_name?: string | null;
          order?: number;
          updated_at?: string;
          user_id?: string;
        };
        Relationships: [
          {
            foreignKeyName: 'user_images_action_id_fkey';
            columns: ['action_id'];
            isOneToOne: false;
            referencedRelation: 'actions';
            referencedColumns: ['id'];
          },
          {
            foreignKeyName: 'user_images_current_review_session_fkey';
            columns: ['current_review_session'];
            isOneToOne: false;
            referencedRelation: 'current_reviews';
            referencedColumns: ['review_session'];
          },
        ];
      };
    };
    Views: {
      [_ in never]: never;
    };
    Functions: {
      create_question_review_action: {
        Args: {
          p_question_id: number;
          p_feedback: Json;
        };
        Returns: string;
      };
      feedback_is_empty: {
        Args: {
          p_feedback: Json;
        };
        Returns: boolean;
      };
      get_lower_question_status: {
        Args: {
          current_status: Database['public']['Enums']['question_status'];
          next_status: Database['public']['Enums']['question_status'];
        };
        Returns: string;
      };
      get_next_question_pipeline_status: {
        Args: {
          current_status: Database['public']['Enums']['question_status'];
        };
        Returns: Database['public']['Enums']['question_status'];
      };
      get_next_question_status_from_feedback: {
        Args: {
          current_active_status: Database['public']['Enums']['question_status'];
          current_next_status: Database['public']['Enums']['question_status'];
          section_id: string;
          checklist_item_id: string;
          checklist_item_value: boolean;
        };
        Returns: Database['public']['Enums']['question_status'];
      };
    };
    Enums: {
      action_type: 'image_upload' | 'prescreening' | 'review' | 'image_ai_review';
    };
    CompositeTypes: {
      [_ in never]: never;
    };
  };
};

type DefaultSchema = Database[Extract<keyof Database, 'public'>];

export type Tables<
  DefaultSchemaTableNameOrOptions extends
    | keyof (DefaultSchema['Tables'] & DefaultSchema['Views'])
    | { schema: keyof Database },
  TableName extends DefaultSchemaTableNameOrOptions extends {
    schema: keyof Database;
  }
    ? keyof (Database[DefaultSchemaTableNameOrOptions['schema']]['Tables'] &
        Database[DefaultSchemaTableNameOrOptions['schema']]['Views'])
    : never = never,
> = DefaultSchemaTableNameOrOptions extends { schema: keyof Database }
  ? (Database[DefaultSchemaTableNameOrOptions['schema']]['Tables'] &
      Database[DefaultSchemaTableNameOrOptions['schema']]['Views'])[TableName] extends {
      Row: infer R;
    }
    ? R
    : never
  : DefaultSchemaTableNameOrOptions extends keyof (DefaultSchema['Tables'] & DefaultSchema['Views'])
    ? (DefaultSchema['Tables'] & DefaultSchema['Views'])[DefaultSchemaTableNameOrOptions] extends {
        Row: infer R;
      }
      ? R
      : never
    : never;

export type TablesInsert<
  DefaultSchemaTableNameOrOptions extends keyof DefaultSchema['Tables'] | { schema: keyof Database },
  TableName extends DefaultSchemaTableNameOrOptions extends {
    schema: keyof Database;
  }
    ? keyof Database[DefaultSchemaTableNameOrOptions['schema']]['Tables']
    : never = never,
> = DefaultSchemaTableNameOrOptions extends { schema: keyof Database }
  ? Database[DefaultSchemaTableNameOrOptions['schema']]['Tables'][TableName] extends {
      Insert: infer I;
    }
    ? I
    : never
  : DefaultSchemaTableNameOrOptions extends keyof DefaultSchema['Tables']
    ? DefaultSchema['Tables'][DefaultSchemaTableNameOrOptions] extends {
        Insert: infer I;
      }
      ? I
      : never
    : never;

export type TablesUpdate<
  DefaultSchemaTableNameOrOptions extends keyof DefaultSchema['Tables'] | { schema: keyof Database },
  TableName extends DefaultSchemaTableNameOrOptions extends {
    schema: keyof Database;
  }
    ? keyof Database[DefaultSchemaTableNameOrOptions['schema']]['Tables']
    : never = never,
> = DefaultSchemaTableNameOrOptions extends { schema: keyof Database }
  ? Database[DefaultSchemaTableNameOrOptions['schema']]['Tables'][TableName] extends {
      Update: infer U;
    }
    ? U
    : never
  : DefaultSchemaTableNameOrOptions extends keyof DefaultSchema['Tables']
    ? DefaultSchema['Tables'][DefaultSchemaTableNameOrOptions] extends {
        Update: infer U;
      }
      ? U
      : never
    : never;

export type Enums<
  DefaultSchemaEnumNameOrOptions extends keyof DefaultSchema['Enums'] | { schema: keyof Database },
  EnumName extends DefaultSchemaEnumNameOrOptions extends {
    schema: keyof Database;
  }
    ? keyof Database[DefaultSchemaEnumNameOrOptions['schema']]['Enums']
    : never = never,
> = DefaultSchemaEnumNameOrOptions extends { schema: keyof Database }
  ? Database[DefaultSchemaEnumNameOrOptions['schema']]['Enums'][EnumName]
  : DefaultSchemaEnumNameOrOptions extends keyof DefaultSchema['Enums']
    ? DefaultSchema['Enums'][DefaultSchemaEnumNameOrOptions]
    : never;

export type CompositeTypes<
  PublicCompositeTypeNameOrOptions extends keyof DefaultSchema['CompositeTypes'] | { schema: keyof Database },
  CompositeTypeName extends PublicCompositeTypeNameOrOptions extends {
    schema: keyof Database;
  }
    ? keyof Database[PublicCompositeTypeNameOrOptions['schema']]['CompositeTypes']
    : never = never,
> = PublicCompositeTypeNameOrOptions extends { schema: keyof Database }
  ? Database[PublicCompositeTypeNameOrOptions['schema']]['CompositeTypes'][CompositeTypeName]
  : PublicCompositeTypeNameOrOptions extends keyof DefaultSchema['CompositeTypes']
    ? DefaultSchema['CompositeTypes'][PublicCompositeTypeNameOrOptions]
    : never;

export const Constants = {
  categories: {
    Enums: {
      course_level: ['SL', 'HL'],
      pace_boundary_types: ['practice'],
    },
  },
  learn_hub: {
    Enums: {
      language_code: ['en'],
      page_status: ['draft', 'published', 'archived'],
      page_type: ['concept', 'cheat_sheet', 'insights', 'past_papers'],
    },
  },
  learning: {
    Enums: {
      activity_type: ['PRACTICE', 'TEST'],
      challenge_activity_status: ['IN_PROGRESS', 'COMPLETED', 'DISCARDED', 'FAILED'],
      practice_activity_status: ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED', 'DISCARDED', 'FAILED'],
      question_attempt_status: ['PENDING', 'SUBMITTED', 'GRADED', 'FAILED'],
    },
  },
  public: {
    Enums: {
      ai_chat_role_enum: ['user', 'ai'],
      category_level: ['subject', 'course', 'topic_area', 'topic'],
      communication_type: ['email', 'phone'],
      exam_course: ['IB Math AA SL', 'IB Math AA HL', 'IB Math AI SL', 'IB Math AI HL'],
      onboarding_step: ['story', 'origin', 'course', 'examDate', 'assessment', 'schoolName'],
      question_engagement_status: ['EXPOSED', 'DISCARDED', 'DRAFT', 'SUBMITTED', 'GRADED', 'COMPLETED'],
      question_status: [
        'DELETED',
        'DRAFT',
        'PUBLISHED',
        'GENERALLY_AVAILABLE',
        'NEEDS_REVIEW',
        'NEEDS_IMAGE',
        'IMPOSSIBLE_TO_FIX_BY_AI',
        'NEEDS_MARKSCHEME',
        'NEEDS_MARKSCHEME_V2',
        'INCORRECT_STEMS_AMOUNT',
        'PUBLISHED_V2',
        'NEEDS_IMAGE_REVIEW',
        'INCONSISTENT_POINTS',
        'HUMAN_REVIEWED',
        'NEEDS_FIX',
        'NEEDS_HUMAN_REVIEW',
        'NEEDS_AI_REVIEW',
        'NEEDS_PRESCREENING',
        'SNAPSHOT',
      ],
      stem_difficulty_category: ['EASY', 'MEDIUM', 'HARD'],
      subject_sub_type: ['ALGEBRA', 'GEOMETRY'],
      subject_type: ['MATH'],
      subscription_recurrence_interval: ['DAY', 'WEEK', 'MONTH', 'YEAR'],
      user_origin: [
        'friends_family',
        'teacher',
        'google_search',
        'youtube',
        'tiktok',
        'reddit',
        'instagram',
        'facebook',
        'linkedin',
        'other',
      ],
      user_role: ['STUDENT', 'CONTENT_REVIEWER', 'SENIOR_CONTENT_REVIEWER', 'CONTENT_CREATOR', 'ADMIN'],
    },
  },
  review: {
    Enums: {
      action_type: ['image_upload', 'prescreening', 'review', 'image_ai_review'],
    },
  },
} as const;
