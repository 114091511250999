import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, Inject, signal, WritableSignal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { connectState } from '@examdojo/angular/util';
import { ClassicButtonComponent } from '@examdojo/core/button';
import { MatDialogComponent } from '@examdojo/core/dialog';
import { InfoBoxSeverityIconComponent } from '@examdojo/core/info-box';
import { OrAsync } from '@examdojo/core/typescript';
import { assertNonNullable } from '@examdojo/core/util/assert';
import { ensureObservable } from '@examdojo/core/util/ensure-observable';
import { filter, firstValueFrom, fromEvent, tap } from 'rxjs';
import { ConfirmDialogData } from './confirm-dialog.model';

@Component({
  selector: 'y42-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule,
    MatDialogComponent,
    InfoBoxSeverityIconComponent,
    MatTooltipModule,
    ClassicButtonComponent,
  ],
})
export class ConfirmDialogComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<ConfirmDialogComponent, boolean | undefined>,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(MAT_DIALOG_DATA) readonly data: ConfirmDialogData,
    private readonly sanitizer: DomSanitizer,
    private readonly destroyRef: DestroyRef,
  ) {
    this.confirmOnEnter().pipe(takeUntilDestroyed()).subscribe();
  }

  readonly messageSanitized = this.sanitizer.bypassSecurityTrustHtml(this.data.message);
  readonly bodySanitized = this.data.body ? this.sanitizer.bypassSecurityTrustHtml(this.data.body) : undefined;

  private readonly confirmPending = signal(false);
  private readonly tertiaryPending = signal(false);

  readonly state = connectState({
    confirmPending: toObservable(this.confirmPending),
    tertiaryPending: toObservable(this.tertiaryPending),
  });

  async cancel() {
    this.dialogRef.close(false);
    return false;
  }

  confirm() {
    this.clickButton(this.data.confirmFn, this.confirmPending);
  }

  tertiaryClicked() {
    assertNonNullable(this.data.tertiary, 'tertiary');
    this.clickButton(this.data.tertiary.fn, this.tertiaryPending);
  }

  private async clickButton(
    buttonFn: (() => OrAsync<boolean>) | undefined = () => true,
    pending: WritableSignal<boolean>,
  ) {
    let result: boolean | undefined;
    pending.set(true);

    try {
      const returnFn$ = ensureObservable(buttonFn()).pipe(takeUntilDestroyed(this.destroyRef));
      const returnFn = await firstValueFrom(returnFn$, { defaultValue: undefined });
      // Make it easier for consumers to use the button callback and at the same time close the
      // dialog with a proper value
      // A "falsy" value means that the confirmation action went bad
      // The dialog can't be closed with "false" since it's the value used for the cancel action
      // Closing with "undefined" is more appropriate since it's used when it's dismissed without taking any additional action
      // and it also allows the "unsaved changes" dialog to work properly
      result = !returnFn ? undefined : returnFn;
    } finally {
      pending.set(false);
      this.dialogRef.close(result);
    }
  }

  private confirmOnEnter() {
    // We need to handle the event during the capture phase
    // Otherwise a form input may capture the event first.
    // And mousetrap library (used by keyboard shortcuts) does not support capturing
    // https://github.com/ccampbell/mousetrap/pull/400.
    return fromEvent<KeyboardEvent>(this.document, 'keydown', { capture: true }).pipe(
      filter((event) => event.key === 'Enter'),
      tap((event) => event.stopPropagation()),
      tap(() => this.confirm()),
    );
  }
}
