import { Injectable } from '@angular/core';
import { StatisticalRange } from '@examdojo/core/model';
import { Proficiency } from '@examdojo/models/proficiency/proficiency.model';
import { EntityState, EntityStore } from '@examdojo/state';
import { TopicLevel2StoreModel, TopicLevel2Weight } from './topic-level-2.model';

export type TopicLevel2State = EntityState<
  TopicLevel2StoreModel,
  {
    weights: TopicLevel2Weight[] | null;
    proficiencies: Record<string, Proficiency> | null;
    predictedScore: StatisticalRange | null;
  },
  number
>;

@Injectable({ providedIn: 'root' })
export class TopicLevel2Store extends EntityStore<TopicLevel2State, TopicLevel2StoreModel, 'id', number> {
  constructor() {
    super({
      name: 'topics-level-2',
      idKey: 'id',
      initialPropsState: {
        weights: null,
        proficiencies: null,
        predictedScore: null,
      },
    });
  }
}
