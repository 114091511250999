import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IconComponent } from '@examdojo/core/icon';

@Component({
  selector: 'exam-save-indicator',
  templateUrl: './save-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatProgressSpinnerModule, IconComponent],
  host: {
    class: 'flex items-center gap-1',
  },
})
export class SaveIndicatorComponent {
  readonly isSaving = input<boolean>();
}
