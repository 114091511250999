import { NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { AvatarModule as NgxAvatarModule } from 'ngx-avatars';
import { AvatarComponent } from './avatar.component';

export const NgxAvatarColors = [
  'var(--purple-400)',
  'var(--green-400)',
  'var(--pink-400)',
  'var(--indigo-400)',
  'var(--orange-400)',
  'var(--teal-400)',
  'var(--blue-400)',
  'var(--sky-400)',
  'var(--amber-400)',
];

@NgModule({
  imports: [
    NgxAvatarModule.forRoot({
      colors: NgxAvatarColors,
    }),
  ],
  declarations: [AvatarComponent],
  exports: [AvatarComponent],
})
export class AvatarModule {}
