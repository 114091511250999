import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExamdojoAuthService } from '@examdojo/auth';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { ToastService } from '@examdojo/core/toast';
import { ExamdojoSupabaseService } from '@examdojo/supabase';
import { Session } from '@supabase/supabase-js';
import { UserStore } from './user.store';

// TODO: Merge AuthService and ExamdojoAuthService services
@Injectable()
export class AuthService extends ExamdojoAuthService {
  constructor(
    protected override readonly supabase: ExamdojoSupabaseService,
    protected override readonly router: Router,
    protected override readonly activatedRoute: ActivatedRoute,
    protected override readonly errorHandlerService: ErrorHandlerService,
    protected readonly userStore: UserStore,
    protected override readonly toastService: ToastService,
  ) {
    super(supabase, router, activatedRoute, errorHandlerService, toastService);
    this.clearUserInStoreWhenSignedOut();
  }

  private clearUserInStoreWhenSignedOut() {
    let currentSession: Session | null;
    this.supabase.client.auth.onAuthStateChange((event, session) => {
      if (session?.user?.id === currentSession?.user?.id) {
        return;
      }

      currentSession = session;

      if (event === 'SIGNED_OUT') {
        this.userStore.setActive(null);
      }
    });
  }
}
