import { SvgIcon } from '@examdojo/core/icon';
import { Enum } from '@examdojo/supabase';

export type TopicStatus = Enum<'page_status', 'learn_hub'>;

export interface TopicStatusToUiModel {
  label: string;
  id: TopicStatus;
  logo: SvgIcon;
  color: string;
  backgroundColor: string;
}

export const TopicStatusToUI: Record<TopicStatus, TopicStatusToUiModel> = {
  draft: {
    label: 'Draft',
    id: 'draft',
    logo: 'assets/images/asset-status-icons/asset-draft.svg',
    color: 'var(--gray-600)',
    backgroundColor: 'var(--gray-100)',
  },
  published: {
    label: 'Published',
    id: 'published',
    logo: 'assets/images/asset-status-icons/asset-published.svg',
    color: 'var(--green-600)',
    backgroundColor: 'var(--green-100)',
  },
  archived: {
    label: 'Archived',
    id: 'archived',
    logo: 'assets/images/asset-status-icons/asset-no-status.svg',
    color: 'var(--gray-600)',
    backgroundColor: 'var(--gray-100)',
  },
};
