import { Injectable } from '@angular/core';
import { ensureArray } from '@examdojo/util/ensure-array';
import { OrArray } from '@ngneat/elf';
import { REALTIME_LISTEN_TYPES } from '@supabase/realtime-js';
import { RealtimePostgresChangesPayload } from '@supabase/supabase-js';
import { Observable, startWith, switchMap } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { SchemaName, TableName } from './database.types';
import { RealtimeChangesListenEvent } from './realtime.service';
import { ExamdojoSupabaseService } from './supabase.service';

export { REALTIME_POSTGRES_CHANGES_LISTEN_EVENT as RealtimeChangesListenEvent } from '@supabase/realtime-js';

export interface RealtimeChangesTarget<
  R extends RealtimeChangesListenEvent,
  S extends SchemaName,
  T extends TableName<S> = TableName<S>,
> {
  event: R;
  schema: S;
  table: T;
  filter?: string;
}

export interface RealtimeBroadcastPayload<U> {
  id: string;
  operation: RealtimeChangesListenEvent;
  old_record: U | null;
  record: U | null;
}

@Injectable({
  providedIn: 'root',
})
export class RealtimeService {
  constructor(private readonly supabase: ExamdojoSupabaseService) {}

  /**
   * Watches for changes in a set of tables and events
   * @param targets One or multiple targets to listen to, including the event type and filters
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listenBroadcastRealtime<U extends Record<string, any> = Record<string, any>>(
    targets: OrArray<{ event: RealtimeChangesListenEvent }>,
    channelName?: string,
  ): Observable<{
    type: `${REALTIME_LISTEN_TYPES.BROADCAST}`;
    event: RealtimeChangesListenEvent;
    payload: RealtimeBroadcastPayload<U>;
  }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listenBroadcastRealtime<U extends Record<string, any> = Record<string, any>, E extends string = string>(
    targets: OrArray<{ event: E }>,
    channelName?: string,
  ): Observable<{
    type: `${REALTIME_LISTEN_TYPES.BROADCAST}`;
    event: E;
    payload: U;
  }>;
  listenBroadcastRealtime(targets: OrArray<{ event: string }>, channelName: string = uuid()) {
    return new Observable((subscriber) => {
      let channel = this.supabase.client.channel(channelName, {
        config: {
          private: true,
        },
      });

      for (const target of ensureArray(targets)) {
        channel = channel.on(
          REALTIME_LISTEN_TYPES.BROADCAST,
          {
            event: target.event,
          },
          (payload) => {
            subscriber.next(payload);
          },
        );
      }

      channel.subscribe();

      return () => {
        this.supabase.client.removeChannel(channel);
      };
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendMessage(channelName: string, event: string, payload: any) {
    const channel = this.supabase.client.channel(channelName, {
      config: {
        private: true,
      },
    });

    return channel.send({
      type: 'broadcast',
      event,
      payload,
    });
  }

  /**
   * Watches for changes in a set of tables and events
   * @param targets One or multiple targets to listen to, including the event type and filters
   * @param channelName Channel name - generated if not provided
   */
  listenRealtime<
    U extends Record<string, unknown> = Record<string, unknown>,
    R extends RealtimeChangesListenEvent = RealtimeChangesListenEvent,
    S extends SchemaName = 'public',
    T extends TableName<S> = TableName<S>,
  >(targets: OrArray<RealtimeChangesTarget<R, S, T>>, channelName: string = uuid()) {
    return new Observable<RealtimePostgresChangesPayload<U>>((subscriber) => {
      let channel = this.supabase.client.channel(channelName);

      for (const target of ensureArray(targets)) {
        channel = channel.on<U>(
          REALTIME_LISTEN_TYPES.POSTGRES_CHANGES,
          {
            ...target,
            // Supabase messed up the type union with the overloads
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            event: target.event as any,
            table: target.table as string,
          },
          (payload) => {
            subscriber.next(payload);
          },
        );
      }

      channel.subscribe();

      return () => {
        this.supabase.client.removeChannel(channel);
      };
    });
  }

  /**
   * Re-triggers the source observable whenever a change is detected in the specified targets
   * @param targets One or multiple targets to listen to, including the event type and filters
   */
  reTriggerOnRealtimeChanges<
    O extends Observable<unknown>,
    U extends Record<string, unknown>,
    R extends RealtimeChangesListenEvent,
    S extends SchemaName,
    T extends TableName<S> = TableName<S>,
  >(targets: OrArray<RealtimeChangesTarget<R, S, T>>) {
    const realtimeChanges$ = this.listenRealtime<U, R, S, T>(targets);

    return (source: O) =>
      realtimeChanges$.pipe(
        startWith(null),
        switchMap(() => source),
      );
  }
}
