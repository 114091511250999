import { Directive, HostBinding } from '@angular/core';

export const SUPPRESS_REGISTERED_KEYBOARD_SHORTCUTS_CLASS = 'suppress-registered-keyboard-shortcuts';

/**
 * Suppresses a keyboard shortcut that is triggered from an element:
 * - bearing this directive
 * - which any of its ancestors bears this directive
 */
@Directive({
  selector: '[y42SuppressRegisteredKeyboardShortcuts]',
  standalone: true,
})
export class SuppressRegisteredKeyboardShortcutsDirective {
  @HostBinding(`class.${SUPPRESS_REGISTERED_KEYBOARD_SHORTCUTS_CLASS}`) suppressRegisteredKeyboardShortcutsClass = true;
}
