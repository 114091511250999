import { inject, Injectable, InputSignal, signal, Type } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular/standalone';
import type { ModalOptions } from '@ionic/core/components';
import { Subject } from 'rxjs';
import { DialogOptions } from './dialog-options.model';

@Injectable()
export abstract class AbstractDialogService<Input = void, Output = boolean, C extends Type<unknown> = Type<unknown>> {
  readonly modalCtrl = inject(ModalController);
  readonly platform = inject(Platform);

  abstract readonly id: string;
  protected abstract readonly component: C;
  protected readonly componentProps: Partial<InstanceType<C>> = {};

  protected readonly options?: DialogOptions;

  dialogRef?: HTMLIonModalElement;
  dialogInput?: Input;
  readonly isMobile = this.platform.is('mobile');
  readonly isIpad = this.platform.is('ipad');

  readonly dismissed$ = new Subject<void>();
  readonly opened$ = new Subject<void>();
  readonly isOpened = signal(false);

  protected getPlatformSpecificOptions(isSingleBreakpoint = false): Partial<ModalOptions> {
    if (this.isMobile && !this.isIpad) {
      return isSingleBreakpoint
        ? { initialBreakpoint: 1, breakpoints: [1] }
        : { initialBreakpoint: 1, breakpoints: [0, 1] };
    }
    // no breakpoints for desktop
    return {};
  }

  private readonly defaultModalConfig = {
    cssClass: 'auto-height action-sheet-on-mobile',
    canDismiss: true,
    backdropDismiss: true,
    showBackdrop: true,
    handle: true,
  } satisfies Partial<ModalOptions>;

  assignComponentProperty<T extends keyof InstanceType<C>, U extends InstanceType<C>[T]>(
    property: T,
    value: U extends InputSignal<infer V> ? V : U,
  ) {
    // @ts-ignore ModalController supports setting both decorated and signal inputs - https://github.com/ionic-team/ionic-framework/pull/29453
    this.componentProps[property] = value;
  }

  async openDialog({
    input,
    options,
    isSingleBreakpoint = false,
  }: { input?: Input; options?: DialogOptions; isSingleBreakpoint?: boolean } = {}): Promise<Output | undefined> {
    this.dialogInput = input;
    const platformSpecificOptions = this.getPlatformSpecificOptions(isSingleBreakpoint);

    if (this.isOpened()) {
      console.warn(`Dialog ${this.id} is already opened`);
      return;
    }

    this.isOpened.set(true);

    this.dialogRef = await this.modalCtrl.create({
      ...this.defaultModalConfig,
      ...platformSpecificOptions,
      id: this.id,
      component: this.component,
      componentProps: this.componentProps,
      ...(this.options ?? {}),
      ...(options ?? {}),
    });

    const dialogRef = this.dialogRef;

    this.opened$.next();
    await dialogRef.present();

    const result = await dialogRef.onDidDismiss<Output>();
    this.isOpened.set(false);
    this.dismissed$.next();
    this.dialogRef = undefined;

    return result.data;
  }

  isOpen() {
    return this.isOpened();
  }

  async dismiss(data?: Output) {
    const result = await this.dialogRef?.dismiss(data, '');
    this.dialogRef = undefined;
    this.isOpened.set(false);

    return result;
  }
}
