import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { mapToVoid } from '@examdojo/rxjs';
import { catchError, EMPTY, first } from 'rxjs';
import { CategoryServiceV1 } from './category.service';

/**
 * @deprecated
 */
export const v1CategoriesResolver: ResolveFn<void> = () => {
  const categoryService = inject(CategoryServiceV1);
  const errorHandler = inject(ErrorHandlerService);

  return categoryService.fetchAll().pipe(
    errorHandler.catchHttpErrors(() => EMPTY),
    catchError(() => EMPTY),
    first(),
    mapToVoid(),
  );
};
