import { Injectable } from '@angular/core';
import { LocalizedString } from '@examdojo/core/i18n';
import { ErrorHandlerService } from '@examdojo/error-handling';
import { rethrowError } from '@examdojo/rxjs';
import { TableUpdateModel } from '@examdojo/supabase';
import { sanitizeObject } from '@examdojo/util/sanitize-object';
import { tap } from 'rxjs';
import { TopicLevel2UIModel } from '../topic-level-2';
import { TopicLevel3HttpService } from './topic-level-3-http.service';
import {
  TOPIC_LEVEL_3_UPDATE_ALLOWED_KEYS,
  TopicLevel3HttpModel,
  TopicLevel3StoreModel,
  TopicLevel3UpdateModel,
} from './topic-level-3.model';
import { TopicLevel3Store } from './topic-level-3.store';

@Injectable({
  providedIn: 'root',
})
export class TopicLevel3Service {
  constructor(
    private readonly store: TopicLevel3Store,
    private readonly httpService: TopicLevel3HttpService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  update(id: TopicLevel3StoreModel['id'], model: TopicLevel3UpdateModel) {
    const httpModel = this.mapUpdateModelToHttpUpdateModel(model);

    return this.httpService.update(id, httpModel).pipe(
      tap((data) => {
        this.store.upsert(id, this.mapHttpModelToStoreModel(data));
      }),
      this.errorHandlerService.catchError(
        '[ReferenceQuestionHttpService]: Updating the syllabus topic failed',
        rethrowError(),
        {
          toast: (err) => ({
            title: 'An error occurred while updating the syllabus topic',
            description: (err as Error)?.message,
          }),
        },
      ),
    );
  }

  fetchByTopicLevel2Ids(topicLevel2Ids: Array<TopicLevel2UIModel['id']>) {
    return this.httpService.fetchByTopicLevel2Ids(topicLevel2Ids).pipe(
      tap((data) => {
        this.store.set(data.map((item) => this.mapHttpModelToStoreModel(item)));
      }),
      this.errorHandlerService.setHttpErrorMetadata({
        entity: 'examdojo.entity.topic_level_03',
        action: 'fetch',
      }),
    );
  }

  reset() {
    this.store.reset();
  }

  private mapUpdateModelToHttpUpdateModel(
    updateModel: TopicLevel3UpdateModel,
  ): TableUpdateModel<'topics_level_03', 'categories'> {
    return sanitizeObject(updateModel, TOPIC_LEVEL_3_UPDATE_ALLOWED_KEYS);
  }

  private mapHttpModelToStoreModel(httpModel: TopicLevel3HttpModel): TopicLevel3StoreModel {
    return {
      ...httpModel,
      name: httpModel.name as LocalizedString,
      testable_concepts: httpModel.testable_concepts as LocalizedString,
    };
  }
}
